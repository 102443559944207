import { useState, useEffect, useRef, useCallback } from "react";
import { collection, where, onSnapshot } from "firebase/firestore";
import { query } from "firebase/database";
import { db } from "../config/firebase";

const pageSize = 10;

const useInfiniteScroll = (
  res,
  numBidders,
  orderSelectedName,
  urlSortOption,
  lastDoc,
  categorySearch,
  conditionFilter,
  postTypeFilter,
  lowestPriceFilter,
  heighestPriceFilter,
  lowestEntryFeeFilter,
  heighestEntryFeeFilter,
  lowestStartingPriceFilter,
  heighestStartingPriceFilter,
  lowestBidIncrementFilter,
  heighestBidIncrementFilter,
  shippingMethodsFilter,
  companyFilter,
  watchDepartmentFilter,
  watchOutsideColorFilter,
  watchInsideColorFilter,
  watchDialShapeFilter,
  smallestDialSizeFilter,
  biggestDialSizeFilter,
  watchHandlesColorFilter,
  watchNumbersColorFilter,
  watchNumbersLanguageFilter,
  watchBandMaterialFilter,
  watchBandColorFilter,
  watchHasOriginalPackagingFilter,
  watchIsWaterResistantFilter,
  watchOldestYearMadeFilter,
  watchNewestYearMadeFilter,
  rosaryKindFilter,
  rosaryColorFilter,
  leastRosaryCountFilter,
  mostRosaryCountFilter,
  smallestRosarySizeFilter,
  biggestRosarySizeFilter,
  walletDepartmentFilter,
  walletOutsideColorFilter,
  purseMaterialFilter,
  purseOutsideColorFilter,
  purseInsideColorFilter,
  carPlateKindFilter,
  carPlateTransferFeeOnFilter,
  carPlateTransferTimeFilter,
  phoneNumberCourierFilter
) => {
  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allCount, setAllCount] = useState(0);
  const lastVisible = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [mostExpensiveItemEntryFee, setMostExpensiveItemEntryFee] = useState(0);
  const [leastExpensiveItemEntryFee, setLeastExpensiveItemEntryFee] =
    useState(0);

  const [mostExpensiveItemStartingPrice, setMostExpensiveItemStartingPrice] =
    useState(0);
  const [leastExpensiveItemStartingPrice, setLeastExpensiveItemStartingPrice] =
    useState(0);
  const [mostExpensiveBidIncrement, setMostExpensiveBidIncrement] = useState(0);
  const [leastExpensiveBidIncrement, setLeastExpensiveBidIncrement] =
    useState(0);

  const [smallestDialSize, setSmallestWatchDialSize] = useState("");
  const [biggestDialSize, setBiggestWatchDialSize] = useState("");
  const [biggestRosarySize, setBiggestRosarySize] = useState(null);
  const [oldestWatchYear, setOldestWatchYear] = useState(null);
  const [newestWatchYear, setNewestWatchYear] = useState(null);

  const [leastRosaryCount, setLeastRosaryCount] = useState(null);
  const [smallestRosarySize, setSmallestRosarySize] = useState(null);
  const [mostRosaryCount, setMostRosaryCount] = useState(null);

  const [allData, setAllData] = useState(null);

  const queryBuilder = useCallback(() => {
    let _query = query(
      collection(db, "Products"),
      where("isDeleted", "==", false),
      where("isSold", "==", false)
    );

    if (categorySearch) {
      _query = query(_query, where("category", "==", categorySearch));
    }

    return [_query];
  }, [categorySearch]);

  const handleLocalData = () => {
    if (allData && lastVisible.current != null) {
      const _lastVisible = lastVisible.current;
      const filteredProducts = applySort(applyFilter(allData));

      setAllCount(filteredProducts.length);

      const arr = filteredProducts.slice(0, (_lastVisible ?? 0) + pageSize);

      setListing(arr);

      lastVisible.current = lastVisible.current + pageSize;

      setHasMore(arr.length < filteredProducts.length);
      setIsLoading(false);
      return true;
    }
    return false;
  };

  const fetchData = () => {
    if (handleLocalData()) {
      return;
    }

    const [_query] = queryBuilder();

    onSnapshot(_query, (snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => doc.data());
        let products = data.filter((data) => {
          const isWithinExpiry = data.expiryDate
            ? Date.now() / 1000 - data.expiryDate.seconds < 0
            : true;

          return isWithinExpiry;
        });

        setAllData(products);

        const filteredProducts = applySort(applyFilter(products));

        setAllCount(filteredProducts.length);

        const arr = filteredProducts.slice(0, pageSize);

        setListing(arr);

        setHasMore(arr.length < filteredProducts.length);

        lastVisible.current = pageSize;
      } else {
        if (!lastVisible.current) {
          setAllCount(0);
          setListing([]);
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    });
  };

  const applyFilter = (arr) => {
    if (arr.length > 0) {
      arr = arr.filter((item) => {
        const isAuction = item.postType.includes("مزاد");

        if (!res) {
          if (numBidders && isAuction && item.numBidders >= numBidders) {
            return true;
          }
          return true;
        } else {
          const searchTerm = res.split(" ");
          const hasAllElems = searchTerm.every((term) =>
            item.keywords.includes(term)
          );
          if (hasAllElems) {
            return true;
          }
          return false;
        }
      });

      // Condition filter
      if (conditionFilter && conditionFilter.length > 0) {
        arr = arr.filter((item) => conditionFilter.includes(item.condition));
      }

      // Post type filter
      if (postTypeFilter && postTypeFilter.length > 0) {
        arr = arr.filter((item) => {
          if (Array.isArray(item.postType)) {
            return item.postType.some((pt) => postTypeFilter.includes(pt));
          }
          return false;
        });
      }

      // Lowest price filter
      if (lowestPriceFilter && parseFloat(lowestPriceFilter) > 0) {
        arr = arr.filter(
          (item) =>
            item.productPrice !== "" &&
            item.productPrice >= parseFloat(lowestPriceFilter)
        );
      }

      // Highest price filter
      if (heighestPriceFilter && parseFloat(heighestPriceFilter) > 0) {
        arr = arr.filter(
          (item) =>
            item.productPrice !== "" &&
            item.productPrice <= parseFloat(heighestPriceFilter)
        );
      }

      // Lowest entry fee filter
      if (lowestEntryFeeFilter && parseFloat(lowestEntryFeeFilter) > 0) {
        arr = arr.filter(
          (item) => item.entryFee >= parseFloat(lowestEntryFeeFilter)
        );
      }

      // Highest entry fee filter
      if (heighestEntryFeeFilter && parseFloat(heighestEntryFeeFilter) > 0) {
        arr = arr.filter(
          (item) => item.entryFee <= parseFloat(heighestEntryFeeFilter)
        );
      }

      // Lowest starting price filter
      if (
        lowestStartingPriceFilter &&
        parseFloat(lowestStartingPriceFilter) > 0
      ) {
        arr = arr.filter(
          (item) => item.startingPrice >= parseFloat(lowestStartingPriceFilter)
        );
      }

      // Highest starting price filter
      if (
        heighestStartingPriceFilter &&
        parseFloat(heighestStartingPriceFilter) > 0
      ) {
        arr = arr.filter(
          (item) =>
            item.startingPrice <= parseFloat(heighestStartingPriceFilter)
        );
      }

      // Lowest bid increment filter
      if (
        lowestBidIncrementFilter &&
        parseFloat(lowestBidIncrementFilter) > 0
      ) {
        arr = arr.filter(
          (item) => item.bidIncrements >= parseFloat(lowestBidIncrementFilter)
        );
      }

      // Highest bid increment filter
      if (
        heighestBidIncrementFilter &&
        parseFloat(heighestBidIncrementFilter) > 0
      ) {
        arr = arr.filter(
          (item) => item.bidIncrements <= parseFloat(heighestBidIncrementFilter)
        );
      }

      // Shipping methods filter
      if (shippingMethodsFilter && shippingMethodsFilter.length > 0) {
        arr = arr.filter((item) => {
          if (Array.isArray(item.shippingMethods)) {
            return item.shippingMethods.some((pt) =>
              shippingMethodsFilter.includes(pt)
            );
          }
          return false;
        });
      }

      // Company filter
      if (companyFilter && companyFilter.length > 0) {
        arr = arr.filter((item) => companyFilter.includes(item.productCompany));
      }

      // watch department filter
      if (watchDepartmentFilter && watchDepartmentFilter.length > 0) {
        arr = arr.filter((item) =>
          watchDepartmentFilter.includes(item.watch.department)
        );
      }

      // watch outside color filter
      if (watchOutsideColorFilter && watchOutsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          watchOutsideColorFilter.includes(item.watch.outsideColor)
        );
      }

      // watch outside color filter
      if (watchInsideColorFilter && watchInsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          watchInsideColorFilter.includes(item.watch.insideColor)
        );
      }

      // rosary kind filter
      if (rosaryKindFilter && rosaryKindFilter.length > 0) {
        arr = arr.filter((item) => rosaryKindFilter.includes(item.rosary.kind));
      }

      // rosary kind filter
      if (rosaryColorFilter && rosaryColorFilter.length > 0) {
        arr = arr.filter((item) =>
          rosaryColorFilter.includes(item.rosary.color)
        );
      }

      // rosary least count filter
      if (leastRosaryCountFilter && parseFloat(leastRosaryCountFilter) > 0) {
        arr = arr.filter(
          (item) => item.rosary.count >= parseFloat(leastRosaryCountFilter)
        );
      }

      // rosary most count filter
      if (mostRosaryCountFilter && parseFloat(mostRosaryCountFilter) > 0) {
        arr = arr.filter(
          (item) => item.rosary.count <= parseFloat(mostRosaryCountFilter)
        );
      }

      // wallet department filter
      if (walletDepartmentFilter && walletDepartmentFilter.length > 0) {
        arr = arr.filter((item) =>
          walletDepartmentFilter.includes(item.wallet.department)
        );
      }

      // wallet outside color filter
      if (walletOutsideColorFilter && walletOutsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          walletOutsideColorFilter.includes(item.wallet.outsideColor)
        );
      }

      // purse material filter
      if (purseMaterialFilter && purseMaterialFilter.length > 0) {
        arr = arr.filter((item) =>
          purseMaterialFilter.includes(item.purse.material)
        );
      }

      // purse outside coloe filter
      if (purseOutsideColorFilter && purseOutsideColorFilter.length > 0) {
        arr = arr.filter((item) =>
          purseOutsideColorFilter.includes(item.purse.outsideColor)
        );
      }

      // car plate kind filter
      if (carPlateKindFilter && carPlateKindFilter.length > 0) {
        arr = arr.filter((item) =>
          carPlateKindFilter.includes(item.carPlate.kind)
        );
      }

      // Car plate transfer fee on filter
      if (
        carPlateTransferFeeOnFilter &&
        carPlateTransferFeeOnFilter.length > 0
      ) {
        arr = arr.filter((item) =>
          carPlateTransferFeeOnFilter.includes(item.carPlate.transferFeeOn)
        );
      }

      // Car plate transfer time filter
      if (carPlateTransferTimeFilter && carPlateTransferTimeFilter.length > 0) {
        arr = arr.filter((item) =>
          carPlateTransferTimeFilter.includes(item.carPlate.transferTime)
        );
      }

      // phone number courier filter
      if (phoneNumberCourierFilter && phoneNumberCourierFilter.length > 0) {
        arr = arr.filter((item) =>
          phoneNumberCourierFilter.includes(item.phoneNumber.courier)
        );
      }

      // filter for !== "" (optional product values)

      // watch dial shape filter
      if (watchDialShapeFilter && watchDialShapeFilter.length > 0) {
        const filteredWatchDialShapeArr = arr.filter(
          (item) => item.watch.dialShape !== ""
        );
        arr = filteredWatchDialShapeArr.filter((item) =>
          watchDialShapeFilter.includes(item.watch.dialShape)
        );
      }

      // watch smallest dial size filter
      if (smallestDialSizeFilter && parseFloat(smallestDialSizeFilter) > 0) {
        const filteredWatchSDialSizeArr = arr.filter(
          (item) => item.watch.dialSize !== ""
        );
        arr = filteredWatchSDialSizeArr.filter(
          (item) => item.watch.dialSize >= parseFloat(smallestDialSizeFilter)
        );
      }

      // watch biggest dial size filter
      if (biggestDialSizeFilter && parseFloat(biggestDialSizeFilter) > 0) {
        const filteredWatchSDialSizeArr = arr.filter(
          (item) => item.watch.dialSize !== ""
        );
        arr = filteredWatchSDialSizeArr.filter(
          (item) => item.watch.dialSize <= parseFloat(biggestDialSizeFilter)
        );
      }

      // watch handles color filter
      if (watchHandlesColorFilter && watchHandlesColorFilter.length > 0) {
        const filteredWatchHandlesColorArr = arr.filter(
          (item) => item.watch.handlesColor !== ""
        );
        arr = filteredWatchHandlesColorArr.filter((item) =>
          watchHandlesColorFilter.includes(item.watch.handlesColor)
        );
      }

      // watch numbers color filter
      if (watchNumbersColorFilter && watchNumbersColorFilter.length > 0) {
        const filteredWatchNumbersColorArr = arr.filter(
          (item) => item.watch.numbersColor !== ""
        );
        arr = filteredWatchNumbersColorArr.filter((item) =>
          watchNumbersColorFilter.includes(item.watch.numbersColor)
        );
      }

      // watch numbers language filter
      if (watchNumbersLanguageFilter && watchNumbersLanguageFilter.length > 0) {
        const filteredWatchNumbersLanguageArr = arr.filter(
          (item) => item.watch.numbersLanguage !== ""
        );
        arr = filteredWatchNumbersLanguageArr.filter((item) =>
          watchNumbersLanguageFilter.includes(item.watch.numbersLanguage)
        );
      }

      // watch band material filter
      if (watchBandMaterialFilter && watchBandMaterialFilter.length > 0) {
        const filteredWatchBandMaterialArr = arr.filter(
          (item) => item.watch.bandMaterial !== ""
        );
        arr = filteredWatchBandMaterialArr.filter((item) =>
          watchBandMaterialFilter.includes(item.watch.bandMaterial)
        );
      }

      // watch band color filter
      if (watchBandColorFilter && watchBandColorFilter.length > 0) {
        const filteredWatchBandColorArr = arr.filter(
          (item) => item.watch.bandColor !== ""
        );
        arr = filteredWatchBandColorArr.filter((item) =>
          watchBandColorFilter.includes(item.watch.bandColor)
        );
      }

      // watch has original packaging filter
      if (
        watchHasOriginalPackagingFilter &&
        watchHasOriginalPackagingFilter.length > 0
      ) {
        const filteredWatchHasOriginalPackagingArr = arr.filter(
          (item) => item.watch.hasOriginalPackaging !== ""
        );
        arr = filteredWatchHasOriginalPackagingArr.filter((item) =>
          watchHasOriginalPackagingFilter.includes(
            item.watch.hasOriginalPackaging
          )
        );
      }

      // watch is water resistant filter
      if (
        watchIsWaterResistantFilter &&
        watchIsWaterResistantFilter.length > 0
      ) {
        const filteredWatchIsWaterResistantArr = arr.filter(
          (item) => item.watch.isWaterResistant !== ""
        );
        arr = filteredWatchIsWaterResistantArr.filter((item) =>
          watchIsWaterResistantFilter.includes(item.watch.isWaterResistant)
        );
      }

      // watch oldest year made filter
      if (
        watchOldestYearMadeFilter &&
        parseFloat(watchOldestYearMadeFilter) > 0
      ) {
        const filteredWatchOldestYearMadeArr = arr.filter(
          (item) => item.watch.yearMade !== ""
        );
        arr = filteredWatchOldestYearMadeArr.filter(
          (item) => item.watch.yearMade >= parseFloat(watchOldestYearMadeFilter)
        );
      }

      // watch newest year made filter
      if (
        watchNewestYearMadeFilter &&
        parseFloat(watchNewestYearMadeFilter) > 0
      ) {
        const filteredWatchNewestYearMadeArr = arr.filter(
          (item) => item.watch.yearMade !== ""
        );
        arr = filteredWatchNewestYearMadeArr.filter(
          (item) => item.watch.yearMade <= parseFloat(watchNewestYearMadeFilter)
        );
      }

      // Sort and filter logic
      const filteredPriceArr = arr.filter((item) => item.productPrice !== "");
      if (filteredPriceArr.length > 0) {
        const sortedByPrice = [...filteredPriceArr].sort(
          (a, b) => b.productPrice - a.productPrice
        );
        // setMostExpensiveItemPrice(parseFloat(sortedByPrice[0].productPrice));
        // setLeastExpensiveItemPrice(parseFloat(sortedByPrice[sortedByPrice.length - 1].productPrice));
      }

      const filteredAuctionsArr = arr.filter((item) =>
        item.postType.includes("مزاد")
      );
      if (filteredAuctionsArr.length > 0) {
        // setHasAucionPosts(true);
        const sortedByEntryFee = [...filteredAuctionsArr].sort(
          (a, b) => b.entryFee - a.entryFee
        );
        setMostExpensiveItemEntryFee(parseFloat(sortedByEntryFee[0].entryFee));
        setLeastExpensiveItemEntryFee(
          parseFloat(sortedByEntryFee[sortedByEntryFee.length - 1].entryFee)
        );

        const sortedByStartingPrice = [...filteredAuctionsArr].sort(
          (a, b) => b.startingPrice - a.startingPrice
        );
        setMostExpensiveItemStartingPrice(
          parseFloat(sortedByStartingPrice[0].startingPrice)
        );
        setLeastExpensiveItemStartingPrice(
          parseFloat(
            sortedByStartingPrice[sortedByStartingPrice.length - 1]
              .startingPrice
          )
        );

        const sortedByBidIncrement = [...filteredAuctionsArr].sort(
          (a, b) => b.bidIncrements - a.bidIncrements
        );
        setMostExpensiveBidIncrement(
          parseFloat(sortedByBidIncrement[0].bidIncrements)
        );
        setLeastExpensiveBidIncrement(
          parseFloat(
            sortedByBidIncrement[sortedByBidIncrement.length - 1].bidIncrements
          )
        );
      }

      // Watches
      if (categorySearch === "ساعات") {
        const filteredDialSizeArr = arr.filter(
          (item) => item.watch.dialSize !== "" && !isNaN(item.watch.dialSize)
        );

        if (filteredDialSizeArr.length > 0) {
          const sortedByDialSize = [...filteredDialSizeArr].sort(
            (a, b) => b.watch.dialSize - a.watch.dialSize
          );

          setSmallestWatchDialSize(
            parseFloat(
              sortedByDialSize[sortedByDialSize.length - 1].watch.dialSize
            )
          );
          setBiggestWatchDialSize(
            parseFloat(sortedByDialSize[0].watch.dialSize)
          );
        }

        const filteredWatchYearMadeArr = arr.filter(
          (item) => item.watch.yearMade !== "" && !isNaN(item.watch.yearMade)
        );
        if (filteredWatchYearMadeArr.length > 0) {
          const sortedByYearMade = [...filteredWatchYearMadeArr].sort(
            (a, b) => b.watch.yearMade - a.watch.yearMade
          );
          setNewestWatchYear(parseFloat(sortedByYearMade[0].watch.yearMade));
          setOldestWatchYear(
            parseFloat(
              sortedByYearMade[sortedByYearMade.length - 1].watch.yearMade
            )
          );
        }
      } else if (categorySearch === "سبح") {
        const filteredRosaryCountArr = arr.filter(
          (item) => item.rosary.count !== ""
        );

        if (filteredRosaryCountArr.length > 0) {
          const sortedByRosaryCount = [...arr].sort(
            (a, b) => b.rosary.count - a.rosary.count
          );
          setMostRosaryCount(parseFloat(sortedByRosaryCount[0].rosary.count));
          setLeastRosaryCount(
            parseFloat(
              sortedByRosaryCount[sortedByRosaryCount.length - 1].rosary.count
            )
          );
        }

        const filteredRosarySizeArr = arr.filter(
          (item) => item.rosary.size !== ""
        );
        if (filteredRosarySizeArr.length > 0) {
          const sortedByRosarySize = [...filteredRosarySizeArr].sort(
            (a, b) => b.rosary.size - a.rosary.size
          );
          setBiggestRosarySize(parseFloat(sortedByRosarySize[0].rosary.size));
          setSmallestRosarySize(
            parseFloat(
              sortedByRosarySize[sortedByRosarySize.length - 1].rosary.size
            )
          );

          // rosary smallest size filter
          if (
            smallestRosarySizeFilter &&
            parseFloat(smallestRosarySizeFilter) > 0
          ) {
            arr = arr.filter(
              (item) => item.rosary.size >= parseFloat(smallestRosarySizeFilter)
            );
          }

          // rosary biggest size filter
          if (
            biggestRosarySizeFilter &&
            parseFloat(biggestRosarySizeFilter) > 0
          ) {
            arr = arr.filter(
              (item) => item.rosary.size <= parseFloat(biggestRosarySizeFilter)
            );
          }
        }
      } else if (categorySearch === "حقائب نسائية") {
        const filteredPurseInsideColorArr = arr.filter(
          (item) => item.purse.insideColor !== ""
        );
        if (filteredPurseInsideColorArr.length > 0) {
          // purse inside coloe filter
          if (purseInsideColorFilter && purseInsideColorFilter.length > 0) {
            arr = arr.filter((item) =>
              purseInsideColorFilter.includes(item.purse.insideColor)
            );
          }
        }
      }
    }

    return arr;
  };

  const applySort = (arr) => {
    let sortedArr = arr;
    switch (urlSortOption) {
      case "من الأقدم إلى الأحدث":
        sortedArr = [...arr].sort((a, b) => b.createdAt - a.createdAt);
        break;
      case "من الأحدث إلى الأقدم":
        sortedArr = [...arr].sort((a, b) => a.createdAt - b.createdAt);
        break;
      case "سعر الشراء تنازليا":
        const filteredAndSortedDescHasProductPrice = [...arr]
          .filter((post) => post.productPrice !== "") // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.productPrice - a.productPrice);
        sortedArr = filteredAndSortedDescHasProductPrice;
        break;
      case "سعر الشراء تصاعديا":
        const filteredAndSortedAscHasProductPrice = [...arr]
          .filter((post) => post.productPrice !== "") // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.productPrice - b.productPrice);
        sortedArr = filteredAndSortedAscHasProductPrice;
        break;
      case "تاريخ انتهاء المزاد تصاعديا":
        const filteredAndSortedAscArr_expiryDate = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.expiryDate - b.expiryDate);
        sortedArr = filteredAndSortedAscArr_expiryDate;
        break;
      case "تاريخ انتهاء المزاد تنازليا":
        const filteredAndSortedDescArr_expiryDate = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.expiryDate - a.expiryDate);
        sortedArr = filteredAndSortedDescArr_expiryDate;
        break;
      case "سعر المزايدة الحالي تنازليا":
        const filteredAndSortedDescArr_currentBid = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.currentBid - a.currentBid);
        sortedArr = filteredAndSortedDescArr_currentBid;
        break;
      case "سعر المزايدة الحالي تصاعديا":
        const filteredAndSortedAscArr_currentBid = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.currentBid - b.currentBid);
        sortedArr = filteredAndSortedAscArr_currentBid;
        break;
      case "عدد المزايدين تصاعديا":
        const filteredAndSortedAscArr_numBidders = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => a.numBidders - b.numBidders);
        sortedArr = filteredAndSortedAscArr_numBidders;
        break;
      case "عدد المزايدين تنازليا":
        const filteredAndSortedDescArr_numBidders = [...arr]
          .filter((post) => post.postType.includes("مزاد")) // Filter posts where postType includes "مزاد"
          .sort((a, b) => b.numBidders - a.numBidders);
        sortedArr = filteredAndSortedDescArr_numBidders;
        break;
      default:
        sortedArr = [...arr].sort((a, b) => b.createdAt - a.createdAt);
        break;
    }

    return sortedArr;
  };

  useEffect(() => {
    window.scroll(0, 0);
    setListing([]);
    setAllData(null);
    setIsLoading(true);
    setTimeout(() => {
      lastVisible.current = null;
      fetchData();
    }, 200);
  }, [categorySearch]);

  useEffect(() => {
    window.scroll(0, 0);
    setListing([]);
    setIsLoading(true);
    setTimeout(() => {
      if (lastVisible.current != null) {
        lastVisible.current = 0;
        fetchData();
      }
    }, 200);
  }, [
    res,
    orderSelectedName,
    urlSortOption,
    numBidders,
    conditionFilter,
    postTypeFilter,
    lowestPriceFilter,
    heighestPriceFilter,
    lowestEntryFeeFilter,
    heighestEntryFeeFilter,
    lowestStartingPriceFilter,
    heighestStartingPriceFilter,
    lowestBidIncrementFilter,
    heighestBidIncrementFilter,
    shippingMethodsFilter,
    companyFilter,
    watchDepartmentFilter,
    watchOutsideColorFilter,
    watchInsideColorFilter,
    watchDialShapeFilter,
    smallestDialSizeFilter,
    biggestDialSizeFilter,
    watchHandlesColorFilter,
    watchNumbersColorFilter,
    watchNumbersLanguageFilter,
    watchBandMaterialFilter,
    watchBandColorFilter,
    watchHasOriginalPackagingFilter,
    watchIsWaterResistantFilter,
    watchOldestYearMadeFilter,
    watchNewestYearMadeFilter,
    rosaryKindFilter,
    rosaryColorFilter,
    leastRosaryCountFilter,
    mostRosaryCountFilter,
    smallestRosarySizeFilter,
    biggestRosarySizeFilter,
    walletDepartmentFilter,
    walletOutsideColorFilter,
    purseMaterialFilter,
    purseOutsideColorFilter,
    purseInsideColorFilter,
    carPlateKindFilter,
    carPlateTransferFeeOnFilter,
    carPlateTransferTimeFilter,
    phoneNumberCourierFilter,
  ]);

  useEffect(() => {
    if (lastDoc > 0) {
      setTimeout(() => {
        fetchData();
      }, 300);
    }
  }, [lastDoc]);

  return {
    isLoading,
    listing,
    allCount,
    hasMore,
    leastExpensiveBidIncrement,
    mostExpensiveBidIncrement,
    leastExpensiveItemStartingPrice,
    mostExpensiveItemStartingPrice,
    leastExpensiveItemEntryFee,
    mostRosaryCount,
    smallestRosarySize,
    leastRosaryCount,
    newestWatchYear,
    oldestWatchYear,
    biggestRosarySize,
    biggestDialSize,
    mostExpensiveItemEntryFee,
    smallestDialSize,
  };
};

export default useInfiniteScroll;
