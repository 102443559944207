import React , {useState, useEffect} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot,deleteDoc, doc, query, where, limit, serverTimestamp , getDoc, Timestamp} from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { Divider } from '@mui/material';
import { FaCalendarAlt } from "react-icons/fa";
import carPlateIcon from '../assets/car-plate-icon00C8B8.png'
import SelectableCategory from '../components/SelectableCategory';
import watch from '../assets/watches.png'
import sbhah from '../assets/sbhah.png'
import pen from '../assets/pen2.png'
import wallet from '../assets/wallets.png'
import plateIcon from '../assets/carPlateW.png'
import purses from '../assets/purses.png'
import { GiBangingGavel } from "react-icons/gi";
import Lottie from 'react-lottie';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import phoneNumberIcon from '../assets/phoneNumbers.png'
import { IoMdMale } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import Countdown from 'react-countdown';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import f from '../assets/furniture.jpg';
import { useRef } from 'react';
import { Oval } from 'react-loader-spinner'
import { db, auth, storage } from '../config/firebase'
import { listAll, ref, getDownloadURL, uploadBytes, deleteObject  } from 'firebase/storage';
import ImageCropper from '../components/ImageCropper';
import { getBlobFromURL } from '../utils'; // Import a function to convert URL to Blob
import DateTimeWithPlaceholder from '../components/DateTimeWithPlaceHolder';
import { BsStopwatchFill } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import LoadingScreen from './LoadingScreen';
import { FaMoneyBillWave } from "react-icons/fa6";
import { GiWeight } from "react-icons/gi";
import { LuCircleDashed } from "react-icons/lu";
import { TbCategoryFilled } from "react-icons/tb";
import { MdFactory } from "react-icons/md";
import { FaTransgender } from "react-icons/fa";
import { HiMiniPaintBrush } from "react-icons/hi2";
import { IoResize } from "react-icons/io5";
import { FaShapes } from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";
import { IoIosWatch } from "react-icons/io";
import { BiSolidPackage } from "react-icons/bi";
import { IoIosWater } from "react-icons/io";
import { MdInfo } from "react-icons/md";
import SebhahIcon from '../assets/sebhah00C8B8.PNG'
import { GoNumber } from "react-icons/go";
import { FaSignal } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import SelectableWatchCompany from '../components/SelectableWatchCompany';
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import galleryPlaceholder from '../assets/galley-placeholder.jpg'
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { IoMdClose } from "react-icons/io";
import e from 'cors';
import accounting from 'accounting';
import SuccessAnim from '../components/SuccessAnim';
import rolexLogo from '../assets/RolexLogo.png'
import omegaLogo from '../assets/omega-logo.png'
import cartierLogo from '../assets/cartier-logo.png'
import versaceLogo from '../assets/Versace-Logo.png'
import montBlancLogo from '../assets/mont-blanc-logo.png'
import aignerLogo from '../assets/aigner-logo.png'
import radoLogo from '../assets/rado-logo.png'
import givenchyLogo from '../assets/givenchy-logo.png'
import chopardLogo from '../assets/chopard-logo.png'
import tissotLogo from '../assets/tissot-logo.png'
import patekPhilippeLogo from '../assets/patek-philippe-logo.png'
import armaniExchangeLogo from '../assets/armani-exchange-logo.png'
import chanelLogo from '../assets/chanel-logo.png'
import lvLogo from '../assets/lv-logo.png'
import gucciLogo from '../assets/gucci-logo.png'
import DGLogo from '../assets/dg-logo.png'
import tomFordLogo from '../assets/tom-ford-logo.png'
import fredPerryLogo from '../assets/fred-perry-logo.png'
import pradaLogo from '../assets/prada-logo.png'
import balenciagaLogo from '../assets/balenciaga-logo.png'
import yslLogo from '../assets/YSL-logo.png'
import toryBurchLogo from '../assets/tory-borch-logo.png'
import coachLogo from '../assets/coach-logo.png'
import tedBakerLogo from '../assets/ted-baker-logo.png'
import charlesKeithLogo from '../assets/charles-and-keith-logo.png'
import carPlateWhite from '../assets/car-plate-empty.png'
import carPlateYellow from '../assets/car-plate-empty-yellow.png'
import carPlateBlue from '../assets/car-plate-empty-blue.png'
import guessLogo from '../assets/guess-logo.png'
import offWhiteLogo from '../assets/off-white-logo.png'
import michaelKorsLogo from '../assets/michael-kors-logo.png'
import appleLogo from '../assets/apple-logo.png'
import gShockLogo from '../assets/g-shock-logo.png'
import huaweiLogo from '../assets/huawei-logo.png'
import samsungLogo from '../assets/samsung-logo.png'
import xiaomiLogo from '../assets/xiaomi-logo.png'
import diorLogo from '../assets/dior-logo.png'
import stcLogo from '../assets/stc-logo.png'
import mobilyLogo from '../assets/mobily-logo.png'
import zainLogo from '../assets/zain-logo.png'
import { FaQuestion } from "react-icons/fa6";
import { IoFemale } from "react-icons/io5";
import { FaRegCircle } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";
import { PiRectangleBold } from "react-icons/pi";
import { TbOval } from "react-icons/tb";
import { RiPentagonLine } from "react-icons/ri";
import { LuHexagon } from "react-icons/lu";
import { IoMdCheckmark } from "react-icons/io";
import YearMade from '../components/YearMade';
import SelectableCondition from '../components/SelectableCondition';
import PostType from '../components/PostType';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import NextSevenDays from '../components/NextSevenDays';
import Datetime from 'react-datetime';
import moment from 'moment';
import "moment/locale/ar"; // Import Arabic locale
import "react-datetime/css/react-datetime.css";
import smsa from '../assets/smsa.png'
import { FaRegHandshake } from "react-icons/fa";
import rosaryIcon from '../assets/sebhah00C8B8.PNG'
import carPlateImageEmpty from '../assets/car-plate-empty.png'
import carPlateImageBlue from '../assets/car-plate-empty-blue.png'
import carPlateImageYellow from '../assets/car-plate-empty-yellow.png'
import CarPlate from '../components/CarPlate';
import { FaCarAlt } from "react-icons/fa";
import { FaMotorcycle } from "react-icons/fa6";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import { FaTruckPickup } from "react-icons/fa6";
import PostTypeSingle from '../components/PostTypeSingle';
import { RiShoppingBagLine } from "react-icons/ri";
import PhoneNumber from '../components/PhoneNumber';
import { FaShippingFast } from "react-icons/fa";
import { GoHomeFill, GoReport } from "react-icons/go";
import { getFunctions, httpsCallable } from 'firebase/functions';
import notFound from '../assets/animations/ProductNotFoundAnim.json'
import aldoLogo from '../assets/aldo-logo.png'


function MyPostDetailsScreen({myId, isBusiness}) {
  const [Listing, setListing] = useState([]);
  const [gal, setGal] = useState([]);
  const [tempGal, setTempGal] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarCategory, setSimilarCategory] = useState([]);
  const [productCondition, setProductCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [productQty, setProductQty] = useState(1);
  const [newProductQty, setNewProductQty] = useState("");
  const [sebhahColor, setSebhahColor] = useState("");
  const [walletDepartment, setWalletDepartment] = useState("");
  const [newWalletDepartment, setNewWalletDepartment] = useState("");
  const [currentBid, setCurrentBid] = useState("");
  const [watchBandColorScrollTrigger, setWatchBandColorScrollTrigger] = useState(false);

  const [watchDepartment, setWatchDepartment] = useState("");
  const [newWatchDepartment, setNewWatchDepartment] = useState("");
  const [watchNewInsideColor, setWatchNewInsideColor] = useState("")
  const [watchInsideColor, setWatchInsideColor] = useState("")
  const [watchInsideColorSpecification, setWatchInsideColorSpecification] = useState("")
  const [watchNewInsideColorSpecification, setWatchNewInsideColorSpecification] = useState("")
  const [watchOutsideColor, setWatchOutsideColor] = useState("")
  const [watchNewOutsideColor, setWatchNewOutsideColor] = useState("")
  const [watchOutsideColorSpecification, setWatchOutsideColorSpecification] = useState("")
  const [watchNewOutsideColorSpecification, setWatchNewOutsideColorSpecification] = useState("")
  const [watchDialSize, setWatchDialSize] = useState(0)
  const [watchNewDialSize, setWatchNewDialSize] = useState(0)
  const [watchDialShape, setWatchDialShape] = useState("")
  const [sebhahCount, setSebhahCount] = useState("")
  const [watchYearMadeScrollTrigger, setWatchYearMadeScrollTrigger] = useState(false);
  const walletCompanyRef = useRef();
  const walletColorRef = useRef();
  const carPlateNumbersRef = useRef();
  const [carPlateNumbersScrollTrigger, setCarPlateNumbersScrollTrigger] = useState(false)
  const purseMaterialRef = useRef();
  const [phoneNumberToSellScrollTrigger, setPhoneNumberToSellScrollTrigger] = useState(false)

  const [watchNumbersLanguageScrollTrigger, setWatchNumbersLanguageScrollTrigger] = useState(false);
  const [phoneCourierScrollTrigger, setPhoneCourierScrollTrigger] = useState(false)
  const [purseMaterialScrollTrigger, setPurseMaterialScrollTrigger] = useState(false)
  const [walletColorScrollTrigger, setWalletColorScrollTrigger] = useState(false)
  const [purseMaterial, setPurseMaterial] = useState("")
  const [walletDepartmentScrollTrigger, setWalletDepartmentScrollTrigger] = useState(false);
  const [carPlateKindScrollTrigger, setCarPlateKindScrollTrigger] = useState(false)
  const [carPlateTransferFeeOnScrollTrigger, setCarPlateTransferFeeOnScrollTrigger] = useState(false)
  const [carPlateTransferTimeScrollTrigger, setCarPlateTransferTimeScrollTrigger] = useState(false)
  const [purseInsideScrollTrigger, setPurseInsideColorScrollTrigger] = useState(false);
  const [productConditionScrollTrigger, setProductConditionScrollTrigger] = useState(false);

  const [watchNewDialShape, setWatchNewDialShape] = useState("")
  const [watchHandlesColor, setWatchHandlesColor] = useState("")
  const [watchHandlesColorSpecification, setWatchHandlesColorSpecification] = useState("")
  const [watchNewHandlesColor, setWatchNewHandlesColor] = useState("")
  const [watchNewHandlesColorSpecification, setWatchNewHandlesColorSpecification] = useState("")
  const [watchNumbersLanguage, setWatchNumbersLanguage] = useState("")
  const [sebhahSize, setSebhahSize] = useState("")
const [watchHandlesColorScrollTrigger, setWatchHandlesColorScrollTrigger] = useState(false);
const [watchNumbersColorScrollTrigger, setWatchNumbersColorScrollTrigger] = useState(false);
const [watchHasOriginalPackagingScrollTrigger, setWatchHasOriginalPackagingScrollTrigger] = useState(false);
const phoneNumberToSellDivRef = useRef()

  const [phoneNumberCourier, setPhoneNumberCourier] = useState("");
  const [newPhoneNumberCourier, setNewPhoneNumberCourier] = useState("");
  const [phoneNumberToSell, setPhoneNumberToSell] = useState("");
  const [newPhoneNumberToSell, setNewPhoneNumberToSell] = useState("");
  const [purseOutsideColorSpecificationHasError, setPurseOutsideColorSpecificationHasError] = useState(false);
  const [purseOutsideColorSpecification, setPurseOutsideColorSpecification] = useState(false);
  const purseOutsideColorSpecificationRef = useRef();
  const [purseNewOutsideColorSpecification, setPurseNewOutsideColorSpecification] = useState("");
  const [purseInsideColorSpecificationHasError, setPurseInsideColorSpecificationHasError] = useState(false);
  const [purseInsideColorSpecification, setPurseInsideColorSpecification] = useState(false);
  const [purseNewInsideColorSpecificationHasError, setPurseNewInsideColorSpecificationHasError] = useState(false);
  const purseInsideColorSpecificationRef = useRef();
const [purseNewInsideColorSpecification, setPurseNewInsideColorSpecification] = useState("")
  const [phoneNumberToSellHasError, setPhoneNumberToSellHasError] = useState(false);
  const phoneNumberToSellRef = useRef();
  const [purseInsideColor, setPurseInsideColor] = useState("");
  const [purseNewInsideColor, setPurseNewInsideColor] = useState("");
  const purseInsideColorRef = useRef();
  const [carPlateTransferFeeOn, setCarPlateTransferFeeOn] = useState("");
  const [carPlateTransferFeeOnHasError, setCarPlateTransferFeeOnHasError] = useState("");
  const [carPlateNewTransferFeeOn, setCarPlateNewTransferFeeOn] = useState("");
  const [carPlateFirstArabicLetter, setCarPlateFirstArabicLetter] = useState("");
  const [carPlateNewFirstArabicLetter, setCarPlateNewFirstArabicLetter] = useState("");
  const [carPlateFirstEnglishLetter, setCarPlateFirstEnglishLetter] = useState("");
  const [carPlateNewFirstEnglishLetter, setCarPlateNewFirstEnglishLetter] = useState("");
  const [carPlateFirstArabicLetterHasError, setCarPlateFirstArabicLetterHasError] = useState(false);
  const [carPlateFirstEnglishLetterHasError, setCarPlateFirstEnglishLetterHasError] = useState(false);
  const carPlateSecondArabicLetterRef = useRef();
  const [carPlateSecondArabicLetter, setCarPlateSecondArabicLetter] = useState("");
  const [carPlateNewSecondArabicLetter, setCarPlateNewSecondArabicLetter] = useState("");
  const [carPlateSecondEnglishLetter, setCarPlateSecondEnglishLetter] = useState("");
  const [carPlateNewSecondEnglishLetter, setCarPlateNewSecondEnglishLetter] = useState("");
  const carPlateThirdArabicLetterRef = useRef();
  const [carPlateThirdArabicLetter, setCarPlateThirdArabicLetter] = useState("");
  const [carPlateNewThirdArabicLetter, setCarPlateNewThirdArabicLetter] = useState("");
  const [carPlateThirdEnglishLetter, setCarPlateThirdEnglishLetter] = useState("");
  const [carPlateNewThirdEnglishLetter, setCarPlateNewThirdEnglishLetter] = useState("");

  const [watchDepartmentScrollTrigger, setWatchDepartmentScrollTrigger] = useState(false);
  const [productCompanyScrollTrigger, setProductCompanyScrollTrigger] = useState(false);

  const [purseInsideColorHasError, setPurseInsideColorHasError] = useState(false);
  const [carPlateKindHasError, setCarPlateKindHasError] = useState(false);
  const [carPlateKind, setCarPlateKind] = useState("")
  const [newCarPlateKind, setNewCarPlateKind] = useState("")
  const carPlateFirstArabicLetterRef = useRef();
  const carPlateFirstArabicNumberRef = useRef();
  const [carPlateFirstArabicNumber, setCarPlateFirstArabicNumber] = useState("");
  const [carPlateNewFirstArabicNumber, setCarPlateNewFirstArabicNumber] = useState("");
  const [carPlateFirstEnglishNumber, setCarPlateFirstEnglishNumber] = useState("");
  const [carPlateNewFirstEnglishNumber, setCarPlateNewFirstEnglishNumber] = useState("");
  const [carPlateFirstArabicNumberHasError, setCarPlateFirstArabicNumberHasError] = useState(false);
  const [carPlateFirstEnglishNumberHasError, setCarPlateFirstEnglishNumberHasError] = useState(false);
  const carPlateSecondArabicNumberRef = useRef();
  const [carPlateSecondArabicNumber, setCarPlateSecondArabicNumber] = useState("");
  const [carPlateNewSecondArabicNumber, setCarPlateNewSecondArabicNumber] = useState("");
  const [carPlateSecondEnglishNumber, setCarPlateSecondEnglishNumber] = useState("");
  const [carPlateNewSecondEnglishNumber, setCarPlateNewSecondEnglishNumber] = useState("");
  const carPlateThirdArabicNumberRef = useRef();
  const [carPlateThirdArabicNumber, setCarPlateThirdArabicNumber] = useState("");
  const [carPlateNewThirdArabicNumber, setCarPlateNewThirdArabicNumber] = useState("");
  const [carPlateThirdEnglishNumber, setCarPlateThirdEnglishNumber] = useState("");
  const [carPlateNewThirdEnglishNumber, setCarPlateNewThirdEnglishNumber] = useState("");
  const carPlateFourthArabicNumberRef = useRef();
  const productQtyRef = useRef();
  const [carPlateFourthArabicNumber, setCarPlateFourthArabicNumber] = useState("");
  const [carPlateNewFourthArabicNumber, setCarPlateNewFourthArabicNumber] = useState("");
  const [carPlateFourthEnglishNumber, setCarPlateFourthEnglishNumber] = useState("");
  const [carPlateNewFourthEnglishNumber, setCarPlateNewFourthEnglishNumber] = useState("");
  const carPlateFirstEnglishLetterRef = useRef();
  const carPlateSecondEnglishLetterRef = useRef();
  const carPlateThirdEnglishLetterRef = useRef();
  const carPlateFirstEnglishNumberRef = useRef();
  const carPlateSecondEnglishNumberRef = useRef();
  const carPlateThirdEnglishNumberRef = useRef();
  const carPlateFourthEnglishNumberRef = useRef();
  const productExpiryDateRef = useRef();
  const [carPlateTransferTimeHasError, setCarPlateTransferTimeHasError] = useState(false);
  const [productQtyHasError, setProductQtyHasError] = useState(false);
  const [carPlateTransferTime, setCarPlateTransferTime] = useState("")
  const [carPlateNewTransferTime, setCarPlateNewTransferTime] = useState("")
  const [watchOutsideColorScrollTrigger, setWatchOutsideColorScrollTrigger] = useState(false);
  const [watchDialShapeScrollTrigger, setWatchDialShapeScrollTrigger] = useState(false);
  const [watchDialSizeScrollTrigger, setWatchDialSizeScrollTrigger] = useState(false);
  const [watchBandMaterialScrollTrigger, setWatchBandMaterialScrollTrigger] = useState(false);
  const [rosaryColorScrollTrigger, setRosaryColorScrollTrigger] = useState(false);
  const [rosaryKindScrollTrigger, setRosaryKindScrollTrigger] = useState(false);
  const rosaryColorDivRef = useRef();
  const rosaryCountDivRef = useRef();
  const rosarySizeDivRef = useRef();
  const rosaryCountRef = useRef();
  const rosarySizeRef = useRef();
  const [rosaryCountHasError, setRosaryCountHasError] = useState(false);

  const [rosaryKindHasError, setRosaryKindHasError] = useState(false);
  const [rosaryColorHasError, setRosaryColorHasError] = useState(false);

  const [rosarySizeScrollTrigger, setRosarySizeScrollTrigger] = useState(false);

  const [rosaryCountScrollTrigger, setRosaryCountScrollTrigger] = useState(false);

  const [watchNewNumbersLanguage, setWatchNewNumbersLanguage] = useState("")
  const [watchNumbersColor, setWatchNumbersColor] = useState("")
  const [watchNumbersColorSpecification, setWatchNumbersColorSpecification] = useState("")
  const [watchNewNumbersColor, setWatchNewNumbersColor] = useState("")
  const [watchNewNumbersColorSpecification, setWatchNewNumbersColorSpecification] = useState("")
  const [watchBandMaterial, setWatchBandMaterial] = useState("")
  const [watchNewBandMaterial, setWatchNewBandMaterial] = useState("")
  const [watchBandColor, setWatchBandColor] = useState("")
  const [watchBandColorSpecification, setWatchBandColorSpecification] = useState("")
  const [watchNewBandColor, setWatchNewBandColor] = useState("")
  const [watchNewBandColorSpecification, setWatchNewBandColorSpecification] = useState("")
  const [watchHasOriginalPackaging, setWatchHasOriginalPackaging] = useState(null)
  const [watchNewHasOriginalPackaging, setWatchNewHasOriginalPackaging] = useState(null)
  const [watchYearMade, setWatchYearMade] = useState(0)
  const [watchNewYearMade, setWatchNewYearMade] = useState("")
  const [watchIsWaterResistant, setWatchIsWaterResistant] = useState(null)
  const [watchNewIsWaterResistant, setWatchNewIsWaterResistant] = useState(null)
  const [productDescription, setProductDescription] = useState("");
  const purseOutsideColorRef = useRef();
  const [purseOutsideColorHasError, setPurseOutsideColorHasError] = useState(false);
  
  const [purseOutsideColor, setPurseOutsideColor] = useState("");
  const [purseNewOutsideColor, setPurseNewOutsideColor] = useState("");
  const [productPrice, setProductPrice] = useState();
  const [productCategory, setProductCategory] = useState();
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Products")));
  const [isLoading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [hasExpired, setExpired] = useState(false);
  const [isAuction, setIsAuction] = useState(false);
  const [newIsAuction, setNewIsAuction] = useState(false);
  const [newProductCondition, setNewProductCondition] = useState(false);
  const [newProductCategory, setNewProductCategory] = useState(false);
  const [postId, setPostId] = useState("");
  const [sebhahKind, setSebhahKind] = useState("");
  const [images, setImages] = useState([]);
  const [blobImgs, setBlobImgs] = useState([]);
  const [hasUpdatedImages, setHasUpdatedImages] = useState(false);
  const [productIsSold, setProductIsSold] = useState(false);
  const [doesntExist, setDoesntExist] = useState(false);
  const [watchDepartmentHasError, setWatchDepartmentHasError] = useState(false);
  const watchDialSizeRef = useRef();
  const watchDialShapeRef = useRef();
  const watchHandlesColorRef = useRef();

  const [originalImgs, setOriginalImgs] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [bidders, setBidders] = useState(0);
  const location = useLocation();
  const [passedDateSelected, setPassedDateSelected] = useState(false);
  const [productExpiryDate, setProductExpiryDate] = useState("");
  const [productNewExpiryDate, setProductNewExpiryDate] = useState("");
  const [newSebhahKind, setNewSebhahKind] = useState("");
  const [productCompany, setProductCompany] = useState("");
  const [newProductCompany, setNewProductCompany] = useState("");
  const [newSebhahKindSpecification, setNewSebhahKindSpecification] = useState("");
  const [newSebhahCount, setNewSebhahCount] = useState(0);
  const [newSebhahColor, setNewSebhahColor] = useState("");
  const [newSebhahSize, setNewSebhahSize] = useState(0);
  const [newSebhahColorSpecification, setNewSebhahColorSpecification] = useState("");
  const [productCompanyHasError, setProductCompanyHasError] = useState(false);
  const [passedOneWeekSelected, setPassedOneWeekSelected] = useState(false);
  const [sebhahKindSpecificationHasError, setSebhahKindSpecificationHasError] = useState(false);
  const [sebhahColorHasError, setSebhahColorHasError] = useState(false);
  const [sebhahCountHasError, setSebhahCountHasError] = useState(false);
  const [sebhahColorSpecificationHasError, setSebhahColorSpecificationHasError] = useState(false);
  const [sebhahSizeHasError, setSebhahSizeHasError] = useState(false);
  const [walletDepartmentHasError, setWalletDepartmentHasError] = useState(false);
  const [phoneNumberCourierHasError, setPhoneNumberCourierHasError] = useState(false);
  const [watchInsideColorScrollTrigger, setWatchInsideColorScrollTrigger] = useState(false);



  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

const [postType, setPostType] = useState([])

  const phoneNumberCourierRef = useRef();

  const walletDepartmentRef = useRef();
  const sebhahSizeRef = useRef();
  const sebhahCountRef = useRef();
  const sebhahColorSpecificationRef = useRef();
  const sebhahColorRef = useRef();
  const watchHandlesSpecificationRef = useRef();
  const sebhahKindSpecificationRef = useRef();
  const watchNumbersLanguageRef = useRef();
  const sebhahKindRef = useRef();
  const watchBandColorRef = useRef();
  const watchBandColorSpecificationRef = useRef();
  const watchHasOriginalPackagingRef = useRef();
  const watchYearMadeRef= useRef();
  const watchIsWaterResistantRef= useRef();
  const watchNumbersColorRef = useRef();
  const watchNumbersColorSpecificationRef = useRef();
  const [entryFee, setEntryFee] = useState(10);
  const [newEntryFee, setNewEntryFee] = useState(10);
  const [entryFeeHasError, setEntryFeeHasError] = useState(false);
  const [sebhahKindHasError, setSebhahKindHasError] = useState(false);
  const bidIncrementRef = useRef();
  const [watchIsWaterResistantScrollTrigger, setWatchIsWaterResistantScrollTrigger] = useState(false);

  const rosaryKindRef = useRef();

  const carPlateTransferTimeRef = useRef();
  const carPlateKindRef = useRef();
  const watchBandMaterialRef = useRef();

  const [postImages, setPostImages] = useState([]);
  const [newPostImages, setNewPostImages] = useState([]);

  const phoneNumberCourierDivRef = useRef()

  const watchYearMadeOptions = [];
  const purseCompanyRef = useRef();

  for (let year = 1990; year <= 2024; year++) {
    watchYearMadeOptions.push(<option style={{backgroundColor:"white", textAlign:"right"}} key={year} value={year}>{year}</option>);
  }
  const navigate = useNavigate();
  const sebhahSizes = [];

  for (let size = 5; size <= 16; size += .5){
    sebhahSizes.push(<option style={{backgroundColor:"white", textAlign:"right"}} key={size} value={size}>{size} ملم</option>)
  }

  const [productToCategoryScrollTrigger, setProductToCategoryScrollTrigger] = useState(false);



  // useEffect(() => {
  //   if (productIsSold) {
  //     setTimeout(() => {
  //       navigate(`/my_sold_products/${id}`);  
  //     }, 2000); // 2 seconds delay
  //   }
  // }, [productIsSold]);




  useEffect(() => {
    if (walletColorScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv =  productConditionRef.current;
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setWalletColorScrollTrigger(false);
    }
  }, [walletColorScrollTrigger]);
  
  const productCategoryDivRef = useRef();

  useEffect(() => {
    if (carPlateTransferTimeScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv =  carPlateNumbersRef.current;
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setCarPlateTransferTimeScrollTrigger(false);
    }
  }, [carPlateTransferTimeScrollTrigger]);

  useEffect(() => {
    if (carPlateNumbersScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv = postTypeRef.current;
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setCarPlateNumbersScrollTrigger(false);
    }
  }, [carPlateNumbersScrollTrigger]);
  useEffect(() => {
    if (productToCategoryScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = productCategoryDivRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setProductToCategoryScrollTrigger(false);
    }
  }, [productToCategoryScrollTrigger]);

  useEffect(() => {
    if (carPlateKindScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = carPlateTransferFeeOnRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setCarPlateKindScrollTrigger(false);
    }
  }, [carPlateKindScrollTrigger]);

  const [productCategoryScrollTrigger, setProductCategoryScrollTrigger] = useState(false);

  useEffect(() => {
    if (purseMaterialScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv = purseOutsideColorRef.current;
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setPurseMaterialScrollTrigger(false);
    }
  }, [purseMaterialScrollTrigger]);

  const [purseOutsideColorScrollTrigger, setPurseOutsideColorScrollTrigger] = useState(false);


  useEffect(() => {
    if (carPlateTransferFeeOnScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = carPlateTransferTimeRef.current;
      if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setCarPlateTransferFeeOnScrollTrigger(false);
    }
  }, [carPlateTransferFeeOnScrollTrigger]);

  useEffect(() => {
    if (purseOutsideColorScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = purseInsideColorRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setPurseOutsideColorScrollTrigger(false);
    }
  }, [purseOutsideColorScrollTrigger]);
  
useEffect(() => {
  if (watchNumbersLanguageScrollTrigger) {
    if (
      productCategory === "ساعات" && 
      productCompany !== "" &&
      productCompany !== "Apple" &&
      productCompany !== "Samsung" && 
      productCompany !== "Huawei" && 
      productCompany !== "Xiaomi"  
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchBandMaterialRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchNumbersLanguageScrollTrigger(false);
  }
}, [watchNumbersLanguageScrollTrigger]);

useEffect(() => {
  if (rosaryCountScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = rosarySizeDivRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    // Reset the trigger after scrolling
    setRosaryCountScrollTrigger(false);
  }
}, [rosaryCountScrollTrigger]);

useEffect(() => {
  if (rosarySizeScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = productConditionRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    // Reset the trigger after scrolling
    setRosarySizeScrollTrigger(false);
  }
}, [rosarySizeScrollTrigger]);
useEffect(() => {
  if (rosaryColorScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = rosaryCountDivRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    // Reset the trigger after scrolling
    setRosaryColorScrollTrigger(false);
  }
}, [rosaryColorScrollTrigger]);
useEffect(() => {
  if (rosaryKindScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = rosaryColorDivRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    // Reset the trigger after scrolling
    setRosaryKindScrollTrigger(false);
  }
}, [rosaryKindScrollTrigger]);


const [successMessage, setSuccessMessage] = useState('');
const [showSuccessAnim, setShowSuccessAnim] = useState(false);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'instant' // Scroll behavior set to instant for instant scrolling
  });
};


useEffect(() => {
  if (watchYearMadeScrollTrigger) {
    if (
      productCategory === "ساعات" 
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = productConditionRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchYearMadeScrollTrigger(false);
  }
}, [watchYearMadeScrollTrigger]);



useEffect(() => {
  if (watchBandMaterialScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchHasOriginalPackagingRef.current;
      const watchBandColorDiv = watchBandColorRef.current;

      if (watchBandMaterial === "ستانلس ستيل") {
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      } else {
        if (watchBandColorDiv) {
          const topOffset = watchBandColorDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
    }
    // Reset the trigger after scrolling
    setWatchBandMaterialScrollTrigger(false);
  }
}, [watchBandMaterialScrollTrigger]);



useEffect(() => {
  if (watchBandColorScrollTrigger) {
    if (
      productCategory === "ساعات" 
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchHasOriginalPackagingRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchBandColorScrollTrigger(false);
  }
}, [watchBandColorScrollTrigger]);


useEffect(() => {
  if (watchHasOriginalPackagingScrollTrigger) {
    if (productCategory === "ساعات") {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchIsWaterResistantRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchHasOriginalPackagingScrollTrigger(false);
  }
}, [watchHasOriginalPackagingScrollTrigger]);



useEffect(() => {
  if (watchIsWaterResistantScrollTrigger) {
    if (productCategory === "ساعات") {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchYearMadeRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchIsWaterResistantScrollTrigger(false);
  }
}, [watchIsWaterResistantScrollTrigger]);

const [productExpiryDateScrollTrigger, setProductExpiryDateScrollTrigger] = useState(false);

const [productImagesScrollTrigger, setProductImagesScrollTrigger] = useState(false);


useEffect(() => {
  if (productImagesScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = productInfoRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    // Reset the trigger after scrolling
    setProductImagesScrollTrigger(false);
  }
}, [productImagesScrollTrigger]);

useEffect(() => {
  if (productExpiryDateScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = productPricingRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    // Reset the trigger after scrolling
    setProductExpiryDateScrollTrigger(false);
  }
}, [productExpiryDateScrollTrigger]);
  
  useEffect(() => {
    if (productCategoryScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      if(productCategory === "ساعات"){
        const watchDetailsDiv = document.getElementById('watch-department-div');
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if(productCategory === "سبح"){
        const watchDetailsDiv = document.getElementById('watch-department-div');
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if(productCategory === "اقلام"){
        const watchDetailsDiv = document.getElementById('watch-department-div');
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if(productCategory === "ابواك"){
        const watchDetailsDiv = document.getElementById('watch-department-div');
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if(productCategory === "لوح سيارات"){
        const watchDetailsDiv = carPlateKindRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if(productCategory === "حقائب نسائية"){
        const watchDetailsDiv = purseCompanyRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if(productCategory === "ارقام جوالات"){
        const watchDetailsDiv = phoneNumberCourierDivRef.current;
        if (watchDetailsDiv) {
          const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      setProductCategoryScrollTrigger(false);
    }
  }, [productCategoryScrollTrigger]);

  useEffect(() => {
    if (walletDepartmentScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv =  walletCompanyRef.current;
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setWalletDepartmentScrollTrigger(false);
    }
  }, [walletDepartmentScrollTrigger]);
  
  useEffect(() => {
    if (purseInsideScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = productConditionRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setPurseInsideColorScrollTrigger(false);
    }
  }, [purseInsideScrollTrigger]);

  const [postTypeScrollTrigger, setPostTypeScrollTrigger] = useState(false);
  
  useEffect(() => {
    if (postTypeScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = productExpiryDateRef.current;
        const productPricingDiv = productPricingRef.current;
        if(postType.includes("مزاد")){
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
        else{
          if (productPricingDiv) {
            const topOffset = productPricingDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
      // Reset the trigger after scrolling
      setPostTypeScrollTrigger(false);
    }
  }, [postTypeScrollTrigger]);


  useEffect(() => {
      
    if (productCompanyScrollTrigger) {
      if (
        productCategory === "ساعات"
      ) {
        if(productCompany !== "Apple" &&
        productCompany !== "Samsung" && 
        productCompany !== "Huawei" && 
        productCompany !== "Xiaomi"){

          const navbarHeight = 65; // Height of the navbar
          const watchDepartmentDiv = watchInsideColorRef.current;
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }
      }
      else if (
        productCategory === "اقلام"
      ) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = productConditionRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if (
        productCategory === "ابواك"
      ) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = walletColorRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      else if (
        productCategory === "حقائب نسائية"
      ) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = purseMaterialRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      // Reset the trigger after scrolling
      setProductCompanyScrollTrigger(false);
    }
  }, [productCompanyScrollTrigger]);

  
  useEffect(() => {
    if (watchInsideColorScrollTrigger) {
      if (
        productCategory === "ساعات" && 
        productCompany !== "" &&
        productCompany !== "Apple" &&
        productCompany !== "Samsung" && 
        productCompany !== "Huawei" && 
        productCompany !== "Xiaomi" && 
        watchDepartment !== "" &&
        watchInsideColor !== ""
      ) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = watchOutsideColorRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      // Reset the trigger after scrolling
      setWatchInsideColorScrollTrigger(false);
    }
  }, [watchInsideColorScrollTrigger]);

  
  useEffect(() => {
    if (watchOutsideColorScrollTrigger) {
      if (
        productCategory === "ساعات" && 
        productCompany !== "" &&
        productCompany !== "Apple" &&
        productCompany !== "Samsung" && 
        productCompany !== "Huawei" && 
        productCompany !== "Xiaomi" && 
        watchDepartment !== "" &&
        watchInsideColor !== "" &&
        watchOutsideColor !== ""
      ) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = watchDialShapeRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      }
      // Reset the trigger after scrolling
      setWatchOutsideColorScrollTrigger(false);
    }
  }, [watchOutsideColorScrollTrigger]);

  
useEffect(() => {
  if (watchDialShapeScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchDialSizeRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchDialShapeScrollTrigger(false);
  }
}, [watchDialShapeScrollTrigger]);


useEffect(() => {
  if (watchDialSizeScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchHandlesColorRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchDialSizeScrollTrigger(false);
  }
}, [watchDialSizeScrollTrigger]);


useEffect(() => {
  if (watchHandlesColorScrollTrigger) {
    if (
      productCategory === "ساعات"
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchNumbersColorRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchHandlesColorScrollTrigger(false);
  }
}, [watchHandlesColorScrollTrigger]);


useEffect(() => {
  if (watchNumbersColorScrollTrigger) {
    if (
      productCategory === "ساعات" && 
      productCompany !== "" &&
      productCompany !== "Apple" &&
      productCompany !== "Samsung" && 
      productCompany !== "Huawei" && 
      productCompany !== "Xiaomi"  
    ) {
      const navbarHeight = 65; // Height of the navbar
      const watchDepartmentDiv = watchNumbersLanguageRef.current;
      if (watchDepartmentDiv) {
        const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
    }
    // Reset the trigger after scrolling
    setWatchNumbersColorScrollTrigger(false);
  }
}, [watchNumbersColorScrollTrigger]);


  useEffect(() => {
    if (watchDepartmentScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv = document.getElementById('watch-details-div');
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setWatchDepartmentScrollTrigger(false);
    }
  }, [watchDepartmentScrollTrigger]);
  
  const handleSetCarPlatePostImage = (image) => {
    setPostImages(image);
  };
  const handleSetPhoneNumberPostImage = (image) => {
    setPostImages(image);
  };
  const handleSetPhoneNumber = (n) => {
    setPhoneNumberToSell(n);
  };
  const handleSetCarPlateFirstArabicLetter = (letter) => {
    setCarPlateFirstArabicLetter(letter);
  };
  const handleSetCarPlateFirstEnglishLetter = (letter) => {
    setCarPlateFirstEnglishLetter(letter);
  };
  const handleSetCarPlateSecondArabicLetter = (letter) => {
    setCarPlateSecondArabicLetter(letter);
  };
  const handleSetCarPlateSecondEnglishLetter = (letter) => {
    setCarPlateSecondEnglishLetter(letter);
  };
  const handleSetCarPlateThirdArabicLetter = (letter) => {
    setCarPlateThirdArabicLetter(letter);
  };
  const handleSetCarPlateThirdEnglishLetter = (letter) => {
    setCarPlateThirdEnglishLetter(letter);
  };
  const handleSetCarPlateFirstArabicNumber = (number) => {
    setCarPlateFirstArabicNumber(number);
  };
  const handleSetCarPlateFirstEnglishNumber = (number) => {
    setCarPlateFirstEnglishNumber(number);
  };
  const handleSetCarPlateSecondArabicNumber = (number) => {
    setCarPlateSecondArabicNumber(number);
  };
  const handleSetCarPlateSecondEnglishNumber = (number) => {
    setCarPlateSecondEnglishNumber(number);
  };
  const handleSetCarPlateThirdArabicNumber = (number) => {
    setCarPlateThirdArabicNumber(number);
  };
  const handleSetCarPlateThirdEnglishNumber = (number) => {
    setCarPlateThirdEnglishNumber(number);
  };
  const handleSetCarPlateFourthArabicNumber = (number) => {
    setCarPlateFourthArabicNumber(number);
  };
  const handleSetCarPlateFourthEnglishNumber = (number) => {
    setCarPlateFourthEnglishNumber(number);
  };
const handleSelectCategory = (category) => {
  if(category !== productCategory){
    setWatchDepartment("")
    setProductCompany("")
    setWatchInsideColor("")
    setWatchOutsideColor("")
    setWatchDialShape("")
    setWatchDialSize("")
    setWatchHandlesColor("")
    setWatchNumbersColor("")
    setWatchNumbersLanguage("")
    setWatchBandMaterial("")
    setWatchBandColor("")
    setWatchHasOriginalPackaging("")
    setWatchIsWaterResistant("")
    setWatchYearMade("")

    setRosaryKind("")
    setRosaryColor("")
    setRosaryCount("")
    setRosarySize("")

    setWalletDepartment("")
    setWalletColor("")

    setCarPlateKind("")
    setCarPlateTransferFeeOn("")
    setCarPlateTransferTime("")

    setCarPlateFirstArabicLetter("")
    setCarPlateSecondArabicLetter("")
    setCarPlateThirdArabicLetter("")

    setCarPlateFirstArabicNumber("")
    setCarPlateSecondArabicNumber("")
    setCarPlateThirdArabicNumber("")
    setCarPlateFourthArabicNumber("")

    setCarPlateFirstEnglishLetter("")
    setCarPlateSecondEnglishLetter("")
    setCarPlateThirdEnglishLetter("")

    setCarPlateFirstEnglishNumber("")
    setCarPlateSecondEnglishNumber("")
    setCarPlateThirdEnglishNumber("")
    setCarPlateFourthEnglishNumber("")


    // continue after finishing categoreies
  }
    setProductCategory(category);
    setProductCategoryScrollTrigger(true);
};

  const handleSelectWatchProductCompany = (company) => {
    setProductCompany(company); 
    if(company === "Apple" || company === "Samsung" || company === "Huawei" || company === "Xiaomi")
    setWatchDepartment("")
    setProductCompanyScrollTrigger(true)
  };
  const handleSelectPenProductCompany = (company) => {
    setProductCompany(company); 
    setProductCompanyScrollTrigger(true)
  };
  const handleSelectPhoneCourier = (company) => {
    setPhoneNumberCourier(company); 
    setPhoneCourierScrollTrigger(true)
  };
  const handleSelectPurseMaterial = (company) => {
    setPurseMaterial(company); 
    setPurseMaterialScrollTrigger(true)
  };
  const handleSelectWalletDepartment = (company) => {
    setWalletDepartment(company);
    setWalletDepartmentScrollTrigger(true)
  };
  const handleSelectRosaryKind = (company) => {
    setRosaryKind(company);
    setRosaryKindScrollTrigger(true)
  };
  const handleSelectWatchInsideColor = (company) => {
    setWatchInsideColor(company);
    setWatchInsideColorScrollTrigger(true);
  };
  const handleSelectWalletColor = (company) => {
    setWalletColor(company);
    setWalletColorScrollTrigger(true);
  };
  const handleSelectCarPlateKind = (company) => {
    setCarPlateKind(company);
    setCarPlateKindScrollTrigger(true);
  };
  const handleSelectCarPlateTransferFeeOn = (company) => {
    setCarPlateTransferFeeOn(company);
    setCarPlateTransferFeeOnScrollTrigger(true);
  };
  const handleSelectCarPlateTransferTime = (company) => {
    setCarPlateTransferTime(company);
    setCarPlateTransferTimeScrollTrigger(true);
  };
  const handleSelectWatchOutsideColor = (company) => {
    setWatchOutsideColor(company);
    setWatchOutsideColorScrollTrigger(true);
  };
  const handleSelectPurseOutsideColor = (company) => {
    setPurseOutsideColor(company);
    setPurseOutsideColorScrollTrigger(true);
  };
  const handleSelectPurseInsideColor = (company) => {
    setPurseInsideColor(company);
    setPurseInsideColorScrollTrigger(true);
  };
  const handleSelectWatchDialShape = (company) => {
    setWatchDialShape(company);
    setWatchDialShapeScrollTrigger(true);
  
  };
  const handleSelectWatchDialSize = (company) => {
    setWatchDialSize(company);
    setWatchDialSizeScrollTrigger(true);
  
  };
  const handleSelectWatchHandlesColor = (company) => {
    setWatchHandlesColor(company);
    setWatchHandlesColorScrollTrigger(true);
    
  };
  
  const handleSelectRosaryColor = (company) => {
    setRosaryColor(company);
    setRosaryColorScrollTrigger(true);
    
  };
  const handleSelectWatchNumbersLanguage = (company) => {
    setWatchNumbersLanguage(company);
    setWatchNumbersLanguageScrollTrigger(true);
    
  };
  const handleSelectWatchNumbersColor = (company) => {
    setWatchNumbersColor(company);
    setWatchNumbersColorScrollTrigger(true);
    
  };
  const handleSelectWatchBandMaterial = (company) => {
    setWatchBandMaterial(company);
    setWatchBandMaterialScrollTrigger(true);
  };
  const handleSelectWatchBandColor = (company) => {
    setWatchBandColor(company);
    setWatchBandColorScrollTrigger(true);
    
  };
  const handleSelectWatchHasOriginalPackaging = (company) => {
    setWatchHasOriginalPackaging(company);
    setWatchHasOriginalPackagingScrollTrigger(true);
  
  };
  const handleSelectWatchIsWaterResistant = (company) => {
    setWatchIsWaterResistant(company);
    setWatchIsWaterResistantScrollTrigger(true);
    
  };
  const handleSelectWatchYearMade = (company) => {
    setWatchYearMade(company);
    setWatchYearMadeScrollTrigger(true)
  };
  
  // const handleSelectIsAuction = (company) => {
  //   setIsAuction(company);
  //   setIsAuctionScrollTrigger(true)
  // };
  
  const handleSelectProductCondition = (company) => {
    setProductCondition(company);
    setProductConditionScrollTrigger(true)
  };

const [rosaryKind, setRosaryKind] = useState("")
const [rosaryColor, setRosaryColor] = useState("")
const [rosaryCount, setRosaryCount] = useState("")
const [rosarySize, setRosarySize] = useState(0)
const [walletColor, setWalletColor] = useState("")


  const {id} = useParams();

    const entryFeeRef = useRef();

  const productNameRef = useRef();
  const productDescriptionRef = useRef();
  const productPriceRef = useRef();
  const productWeightRef = useRef();
  const startingPriceRef = useRef();
  const bidIncrementsRef = useRef();
  const productConditionRef = useRef();
  const isValidDate = (current) => {
    return current.isSameOrAfter(today, 'day') && current.isSameOrBefore(sixDaysAhead, 'day');
  };

  const [productInfoScrollTrigger, setProductInfoScrollTrigger] = useState(false);

  
  useEffect(() => {
    if (productInfoScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = shippingRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setProductInfoScrollTrigger(false);
    }
  }, [productInfoScrollTrigger]);

  useEffect(() => {
    if (phoneNumberToSellScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv = postTypeRef.current
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setPhoneNumberToSellScrollTrigger(false);
    }
  }, [phoneNumberToSellScrollTrigger]);

  useEffect(() => {
    if (phoneCourierScrollTrigger) {
      const navbarHeight = 65; // Height of the navbar
      const watchDetailsDiv = phoneNumberToSellDivRef.current
      if (watchDetailsDiv) {
        const topOffset = watchDetailsDiv.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: topOffset - navbarHeight,
          behavior: 'smooth'
        });
      }
      // Reset the trigger after scrolling
      setPhoneCourierScrollTrigger(false);
    }
  }, [phoneCourierScrollTrigger]);

  useEffect(() => {
    if (productConditionScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = postTypeRef.current;
        if (watchDepartmentDiv) {
          const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
          window.scrollTo({
            top: topOffset - navbarHeight,
            behavior: 'smooth'
          });
        }
      // Reset the trigger after scrolling
      setProductConditionScrollTrigger(false);
    }
  }, [productConditionScrollTrigger]);

  const shippingRef = useRef();
  const handleSelectShippingMethods = (categories) => {
    setShippingMethods(categories);
    
    // Check if "شحن" is included in categories
    if (!categories.includes("شحن")) {
      // Set productWeight to an empty string
      setProductWeight("");
    }
  };


  function calculateShippingCost(weight) {
    const basePrice = 23; // تكلفة الشحن لأول 15 كيلوجرام
    const additionalPricePerKilo = 2; // تكلفة الشحن لكل كيلوجرام إضافي
    const baseWeight = 15; // الوزن الأساسي

    if (weight <= baseWeight) {
        return basePrice;
    } else {
        const additionalWeight = weight - baseWeight;
        const additionalCost = additionalWeight * additionalPricePerKilo;
        return basePrice + additionalCost;
    }
}
  const [shippingMethods, setShippingMethods] = useState([])

  const today = moment();
  const sixDaysAhead = moment().add(6, 'days');
  const handleSelectPostType = (categories) => {
    // Check if "عروض" should be removed when "شراء فوري" is not included
    if (!categories.includes("شراء فوري") && categories.includes("عروض")) {
      // Filter out "عروض" since "شراء فوري" is not selected
      categories = categories.filter(category => category !== "عروض");
    }
    
    if (!categories.includes("مزاد")) {
      setProductExpiryDate("")
      setEntryFee("")
      setStartingPrice("")
      setBidIncrements("")
    }
  
    // Update the state with the new categories
    setPostType(categories);
  };
  const productImagesRef = useRef();
  const postTypeRef = useRef();
  const customInputProps = {
    isValidDate: isValidDate,
    closeOnClickOutside: true,
    initialValue: today, // Set the initial value to today's date
    inputProps: {
      readOnly: true, // Disable keyboard input
      style: { outline: 'none', boxShadow: 'none', color: '#00C8B8', direction: 'rtl' } // Remove focus styles, set text color, and RTL
    },
  };
  const productPricingRef = useRef();
  const productInfoRef = useRef();


  const isAuctionRef = useRef();
  const productCategoryRef = useRef();
  const watchDepartmentRef = useRef();
  const productCompanyRef = useRef();
  const watchInsideColorRef = useRef();
  const watchInsideColorSpecificationRef = useRef();
  const watchOutsideColorRef = useRef();
  const [watchOutsideColorHasError, setWatchOutsideColorHasError] = useState(false);
  const [watchOutsideColorSpecificationHasError, setWatchOutsideColorSpecificationHasError] = useState(false);
  const watchOutsideColorSpecificationRef = useRef();
  const carPlateTransferFeeOnRef = useRef();
  
  const [watchInsideColorHasError, setWatchInsideColorHasError] = useState(false);
  const [watchInsideColorSpecificationHasError, setWatchInsideColorSpecificationHasError] = useState(false);

  const [productImagesHasError, setProductImageHasError] = useState(false);
  const [productNameHasError, setProductNameHasError] = useState(false);
  const [productDescriptionHasError, setProductDescriptionHasError] = useState(false);
  const [productPriceHasError, setProductPriceHasError] = useState(false);
  const [productWeightHasError, setProductWeightHasError] = useState(false);
  const [productExpiryDateHasError, setProductExpiryDateHasError] = useState(false);
  const [productStartingPriceHasError, setProductStartingPriceHasError] = useState(false);
  const [productConditionHasError, setProductConditionHasError] = useState(false);
  const [productCategoryHasError, setProductCategoryHasError] = useState(false);
  const [bidIncrementHasError, setBidIncrementHasError] = useState(false);
  const [startingPrice, setStartingPrice] = useState(10);
  const [newStartingPrice, setNewStartingPrice] = useState(10);
  const [bidIncrements, setBidIncrements] = useState(10);
  const [newBidIncrements, setNewBidIncrements] = useState(10);
  const [searchParams] = useSearchParams();
  const [numBidders, setNumBidders] = useState(0)
  const [productWeight, setProductWeight] = useState(0.5)

  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  
  const [imageUrls, setImageUrls] = useState([]);


  const [productPricingScrollTrigger, setProductPricingScrollTrigger] = useState(false);

  useEffect(() => {
    if (productPricingScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = productQtyDivRef.current;
        const productInfoDiv = productInfoRef.current;
        const productImagesDiv = productImagesRef.current;

        if(productCategory === "لوح سيارات" || productCategory === "ارقام جوالات"){
          if (productInfoDiv) {
            const topOffset = productInfoDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        } else{
          if(isBusiness && !postType.includes("مزاد")){
            if (watchDepartmentDiv) {
              const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          } else{
            if (productImagesDiv) {
              const topOffset = productImagesDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
              window.scrollTo({
                top: topOffset - navbarHeight,
                behavior: 'smooth'
              });
            }
          }
        }

      // Reset the trigger after scrolling
      setProductPricingScrollTrigger(false);
    }
  }, [productPricingScrollTrigger]);

  const [productQtyScrollTrigger, setProductQtyScrollTrigger] = useState(false);
  const productQtyDivRef = useRef(null);

  useEffect(() => {
    if (productQtyScrollTrigger) {
        const navbarHeight = 65; // Height of the navbar
        const watchDepartmentDiv = productImagesRef.current;
        const productInfoDiv = productInfoRef.current;

        if(productCategory === "لوح سيارات" || productCategory === "ارقام جوالات"){
          if (productInfoDiv) {
            const topOffset = productInfoDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        } else{
          if (watchDepartmentDiv) {
            const topOffset = watchDepartmentDiv.getBoundingClientRect().top + window.scrollY; // Calculate relative to viewport with scroll position
            window.scrollTo({
              top: topOffset - navbarHeight,
              behavior: 'smooth'
            });
          }
        }

      // Reset the trigger after scrolling
      setProductQtyScrollTrigger(false);
    }
  }, [productQtyScrollTrigger]);


  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [])

  const [notMyProduct, setNotMyProduct] = useState(false);



  useEffect(() => {
    if (myId) {
      const fetchData = async () => {
        try {
          // Reference the product document directly by ID
          const docRef = doc(db, 'Products', id);

          const unsubscribe = onSnapshot(docRef, async (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              
              const sellerId = data['sellerId'];

              if (sellerId !== myId) {
                setNotMyProduct(true);
                setLoading(false);
                return;
              }
                // General product fields
                setPostId(data?.['id']);
                setProductName(data?.['productName']);
                setProductDescription(data?.['productDescription']);
                setIsAuction(data?.['isAuction']);
                setNewIsAuction(data?.['isAuction']);
                setProductCondition(data?.['condition']);
                setNewProductCondition(data?.['condition']);
                setProductCompany(data?.['productCompany']);
                setProductPrice(data?.['productPrice']);
                setProductIsSold(data?.['isSold']);
                setIsDeleted(data?.['isDeleted']);
                setBidders(data?.['bidders']);
                setProductQty(data?.['productQty']);
                setNewProductQty(data?.['productQty']);
                setNumBidders(data?.['numBidders']);
                setShippingMethods(data?.['shippingMethods']);
                setEntryFee(data?.['entryFee']);
                setNewEntryFee(data?.['entryFee']);
                setBidIncrements(data?.['bidIncrements']);
                setPostType(data?.['postType']);
                setStartingPrice(data?.['startingPrice']);
                setNewStartingPrice(data?.['startingPrice']);
                setCurrentBid(data?.['currentBid']);
                setProductWeight(data?.['productWeight']);
                setProductExpiryDate(data?.['expiryDate']);
                setProductNewExpiryDate(data?.['expiryDate']);
                setProductCategory(data?.['category']);
                setNewProductCategory(data?.['category']);
                

                // Wallet-specific fields
                setWalletDepartment(data?.['wallet']?.['department']);
                setNewWalletDepartment(data?.['wallet']?.['walletDepartment']);
                setWalletColor(data?.['wallet']?.['outsideColor']);

                // Rosary-specific fields
                setRosarySize(data?.['rosary']?.['size']);
                setRosaryColor(data?.['rosary']?.['color']);
                setRosaryKind(data?.['rosary']?.['kind']);
                setRosaryCount(data?.['rosary']?.['count']);
                setRosarySize(data?.['rosary']?.['size']);

                // Car plate-specific fields
                setCarPlateTransferTime(data?.['carPlate']?.['transferTime']);
                setCarPlateNewTransferTime(data?.['carPlate']?.['transferTime']);
                setCarPlateTransferFeeOn(data?.['carPlate']?.['transferFeeOn']);
                setCarPlateNewTransferFeeOn(data?.['carPlate']?.['transferFeeOn']);
                setCarPlateKind(data?.['carPlate']?.['kind']);
                setNewCarPlateKind(data?.['carPlate']?.['kind']);
                setCarPlateFirstArabicLetter(data?.['carPlate']?.['firstArabicLetter']);
                setCarPlateSecondArabicLetter(data?.['carPlate']?.['secondArabicLetter']);
                setCarPlateThirdArabicLetter(data?.['carPlate']?.['thirdArabicLetter']);
                setCarPlateFirstArabicNumber(data?.['carPlate']?.['firstArabicNumber']);
                setCarPlateSecondArabicNumber(data?.['carPlate']?.['secondArabicNumber']);
                setCarPlateThirdArabicNumber(data?.['carPlate']?.['thirdArabicNumber']);
                setCarPlateFourthArabicNumber(data?.['carPlate']?.['fourthArabicNumber']);
                setCarPlateNewFirstArabicLetter(data?.['carPlate']?.['firstArabicLetter']);
                setCarPlateNewSecondArabicLetter(data?.['carPlate']?.['secondArabicLetter']);
                setCarPlateNewThirdArabicLetter(data?.['carPlate']?.['thirdArabicLetter']);
                setCarPlateNewFirstArabicNumber(data?.['carPlate']?.['firstArabicNumber']);
                setCarPlateNewSecondArabicNumber(data?.['carPlate']?.['secondArabicNumber']);
                setCarPlateNewThirdArabicNumber(data?.['carPlate']?.['thirdArabicNumber']);
                setCarPlateNewFourthArabicNumber(data?.['carPlate']?.['fourthArabicNumber']);
                setCarPlateFirstEnglishLetter(data?.['carPlate']?.['firstEnglishLetter']);
                setCarPlateSecondEnglishLetter(data?.['carPlate']?.['secondEnglishLetter']);
                setCarPlateThirdEnglishLetter(data?.['carPlate']?.['thirdEnglishLetter']);
                setCarPlateFirstEnglishNumber(data?.['carPlate']?.['firstEnglishNumber']);
                setCarPlateSecondEnglishNumber(data?.['carPlate']?.['secondEnglishNumber']);
                setCarPlateThirdEnglishNumber(data?.['carPlate']?.['thirdEnglishNumber']);
                setCarPlateFourthEnglishNumber(data?.['carPlate']?.['fourthEnglishNumber']);
                setCarPlateNewFirstEnglishLetter(data?.['carPlate']?.['firstEnglishLetter']);
                setCarPlateNewSecondEnglishLetter(data?.['carPlate']?.['secondEnglishLetter']);
                setCarPlateNewThirdEnglishLetter(data?.['carPlate']?.['thirdEnglishLetter']);
                setCarPlateNewFirstEnglishNumber(data?.['carPlate']?.['firstEnglishNumber']);
                setCarPlateNewSecondEnglishNumber(data?.['carPlate']?.['secondEnglishNumber']);
                setCarPlateNewThirdEnglishNumber(data?.['carPlate']?.['thirdEnglishNumber']);
                setCarPlateNewFourthEnglishNumber(data?.['carPlate']?.['fourthEnglishNumber']);

                // Phone number-specific fields
                setPhoneNumberCourier(data?.['phoneNumber']?.['courier']);
                setPhoneNumberToSell(data?.['phoneNumber']?.['phoneNumber']);
                setNewPhoneNumberToSell(data?.['phoneNumber']?.['phoneNumber']);

                // Watch-specific fields
                setWatchDepartment(data?.['watch']?.['department']);
                setNewWatchDepartment(data?.['watch']?.['department']);
                setWatchInsideColor(data?.['watch']?.['insideColor']);
                setWatchNewInsideColor(data?.['watch']?.['insideColor']);
                setWatchInsideColorSpecification(data?.['watch']?.['insideColorSpecification']);
                setWatchNewInsideColorSpecification(data?.['watch']?.['insideColorSpecification']);
                setWatchOutsideColor(data?.['watch']?.['outsideColor']);
                setWatchNewOutsideColor(data?.['watch']?.['outsideColor']);
                setWatchOutsideColorSpecification(data?.['watch']?.['outsideColorSpecification']);
                setWatchNewOutsideColorSpecification(data?.['watch']?.['outsideColorSpecification']);
                setWatchDialSize(parseFloat(data?.['watch']?.['dialSize']));
                setWatchNewDialSize(data?.['watch']?.['dialSize']);
                setWatchDialShape(data?.['watch']?.['dialShape']);
                setWatchNewDialShape(data?.['watch']?.['dialShape']);
                setWatchHandlesColor(data?.['watch']?.['handlesColor']);
                setWatchHandlesColorSpecification(data?.['watch']?.['handlesColorSpecification']);
                setWatchNewHandlesColor(data?.['watch']?.['handlesColor']);
                setWatchNewHandlesColorSpecification(data?.['watch']?.['handlesColorSpecification']);
                setWatchNumbersLanguage(data?.['watch']?.['numbersLanguage']);
                setWatchNewNumbersLanguage(data?.['watch']?.['numbersLanguage']);
                setWatchNumbersColor(data?.['watch']?.['numbersColor']);
                setWatchNumbersColorSpecification(data?.['watch']?.['numbersColorSpecification']);
                setWatchNewNumbersColor(data?.['watch']?.['numbersColor']);
                setWatchNewNumbersColorSpecification(data?.['watch']?.['numbersColorSpecification']);
                setWatchBandMaterial(data?.['watch']?.['bandMaterial']);
                setWatchNewBandMaterial(data?.['watch']?.['bandMaterial']);
                setWatchBandColor(data?.['watch']?.['bandColor']);
                setWatchNewBandColor(data?.['watch']?.['bandColor']);
                setWatchBandColorSpecification(data?.['watch']?.['bandColorSpecification']);
                setWatchNewBandColorSpecification(data?.['watch']?.['bandColorSpecification']);
                setWatchHasOriginalPackaging(data?.['watch']?.['hasOriginalPackaging']);
                setWatchNewHasOriginalPackaging(data?.['watch']?.['hasOriginalPackaging']);
                setWatchYearMade(data?.['watch']?.['yearMade']);
                setWatchNewYearMade(data?.['watch']?.['yearMade']);
                setWatchIsWaterResistant(data?.['watch']?.['isWaterResistant']);
                setWatchNewIsWaterResistant(data?.['watch']?.['isWaterResistant']);

                // Purse-specific fields
                setPurseInsideColor(data?.['purse']?.['insideColor']);
                setPurseNewInsideColor(data?.['purse']?.['insideColor']);
                setPurseMaterial(data?.['purse']?.['material']);
                setPurseInsideColorSpecification(data?.['purse']?.['insideSpecificationColor']);
                setPurseNewInsideColorSpecification(data?.['purse']?.['insideSpecificationColor']);
                setPurseOutsideColor(data?.['purse']?.['outsideColor']);
                setPurseNewOutsideColor(data?.['purse']?.['outsideColor']);
                setPurseOutsideColorSpecification(data?.['purse']?.['outsideSpecificationColor']);
                setPurseNewOutsideColorSpecification(data?.['purse']?.['outsideSpecificationColor']);

              // Fetch images and convert them to base64
              const base64Promises = data['imgs'].map(async (url) => {
                try {
                  const response = await fetch(url);
                  if (!response.ok) {
                    throw new Error(`Failed to fetch image: ${response.status} - ${response.statusText}`);
                  }
                  const blob = await response.blob();
                  const base64 = await fileToBase64(blob);
                  return { src: base64, isCropped: false };
                } catch (error) {
                  console.error('Error fetching image:', error);
                  return null;
                }
              });

              const base64Images = await Promise.all(base64Promises);
              setPostImages(base64Images.filter(img => img !== null)); // Remove any null entries

              // Update loading state and other necessary state variables
              setLoading(false);
            } else {
              setDoesntExist(true)
              console.log("No product data found");
              setLoading(false);
            }
          });

          return () => {
            unsubscribe(); // Cleanup on component unmount
          };
        } catch (error) {
          console.error("Error fetching product data:", error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id, myId]);

  



const deletePost = async () => {
  const confirmDelete = window.confirm("حذف المنتج؟");

  if (confirmDelete) {
    try {
      setDeleteLoading(true);

      const functions = getFunctions();
      const markProductAsDeleted = httpsCallable(functions, 'deleteProduct');


      const response = await markProductAsDeleted({ id });

      if (response.data.success) {
        setSuccessMessage("!تم حذف المنتج بنجاح");
        setShowSuccessAnim(true)
        setTimeout(() => {
          handleNavigateBack();
        }, 2000); // 1000 milliseconds = 3 seconds
      } else {
        console.error('Failed to delete product:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    } finally {
    }
  } else {
    console.log('Deletion cancelled.');
  }
  setDeleteLoading(false);
};

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}



// const uploadImagesToStorageAndFirestore = async (imagesArray, id) => {
//   // const urls = {};
//   // const uploadPromises = imagesArray.map(async (image, index) => {
//   //   const storageRef = ref(storage, `Posts/post${id}/${index + 1}`);
//   //   await uploadBytes(storageRef, image);
//   //   // const downloadURL = await getDownloadURL(storageRef);
//   //   // Object.assign(urls, {
//   //   //   [`${index}`]: downloadURL
//   //   // })

//   //   // return urls;
//   // });

//   const folderRef = ref(storage, `Posts/${postId}`); // Replace 'images' with your folder name

//   // Delete existing files in the folder
//   const existingFiles = await listAll(folderRef);
//   await Promise.all(existingFiles.items.map((fileRef) => deleteObject(fileRef)));


//   // const uploadPromises = imagesArray.map(async (image, index) => {
//   //   const storageRef = ref(storage, folderRef);
//   //   await uploadBytes(storageRef, image);
//   //   // const downloadURL = await getDownloadURL(storageRef);
//   //   // Object.assign(urls, {
//   //   //   [`${index}`]: downloadURL
//   //   // })

//   //   // return urls;
//   // });

//           const urls = {};
//           const uploadPromises = imagesArray.map(async (image, index) => {
//             try {
//               const storageRef = ref(storage, `Posts/${postId}/${index + 1}`);
//               await uploadBytes(storageRef, image);
//               const downloadURL = await getDownloadURL(storageRef);
//               urls[index] = downloadURL;
//             } catch (error) {
//               console.error(`Error uploading image ${index + 1}:`, error);
//               throw error;
//             }
//           });



//   try {
//     await Promise.all(uploadPromises);
//     // const urls = Object.assign({}, ...urlsArray);
//     // return urls;
//   } catch (error) {
//     console.error('Error uploading images:', error);
//     throw error;
//   }
  
// }

if(doesntExist){
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: notFound,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{
      direction: "rtl",
      justifyContent: "center",
      alignContent: "center",
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    }}>
      <Lottie options={defaultOptions}
              height={250}
              width={250}
              style={{ margin: "8px auto" }} />
      <h5 style={{ color: "#01635c", fontSize: "17px", textAlign: "center" }}>
        لا يوجد منتج بهذا الرقم
      </h5>
    </div>
  );
}

if(isLoading){
  return <LoadingScreen/>
}

if (notMyProduct) {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: notFound,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{
      direction: "rtl",
      justifyContent: "center",
      alignContent: "center",
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    }}>
      <Lottie options={defaultOptions}
              height={250}
              width={250}
              style={{ margin: "8px auto" }} />
      <h5 style={{ color: "#01635c", fontSize: "17px", textAlign: "center" }}>
        رقم المنتج غير صحيح
      </h5>
    </div>
  );
}

const handleNavigateBack = () => {
  navigate(-1, { replace: true }); // This replaces the current entry in the history stack
};

// Call this function when you want to navigate back without forward navigation.



    // if(isAuction && hasExpired ){
    //   return <h1 style={{marginTop:"60px", marginBottom: "100px"}}>this post has expired</h1>
    // }


    const handleImagesChange = (newImages) => {
      setProductImageHasError(false);
      setPostImages(newImages);
    };

    const uploadImages = async (images, postId) => {
      try {
        const imageURLs = [];
        for (let index = 0; index < images.length; index++) {
          const image = images[index];
    
          if (!image || !image.src || !image.src.trim()) {
            console.error(`Image data is missing or empty for image at index ${index}`);
            continue; // Skip to the next image if data is missing
          }
    
          const base64Data = image.src.split(',')[1]; // Get the base64 data
          const blob = b64toBlob(base64Data, 'image/jpeg'); // Convert base64 to Blob
    
          const imagePath = `Posts/post${postId}/${index}`;
          const storageRef = ref(storage, imagePath);
    
          // Delete existing image before upload
          try {
            await deleteObject(storageRef);
          } catch (error) {
            // Handle potential error if image doesn't exist
            console.warn(`Error deleting existing image: ${imagePath}`);
          }
    
          try {
            await uploadBytes(storageRef, blob); // Upload the Blob to Firebase Storage
            const downloadURL = await getDownloadURL(storageRef); // Get the download URL
            imageURLs.push(downloadURL);
            console.log(`Image ${index + 1} uploaded successfully: ${downloadURL}`); // Log URL after upload
          } catch (error) {
            console.error(`Error uploading image ${index + 1}:`, error);
            // Continue uploading remaining images despite an error (optional)
          }
        }
    
        return imageURLs; // Return array of download URLs
      } catch (error) {
        console.error('Error uploading images to storage:', error);
        return [];
      }
    };
    
    

    
// Helper function to convert base64 to Blob
const b64toBlob = (b64Data, contentType = 'image/jpeg', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};


const updateProduct = async () => {

  if(
    // main fields
    (postImages.length < 3 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") ||
    productName.length < 1 ||
    // productDescription.length < 50 ||
    postType.length === 0 ||
    (postType.includes("شراء فوري") && productPrice === "") ||
    productQty === "" ||
    productQty <= 0 ||
    (shippingMethods.length === 0 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") || 
    (shippingMethods.includes("شحن") && (productWeight === "" || isNaN(productWeight))) ||
    (postType.includes("مزاد") && productExpiryDate === "") ||
    (postType.includes("مزاد") && (postType.includes("شراء فوري") && (parseFloat(startingPrice) >= parseFloat(productPrice)))) ||
    (postType.includes("مزاد") && (bidIncrements < 10 || isNaN(bidIncrements) || bidIncrements === "")) ||
    (postType.includes("مزاد") && (startingPrice < 10 || isNaN(startingPrice) || startingPrice === "")) ||
    (productCondition === "" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") ||
    productCategory === "" ||

    // specific fields
    (productCategory === "لوح سيارات" &&( 
    carPlateFirstArabicLetter === "" || carPlateFirstArabicNumber === "" ||
    carPlateTransferTime === "" ||
    carPlateKind === "" ||
    carPlateTransferFeeOn === ""
    )) ||


    (productCategory === "ساعات" &&(
    watchDepartment === "" ||
    productCompany === "" ||
    watchInsideColor === "" ||
    watchOutsideColor === "" 
    )) ||


    (productCategory === "سبح" &&(
    rosaryKind === "" ||
    rosaryColor === "" ||
    rosaryCount === ""
    )) ||


    (productCategory === "اقلام" &&(
    productCompany === ""
    )) ||


    (productCategory === "ابواك" &&(
    walletDepartment === "" ||
    productCompany === ""
    )) ||



    (productCategory === "ارقام جوالات" && (
    phoneNumberCourier === "" || phoneNumberToSell === "" ||
    phoneNumberToSell.length < 10
    )) ||

    (productCategory === "حقائب نسائية" && (
    productCompany === "" || purseOutsideColor === ""
    // (purseOutsideColor === "آخر" && purseOutsideColorSpecification === "") || 
    // purseInsideColor === "" ||
    // (purseInsideColor === "آخر" && purseInsideColorSpecification === "")
    ))

     ){

      // main fields
      if(productCategory === "") {setProductCategoryHasError(true); console.log("setProductCategoryHasError"); alert("يجب ادخال تصنيف المنتج"); setProductToCategoryScrollTrigger(true); return;}

      // if(productDescription.length < 50) {setProductDescriptionHasError(true); console.log("setProductDescriptionHasError")} 
      if(isBusiness && productQty === "") {setProductQtyHasError(true); console.log("setProductQtyHasError"); setProductPricingScrollTrigger(); alert("يجب ادخال الكمية"); return;}
      
      if(isBusiness && productQty <= 0) {setProductQtyHasError(true); console.log("setProductQtyHasError"); setProductPricingScrollTrigger(true)}
      
      // entry fee
      if(postType.includes("مزاد") && (isNaN(entryFee) || entryFee === "")) {setEntryFeeHasError(true); console.log("setEntryFeeHasError"); alert("يرجى ادخال مبلغ عربون المزاد"); setProductExpiryDateScrollTrigger(true);  return;}
      if(postType.includes("مزاد") && entryFee < 10) {setEntryFeeHasError(true); console.log("setEntryFeeHasError"); alert("اقل مبلغ للعربون هو 10 ريال"); setProductExpiryDateScrollTrigger(true);  return;}
      
      // starting price
      if(postType.includes("مزاد") && (isNaN(startingPrice) || startingPrice === "")) {setProductStartingPriceHasError(true); console.log("setProductStartingPriceHasError"); alert("يرجى ادخال مبلغ افتتاح المزاد"); setProductExpiryDateScrollTrigger(true); return;}
      if(postType.includes("مزاد") && startingPrice < 10) {setProductStartingPriceHasError(true); console.log("setProductStartingPriceHasError"); alert("اقل مبلغ لافتتاح المزاد هو 10 ريال"); setProductExpiryDateScrollTrigger(true); return;}
      
      // bid increments
      if(postType.includes("مزاد") && (isNaN(bidIncrements) || bidIncrements === "")) {setBidIncrementHasError(true); console.log("setBidIncrementHasError"); alert("يرجى ادخال مبلغ مضاعفات المزايدة"); setProductExpiryDateScrollTrigger(true); return;}
      if(postType.includes("مزاد") && bidIncrements < 10) {setBidIncrementHasError(true); console.log("setBidIncrementHasError"); alert("اقل مبلغ لمضاعفات المزايدة هو 10 ريال"); setProductExpiryDateScrollTrigger(true); return;}
      
      if((postType.includes("مزاد") && (postType.includes("شراء فوري") && (parseInt(entryFee) >= parseFloat(productPrice))))){
        setProductPriceHasError(true);
        setEntryFeeHasError(true);
        console.log("setProductPriceHasError")
        console.log("setEntryFeeHasError")
        setProductExpiryDateScrollTrigger(true);
        alert("مبلغ عربون دخول المزاد يجب ان يكون اقل من سعر الشراء الفوري");
        return;
      }
      if((postType.includes("مزاد") && (postType.includes("شراء فوري") && (parseInt(startingPrice) >= parseFloat(productPrice))))){
        setProductPriceHasError(true);
        setProductStartingPriceHasError(true);
        console.log("setProductPriceHasError")
        console.log("setProductStartingPriceHasError")
        alert("مبلغ افتتاح المزاد يجب ان يكون اقل من سعر الشراء الفوري");
        setProductExpiryDateScrollTrigger(true);
        return;
      }
      
      
    
      
      // specific fields
      if(productCategory === "لوح سيارات"){
        if(carPlateKind === "") { setCarPlateKindHasError(true); alert("يجب تحديد نوع اللوحة"); setProductCategoryScrollTrigger(true); return;}
        if(carPlateTransferFeeOn === "") { setCarPlateTransferFeeOnHasError(true) ; alert("يجب تحديد من سيدفع تكلفة نقل ملكية اللوحة"); setCarPlateKindScrollTrigger(true); return;}
        if(carPlateTransferTime === "") { setCarPlateTransferTimeHasError(true); alert("يجب تحديد فترة نقل الملكية"); setCarPlateTransferFeeOnScrollTrigger(true); return;}
        if(carPlateFirstArabicLetter === "")  {setCarPlateFirstArabicLetterHasError(true); alert("يجب ادخال اول حرف من حروف اللوحة"); setCarPlateTransferTimeScrollTrigger(true); return;}
        if(carPlateFirstArabicNumber === "")  {setCarPlateFirstArabicNumberHasError(true); alert("يجب ادخال اول رقم من ارقام اللوحة"); setCarPlateTransferTimeScrollTrigger(true); return;}
      } 
      
      else if(productCategory === "ساعات"){
        if(watchDepartment === "") {setWatchDepartmentHasError(true); console.log("setWatchDepartmentHasError"); alert("يجب تحديد قسم الساعات"); setProductCategoryScrollTrigger(true); return;}
        if(productCompany === "") {setProductCompanyHasError(true); console.log("setProductCompanyHasError"); alert("يجب تحدبد الشركة المصنعة للساعة"); setWatchDepartmentScrollTrigger(true); return;}
        if(watchInsideColor === "") {setWatchInsideColorHasError(true); console.log("setWatchInsideColorHasError"); alert("يجب تحديد لون قرص الساعة الداخلي"); setProductCompanyScrollTrigger(true); return;}
        if(watchOutsideColor === "" ) {setWatchOutsideColorHasError(true); console.log("setWatchOutsideColorHasError"); alert("يجب تحديد لون قرص الساعة الخارجي"); setWatchInsideColorScrollTrigger(true); return}
      }

      else if(productCategory === "سبح"){
        if(rosaryKind === "") {setRosaryKindHasError(true); alert("يجب تحديد نوع الخرز"); setProductCategoryScrollTrigger(true); return;}
        if(rosaryColor === "") {setRosaryColorHasError(true); alert("يجب تحديد لون الخرز"); setRosaryKindScrollTrigger(true); return;}
        if(rosaryCount === "") {setRosaryCountHasError(true); alert("يجب تحديد عدد الخرز"); setRosaryColorScrollTrigger(true); return;}
      }


      else if(productCategory === "اقلام"){
        if(productCompany === "") {setProductCompanyHasError(true); alert("يجب تحديد الشركة المصنعة للقلم"); setProductCategoryScrollTrigger(true); return;}
      }


      else if(productCategory === "ابواك"){
        if(walletDepartment === "") {setWalletDepartmentHasError(true); alert("يجب تحديد قسم الأبواك"); setProductCategoryScrollTrigger(true); return;}
        if(productCompany === "") {setProductCompanyHasError(true); alert("يجب تحديد الشركة المصنعة للبوك"); setWalletDepartmentScrollTrigger(true); return;}
        if(walletColor === "") {alert("يجب تحديد اللون الخارجي للبوك"); setProductCompanyScrollTrigger(true); return;}
      }

      else if(productCategory === "ارقام جوالات"){
        if(phoneNumberCourier === "") {setPhoneNumberCourierHasError(true); alert("يجب تحديد مشغل الخدمة"); setProductCategoryScrollTrigger(true); return;}
        if(phoneNumberToSell === "") {setPhoneNumberToSellHasError(true); console.log("setPhoneNumberToSellHasError"); alert("يجب ادخال رقم الجوال"); setPhoneCourierScrollTrigger(true); return;}
        if(phoneNumberToSell.length < 10) {setPhoneNumberToSellHasError(true); console.log("setPhoneNumberToSellHasError"); alert("يجب ان يتكون رقم الجوال من 10 ارقام و يبدأ بـ05"); setPhoneCourierScrollTrigger(true); return;}
      }

      else if(productCategory === "حقائب نسائية"){

    if(productCompany === "") {setProductCompanyHasError(true); alert("يجب تحديد الشركة المصنعة للحقيبة"); setProductCategoryScrollTrigger(true); return;}
    if(purseMaterial === "") {alert("يجب تحديد المادة المصنوع منها الحقيبة"); setProductCompanyScrollTrigger(true); return;}
    if(purseOutsideColor === "") {setPurseOutsideColorHasError(true); alert("يجب تحديد اللون الخارجي للحقيبة"); setPurseMaterialScrollTrigger(true); return;}
    // if(purseOutsideColor === "آخر" && purseOutsideColorSpecification === "") setPurseOutsideColorSpecificationHasError(true);
    // if(purseInsideColor === "آخر" && purseInsideColorSpecification === "") setPurseInsideColorSpecificationHasError(true);               
    
    }
    if(productCondition === "" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" ) {setProductConditionHasError(true); console.log("setProductConditionHasError"); alert("يجب ادخال حالة المنتج");
    if(productCategory === "ساعات") setWatchYearMadeScrollTrigger(true);
    else if(productCategory === "سبح") setRosarySizeScrollTrigger(true)
      else if(productCategory === "اقلام") setProductCompanyScrollTrigger(true)
    else if(productCategory === "ابواك") setWalletColorScrollTrigger(true)
  else if(productCategory === "حقائب نسائية") setPurseInsideColorScrollTrigger(true)
return;
}
if(postType.length === 0) {alert("يجب تحديد طريقة عرض المنتج"); setProductConditionScrollTrigger(true); return;}
if(postType.includes("شراء فوري") && productPrice === "") {setProductPriceHasError(true); console.log("setProductPriceHasError"); alert("يجب تحديد سعر المنتج"); if(!postType.includes("مزاد")){setPostTypeScrollTrigger(true);} else{setProductExpiryDateScrollTrigger(true)} return;}
if((postImages.length < 3 || postImages.length > 10) && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") {setProductImageHasError(true); console.log("setProductImageHasError"); alert("يرجى اضافة من 3 الى 10 صور"); setProductQtyScrollTrigger(true); return}
if(productName.length < 1) {setProductNameHasError(true); console.log("setProductNameHasError"); alert("يرجى كتابة اسم للمنتج"); setProductImagesScrollTrigger(true); return;}


    
      
    if(shippingMethods.length === 0 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات") {console.log("setProductShippingMethodsHasError"); alert("يرجى تحديد طريقة التوصيل"); setProductInfoScrollTrigger(true); return;}
      
    if(shippingMethods.includes("شحن") && (productWeight === "" || isNaN(productWeight))) {setProductWeightHasError(true); console.log("setProductWeightHasError"); alert("يرجى ادخال وزن المنتج"); setProductInfoScrollTrigger(true); return;}

      return;
  }

  setUpdateLoading(true); 

const keywords = [];
const productCon = productCondition.trim();

if(productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات"){
  keywords.push(productCon)
}

// only if applicable
const companyStr = productCompany.trim().split(" ");
const productNameStr = productName.trim().split(" ");
const descriptionStr = productDescription.trim().split(/\s+/);
//#region 
// const subCategoryStr = productSubCategory.trim().split(" ");

for(var i = 0 ; i < productNameStr.length; i ++){
if(!keywords.includes(productNameStr[i])){
keywords.push(productNameStr[i].trim())
}
}


if(productCategory === "ساعات" || productCategory === "اقلام" || productCategory === "ابواك" || productCategory === "حقائب نسائية" ){

for(var i = 0 ; i < companyStr.length; i ++){
if(!keywords.includes(companyStr[i])){
keywords.push(companyStr[i].trim())
}
}
}
// for(var i = 0 ; i < productKindStr.length; i ++){
//   if(!keywords.includes(productKindStr[i])){
//     keywords.push(productKindStr[i].trim())
//   }
// }
//#endregion

if(productDescription.length > 0){
  for(var i = 0 ; i < descriptionStr.length; i ++){
    if(!descriptionStr[i].includes("\n") && !keywords.includes(descriptionStr[i])){
      keywords.push(descriptionStr[i].trim())
    }
    }
}


if(productCategory == "ساعات"){

  if(!keywords.includes("ساعة")){
    keywords.push("ساعة")
  }
  if(!keywords.includes("ساعه")){
    keywords.push("ساعه")
  }
  if(!keywords.includes("ساعات")){
    keywords.push("ساعات")
  }
  



  keywords.push(watchDepartment);

  if(watchDepartment === "رجالي"){
    if(!keywords.includes("رجالية")){
      keywords.push("رجالية")
    }
    if(!keywords.includes("رجاليه")){
      keywords.push("رجاليه")
    }
  }
  else if(watchDepartment === "نسائي"){
    if(!keywords.includes("نسائية")){
      keywords.push("نسائية")
    }
    if(!keywords.includes("نسائيه")){
      keywords.push("نسائيه")
    }
  }





  keywords.push(watchInsideColor);

  if(watchInsideColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchInsideColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchInsideColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchInsideColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchInsideColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchInsideColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchInsideColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchInsideColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchInsideColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchInsideColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchInsideColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchInsideColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchInsideColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchInsideColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchInsideColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }


// if(watchInsideColorSpecification){
// if(!keywords.includes(watchInsideColorSpecification)){
// keywords.push(watchInsideColorSpecification);
// if(watchInsideColorSpecification.includes("أ") && !keywords.includes(watchInsideColorSpecification.replace("أ", "ا"))){
// keywords.push(watchInsideColorSpecification.replace("أ", "ا"));
// }
// }
// }

  keywords.push(watchOutsideColor);

  if(watchOutsideColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchOutsideColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchOutsideColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchOutsideColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchOutsideColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchOutsideColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchOutsideColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchOutsideColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchOutsideColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchOutsideColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchOutsideColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchOutsideColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchOutsideColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchOutsideColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchOutsideColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }



// if(watchOutsideColorSpecification){
//   if(!keywords.includes(watchOutsideColorSpecification)){
//     keywords.push(watchOutsideColorSpecification);
//   if(watchOutsideColorSpecification.includes("أ") && !keywords.includes(watchOutsideColorSpecification.replace("أ", "ا"))){
//     keywords.push(watchOutsideColorSpecification.replace("أ", "ا"));
//   }
//   }
// }

  if(watchDialShape && !keywords.includes(watchDialShape)){
  keywords.push(watchDialShape)
  if(watchDialShape === "دائري"){
    if(!keywords.includes("دائرية")) keywords.push("دائرية")
    if(!keywords.includes("دائريه")) keywords.push("دائريه")
  }
  else if(watchDialShape === "مربع"){
    if(!keywords.includes("مربعة")) keywords.push("مربعة")
    if(!keywords.includes("مربعه")) keywords.push("مربعه")
  }
  else if(watchDialShape === "مستطيل"){
    if(!keywords.includes("مستطيلة")) keywords.push("مستطيلة")
    if(!keywords.includes("مستطيله")) keywords.push("مستطيله")
  }
  else if(watchDialShape === "بيضاوي"){
    if(!keywords.includes("بيضاوية")) keywords.push("بيضاوية")
    if(!keywords.includes("بيضاويه")) keywords.push("بيضاويه")
  }
  else if(watchDialShape === "خماسي"){
    if(!keywords.includes("خماسية")) keywords.push("خماسية")
    if(!keywords.includes("خماسيه")) keywords.push("خماسيه")
  }
  else if(watchDialShape === "سداسي"){
    if(!keywords.includes("سداسية")) keywords.push("سداسية")
    if(!keywords.includes("سداسيه")) keywords.push("سداسيه")
  }
}

  if(watchDialSize && !keywords.includes(watchDialSize)){
  keywords.push(watchDialSize)
  }

  if(watchHandlesColor && !keywords.includes(watchHandlesColor)){
  keywords.push(watchHandlesColor)
  
  if(watchHandlesColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchHandlesColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchHandlesColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchHandlesColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchHandlesColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchHandlesColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchHandlesColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchHandlesColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchHandlesColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchHandlesColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchHandlesColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchHandlesColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchHandlesColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchHandlesColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchHandlesColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
  }

  // if(watchHandlesColorSpecification){
  //   if(!keywords.includes(watchHandlesColorSpecification)){
  //     keywords.push(watchHandlesColorSpecification);
  //   if(watchHandlesColorSpecification.includes("أ") && !keywords.includes(watchHandlesColorSpecification.replace("أ", "ا"))){
  //     keywords.push(watchHandlesColorSpecification.replace("أ", "ا"));
  //   }
  //   }
  // }

  if(watchNumbersColor && !keywords.includes(watchNumbersColor)){
    keywords.push(watchNumbersColor)
    
  if(watchNumbersColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchNumbersColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchNumbersColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchNumbersColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchNumbersColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchNumbersColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchNumbersColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchNumbersColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchNumbersColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchNumbersColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchNumbersColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchNumbersColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchNumbersColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchNumbersColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchNumbersColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
}

// if(watchNumbersColor){
// if(!keywords.includes(watchNumbersColorSpecification)){
// keywords.push(watchNumbersColorSpecification);
// if(watchHandlesColorSpecification.includes("أ") && !keywords.includes(watchHandlesColorSpecification.replace("أ", "ا"))){
// keywords.push(watchHandlesColorSpecification.replace("أ", "ا"));
// }
// }
// }


  if(watchNumbersLanguage && !keywords.includes(watchNumbersLanguage)){
  if(!keywords.includes("ارقام"))
  keywords.push("ارقام")

  if(!keywords.includes("أرقام"))
  keywords.push("أرقام")

  if(!keywords.includes(watchNumbersLanguage))
  keywords.push(watchNumbersLanguage)

  if(watchNumbersLanguage === "العربية"){
  if(!keywords.includes("العربية")){
  keywords.push("العربية")
  }
  if(!keywords.includes("العربيه")){
  keywords.push("العربيه")
  }
  if(!keywords.includes("عربية")){
  keywords.push("عربية")
  }
  if(!keywords.includes("عربيه")){
  keywords.push("عربيه")
  }
  }
  else if(watchNumbersLanguage === "الإنجليزية"){
  if(!keywords.includes("الإنجليزية")){
  keywords.push("الإنجليزية")
  }
  if(!keywords.includes("الإنجليزيه")){
  keywords.push("الإنجليزيه")
  }
  if(!keywords.includes("انجليزية")){
  keywords.push("انجليزية")
  }
  if(!keywords.includes("انجليزيه")){
  keywords.push("انجليزيه")
  }
  if(!keywords.includes("إنجليزية")){
  keywords.push("إنجليزية")
  }
  if(!keywords.includes("إنجليزيه")){
  keywords.push("إنجليزيه")
  }
  }
  else if(watchNumbersLanguage === "الرومانية"){
  if(!keywords.includes("الرومانية")){
  keywords.push("الرومانية")
  }
  if(!keywords.includes("الرومانيه")){
  keywords.push("الرومانيه")
  }
  if(!keywords.includes("رومانية")){
  keywords.push("رومانية")
  keywords.push("رومانيه")
  }
  }
  else if(watchNumbersLanguage === "بدون أرقام"){
  if(!keywords.includes("بدون ارقام")){
  keywords.push("بدون ارقام")
  }
  if(!keywords.includes("بدون أرقام")){
  keywords.push("بدون أرقام")
  }
  }
}

// if(watchBandColorSpecification){
// if(!keywords.includes(watchBandColorSpecification)){
// keywords.push(watchBandColorSpecification);
// if(watchBandColorSpecification.includes("أ") && !keywords.includes(watchBandColorSpecification.replace("أ", "ا"))){
// keywords.push(watchBandColorSpecification.replace("أ", "ا"));
// }
// }
// }


if(watchBandMaterial === "ستانلس ستيل") setWatchBandColor("ستانلس ستيل");

if(!keywords.includes(watchBandColor)){
  keywords.push(watchBandColor);
  
  if(watchBandColor === "ستانلس ستيل"){
    if(!keywords.includes("ستانلس ستيل")){
      keywords.push("ستانلس ستيل")
    }
    if(!keywords.includes("ستانلس")){
      keywords.push("ستانلس")
    }
    if(!keywords.includes("ستيل")){
      keywords.push("ستيل")
    }
  }

  if(watchBandColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(watchBandColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(watchBandColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(watchBandColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(watchBandColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(watchBandColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(watchBandColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(watchBandColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(watchBandColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(watchBandColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(watchBandColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(watchBandColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(watchBandColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(watchBandColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
  }



  if(watchYearMade){
    if(!keywords.includes(watchYearMade)){
      keywords.push(watchYearMade)
    }
  }

  if(watchIsWaterResistant){
    if(watchIsWaterResistant === true){
    if(!keywords.includes("مقاومة للماء")){
    keywords.push("مقاومة للماء")
    }
    if(!keywords.includes("مقاومه للماء")){
    keywords.push("مقاومه للماء")
    }
    if(!keywords.includes("مقاومة")){
    keywords.push("مقاومة")
    }
    if(!keywords.includes("مقاومه")){
    keywords.push("مقاومه")
    }
    }
      
    else{
      if(!keywords.includes("غير مقاومة للماء")){
      keywords.push("غير مقاومة للماء")
      }
      if(!keywords.includes("غير مقاومه للماء")){
      keywords.push("مقاومه للماء")
      }
      if(!keywords.includes("غير")){
      keywords.push("غير")
      }
      if(!keywords.includes("مقاومة")){
      keywords.push("مقاومة")
      }
      if(!keywords.includes("مقاومه")){
      keywords.push("مقاومه")
      }
    }
  }

  if(watchHasOriginalPackaging){
    if(watchHasOriginalPackaging === true){
      if(!keywords.includes("مع صندوق")) keywords.push("مع صندوق")
    } else{
      if(!keywords.includes("بدون صندوق")) keywords.push("بدون صندوق")
  }
  }
}




else if(productCategory == "سبح"){
  if(!keywords.includes("سبحة")){
    keywords.push("سبحة")
  }
  if(!keywords.includes("سبحه")){
    keywords.push("سبحه")
  }
  if(!keywords.includes("مسباح")){
    keywords.push("مسباح")
  }

  if(!keywords.includes(rosaryKind)){
    keywords.push(rosaryKind)
  }

  if(!keywords.includes(rosaryColor)){
  keywords.push(rosaryColor)

  if(rosaryColor === "أسود"){
    if(!keywords.includes("اسود")){
      keywords.push("اسود")
    }
    if(!keywords.includes("سوداء")){
      keywords.push("سوداء")
    }
    if(!keywords.includes("سودا")){
      keywords.push("سودا")
    }
  }

  if(rosaryColor === "أبيض"){
    if(!keywords.includes("ابيض")){
      keywords.push("ابيض")
    }
    if(!keywords.includes("بيضاء")){
      keywords.push("بيضاء")
    }
    if(!keywords.includes("بيضا")){
      keywords.push("بيضا")
    }
  }


  if(rosaryColor === "أحمر"){
    if(!keywords.includes("أحمر")){
      keywords.push("أحمر")
    }
    if(!keywords.includes("احمر")){
      keywords.push("احمر")
    }
    if(!keywords.includes("حمراء")){
      keywords.push("حمراء")
    }
    if(!keywords.includes("حمرا")){
      keywords.push("حمرا")
    }
  }
  
  if(rosaryColor === "أزرق"){
    if(!keywords.includes("أزرق")){
    keywords.push("أزرق")
    }
    if(!keywords.includes("ازرق")){
    keywords.push("ازرق")
    }
    if(!keywords.includes("زرقاء")){
    keywords.push("زرقاء")
    }
  }
  
  if(rosaryColor === "أخضر"){
    if(!keywords.includes("أخضر")){
    keywords.push("أخضر")
    }
    if(!keywords.includes("اخضر")){
    keywords.push("اخضر")
    }
    if(!keywords.includes("خضراء")){
    keywords.push("خضراء")
    }
    if(!keywords.includes("خضرا")){
    keywords.push("خضرا")
    }
  }
  
  if(rosaryColor === "أصفر"){
    if(!keywords.includes("أصفر")){
    keywords.push("أصفر")
    }
    if(!keywords.includes("اصفر")){
    keywords.push("اصفر")
    }
    if(!keywords.includes("صفراء")){
    keywords.push("صفراء")
    }
    if(!keywords.includes("صفرا")){
    keywords.push("صفراء")
    }
  }
  
  if(rosaryColor === "برتقالي"){
    if(!keywords.includes("برتقالي")){
      keywords.push("برتقالي")
    }
    if(!keywords.includes("برتقالية")){
      keywords.push("برتقالية")
    }
    if(!keywords.includes("برتقاليه")){
      keywords.push("برتقاليه")
    }
  }
  
  if(rosaryColor === "بنفسجي"){
    if(!keywords.includes("بنفسجي")){
      keywords.push("بنفسجي")
    }
    if(!keywords.includes("بنفسجية")){
      keywords.push("بنفسجية")
    }
    if(!keywords.includes("بنفسجيه")){
      keywords.push("بنفسجيه")
    }
  }
  
  if(rosaryColor === "وردي"){
    if(!keywords.includes("وردي")){
      keywords.push("وردي")
    }
    if(!keywords.includes("وردية")){
      keywords.push("وردية")
    }
    if(!keywords.includes("ورديه")){
      keywords.push("ورديه")
    }
  }
  
  if(rosaryColor === "بني"){
    if(!keywords.includes("بني")){
      keywords.push("بني")
    }
    if(!keywords.includes("بنية")){
      keywords.push("بنية")
    }
    if(!keywords.includes("بنيه")){
      keywords.push("بنيه")
    }
  }
  
  if(rosaryColor === "رمادي"){
    if(!keywords.includes("رمادي")){
      keywords.push("رمادي")
    }
    if(!keywords.includes("رمادية")){
      keywords.push("رمادية")
    }
    if(!keywords.includes("رماديه")){
      keywords.push("رماديه")
    }
  }
  
  if(rosaryColor === "زيتي"){
    if(!keywords.includes("زيتي")){
      keywords.push("زيتي")
    }
    if(!keywords.includes("زيتية")){
      keywords.push("زيتية")
    }
    if(!keywords.includes("زيتيه")){
      keywords.push("زيتيه")
    }
  }
  
  if(rosaryColor === "سماوي"){
    if(!keywords.includes("سماوي")){
      keywords.push("سماوي")
    }
    if(!keywords.includes("سماوية")){
      keywords.push("سماوية")
    }
    if(!keywords.includes("سماويه")){
      keywords.push("سماويه")
    }
  }
  
  if(rosaryColor === "كحلي"){
    if(!keywords.includes("كحلي")){
      keywords.push("كحلي")
    }
    if(!keywords.includes("كحلية")){
      keywords.push("كحلية")
    }
    if(!keywords.includes("كحليه")){
      keywords.push("كحليه")
    }
  }
}
  if(!keywords.includes(rosaryCount)){
  keywords.push(rosaryCount)
  }

  if(rosarySize && !keywords.includes(rosarySize)){
    keywords.push(rosarySize)
    keywords.push("ملم")
  }

  if(!keywords.includes("خرز")){
  keywords.push("خرز")
  }
  if(!keywords.includes("خرزة")){
  keywords.push("خرزة")
  }
  if(!keywords.includes("خرزه")){
  keywords.push("خرزه")
  }




}


else if(productCategory == "اقلام"){
if(!keywords.includes("قلم")){
keywords.push("قلم")
}
if(!keywords.includes("أقلام")){
keywords.push("أقلام")
}
if(!keywords.includes("اقلام")){
keywords.push("اقلام")
}
}

else if(productCategory == "ابواك"){

if(!keywords.includes("بوك")){
keywords.push("بوك")
}
if(!keywords.includes("محفظة")){
keywords.push("محفظة")
}
if(!keywords.includes("محفظه")){
keywords.push("محفظه")
}

keywords.push(walletDepartment);

if(walletDepartment === "رجالي"){
  if(!keywords.includes("رجالية")) keywords.push("رجالية")
  if(!keywords.includes("رجاليه")) keywords.push("رجاليه")
}

if(walletDepartment === "نسائي"){
  if(!keywords.includes("نسائية")) keywords.push("نسائية")
  if(!keywords.includes("نسائيه")) keywords.push("نسائيه")
}



if(walletColor === "أسود"){
  if(!keywords.includes("اسود")){
    keywords.push("اسود")
  }
  if(!keywords.includes("سوداء")){
    keywords.push("سوداء")
  }
  if(!keywords.includes("سودا")){
    keywords.push("سودا")
  }
}


if(walletColor === "أبيض"){
  if(!keywords.includes("ابيض")){
    keywords.push("ابيض")
  }
  if(!keywords.includes("بيضاء")){
    keywords.push("بيضاء")
  }
  if(!keywords.includes("بيضا")){
    keywords.push("بيضا")
  }
}


if(walletColor === "أحمر"){
  if(!keywords.includes("أحمر")){
    keywords.push("أحمر")
  }
  if(!keywords.includes("احمر")){
    keywords.push("احمر")
  }
  if(!keywords.includes("حمراء")){
    keywords.push("حمراء")
  }
  if(!keywords.includes("حمرا")){
    keywords.push("حمرا")
  }
}

if(walletColor === "أزرق"){
  if(!keywords.includes("أزرق")){
  keywords.push("أزرق")
  }
  if(!keywords.includes("ازرق")){
  keywords.push("ازرق")
  }
  if(!keywords.includes("زرقاء")){
  keywords.push("زرقاء")
  }
}

if(walletColor === "أخضر"){
  if(!keywords.includes("أخضر")){
  keywords.push("أخضر")
  }
  if(!keywords.includes("اخضر")){
  keywords.push("اخضر")
  }
  if(!keywords.includes("خضراء")){
  keywords.push("خضراء")
  }
  if(!keywords.includes("خضرا")){
  keywords.push("خضرا")
  }
}

if(walletColor === "أصفر"){
  if(!keywords.includes("أصفر")){
  keywords.push("أصفر")
  }
  if(!keywords.includes("اصفر")){
  keywords.push("اصفر")
  }
  if(!keywords.includes("صفراء")){
  keywords.push("صفراء")
  }
  if(!keywords.includes("صفرا")){
  keywords.push("صفراء")
  }
}

if(walletColor === "برتقالي"){
  if(!keywords.includes("برتقالي")){
    keywords.push("برتقالي")
  }
  if(!keywords.includes("برتقالية")){
    keywords.push("برتقالية")
  }
  if(!keywords.includes("برتقاليه")){
    keywords.push("برتقاليه")
  }
}

if(walletColor === "بنفسجي"){
  if(!keywords.includes("بنفسجي")){
    keywords.push("بنفسجي")
  }
  if(!keywords.includes("بنفسجية")){
    keywords.push("بنفسجية")
  }
  if(!keywords.includes("بنفسجيه")){
    keywords.push("بنفسجيه")
  }
}

if(walletColor === "وردي"){
  if(!keywords.includes("وردي")){
    keywords.push("وردي")
  }
  if(!keywords.includes("وردية")){
    keywords.push("وردية")
  }
  if(!keywords.includes("ورديه")){
    keywords.push("ورديه")
  }
}

if(walletColor === "بني"){
  if(!keywords.includes("بني")){
    keywords.push("بني")
  }
  if(!keywords.includes("بنية")){
    keywords.push("بنية")
  }
  if(!keywords.includes("بنيه")){
    keywords.push("بنيه")
  }
}

if(walletColor === "رمادي"){
  if(!keywords.includes("رمادي")){
    keywords.push("رمادي")
  }
  if(!keywords.includes("رمادية")){
    keywords.push("رمادية")
  }
  if(!keywords.includes("رماديه")){
    keywords.push("رماديه")
  }
}

if(walletColor === "زيتي"){
  if(!keywords.includes("زيتي")){
    keywords.push("زيتي")
  }
  if(!keywords.includes("زيتية")){
    keywords.push("زيتية")
  }
  if(!keywords.includes("زيتيه")){
    keywords.push("زيتيه")
  }
}

if(walletColor === "سماوي"){
  if(!keywords.includes("سماوي")){
    keywords.push("سماوي")
  }
  if(!keywords.includes("سماوية")){
    keywords.push("سماوية")
  }
  if(!keywords.includes("سماويه")){
    keywords.push("سماويه")
  }
}

if(walletColor === "كحلي"){
  if(!keywords.includes("كحلي")){
    keywords.push("كحلي")
  }
  if(!keywords.includes("كحلية")){
    keywords.push("كحلية")
  }
  if(!keywords.includes("كحليه")){
    keywords.push("كحليه")
  }
}

}


else if(productCategory == "لوح سيارات"){
if(!keywords.includes("لوحة")){
keywords.push("لوحة")
}
if(!keywords.includes("لوحه")){
keywords.push("لوحه")
}
if(!keywords.includes("لوحة سيارة")){
keywords.push("لوحة سيارة")
}
if(!keywords.includes("لوحه سيارة")){
keywords.push("لوحه سيارة")
}
if(!keywords.includes("لوحه سياره")){
keywords.push("لوحه سياره")
}
if(!keywords.includes("لوحة سياره")){
keywords.push("لوحة سياره")
}



// Collect Car Plate Arabic letters
if (carPlateFirstArabicLetter) {
  keywords.push(carPlateFirstArabicLetter);
}
if (carPlateSecondArabicLetter) {
  keywords.push(carPlateSecondArabicLetter);
}
if (carPlateThirdArabicLetter) {
  keywords.push(carPlateThirdArabicLetter);
}

// Collect Car Plate Arabic numbers
if (carPlateFirstArabicNumber) {
  keywords.push(carPlateFirstArabicNumber);
}
if (carPlateSecondArabicNumber) {
  keywords.push(carPlateSecondArabicNumber);
}
if (carPlateThirdArabicNumber) {
  keywords.push(carPlateThirdArabicNumber);
}
if (carPlateFourthArabicNumber) {
  keywords.push(carPlateFourthArabicNumber);
}

// Collect Car Plate English letters
if (carPlateFirstEnglishLetter) {
  keywords.push(carPlateFirstEnglishLetter);
}
if (carPlateSecondEnglishLetter) {
  keywords.push(carPlateSecondEnglishLetter);
}
if (carPlateThirdEnglishLetter) {
  keywords.push(carPlateThirdEnglishLetter);
}

// Collect Car Plate English numbers
if (carPlateFirstEnglishNumber) {
  keywords.push(carPlateFirstEnglishNumber);
}
if (carPlateSecondEnglishNumber) {
  keywords.push(carPlateSecondEnglishNumber);
}
if (carPlateThirdEnglishNumber) {
  keywords.push(carPlateThirdEnglishNumber);
}
if (carPlateFourthEnglishNumber) {
  keywords.push(carPlateFourthEnglishNumber);
}

}

else if(productCategory == "ارقام جوالات"){
if(!keywords.includes("رقم")){
keywords.push("رقم")
}
if(!keywords.includes("رقم جوال")){
keywords.push("رقم جوال")
}
if(phoneNumberCourier === "STC"){
  if(!keywords.includes("الاتصالات السعودية")) keywords.push("الاتصالات")
}
if(!keywords.includes(phoneNumberCourier)){
keywords.push(phoneNumberCourier)
if(!keywords.includes(phoneNumberToSell)){
keywords.push(phoneNumberToSell)
}
}


}

else if(productCategory == "حقائب نسائية"){

if(!keywords.includes(purseOutsideColor)){
keywords.push(purseOutsideColor)

if(purseOutsideColor === "أسود"){
  if(!keywords.includes("اسود")){
    keywords.push("اسود")
  }
  if(!keywords.includes("سوداء")){
    keywords.push("سوداء")
  }
  if(!keywords.includes("سودا")){
    keywords.push("سودا")
  }
}

if(purseOutsideColor === "أبيض"){
  if(!keywords.includes("ابيض")){
    keywords.push("ابيض")
  }
  if(!keywords.includes("بيضاء")){
    keywords.push("بيضاء")
  }
  if(!keywords.includes("بيضا")){
    keywords.push("بيضا")
  }
}


if(purseOutsideColor === "أحمر"){
  if(!keywords.includes("أحمر")){
    keywords.push("أحمر")
  }
  if(!keywords.includes("احمر")){
    keywords.push("احمر")
  }
  if(!keywords.includes("حمراء")){
    keywords.push("حمراء")
  }
  if(!keywords.includes("حمرا")){
    keywords.push("حمرا")
  }
}

if(purseOutsideColor === "أزرق"){
  if(!keywords.includes("أزرق")){
  keywords.push("أزرق")
  }
  if(!keywords.includes("ازرق")){
  keywords.push("ازرق")
  }
  if(!keywords.includes("زرقاء")){
  keywords.push("زرقاء")
  }
}

if(purseOutsideColor === "أخضر"){
  if(!keywords.includes("أخضر")){
  keywords.push("أخضر")
  }
  if(!keywords.includes("اخضر")){
  keywords.push("اخضر")
  }
  if(!keywords.includes("خضراء")){
  keywords.push("خضراء")
  }
  if(!keywords.includes("خضرا")){
  keywords.push("خضرا")
  }
}

if(purseOutsideColor === "أصفر"){
  if(!keywords.includes("أصفر")){
  keywords.push("أصفر")
  }
  if(!keywords.includes("اصفر")){
  keywords.push("اصفر")
  }
  if(!keywords.includes("صفراء")){
  keywords.push("صفراء")
  }
  if(!keywords.includes("صفرا")){
  keywords.push("صفراء")
  }
}

if(purseOutsideColor === "برتقالي"){
  if(!keywords.includes("برتقالي")){
    keywords.push("برتقالي")
  }
  if(!keywords.includes("برتقالية")){
    keywords.push("برتقالية")
  }
  if(!keywords.includes("برتقاليه")){
    keywords.push("برتقاليه")
  }
}

if(purseOutsideColor === "بنفسجي"){
  if(!keywords.includes("بنفسجي")){
    keywords.push("بنفسجي")
  }
  if(!keywords.includes("بنفسجية")){
    keywords.push("بنفسجية")
  }
  if(!keywords.includes("بنفسجيه")){
    keywords.push("بنفسجيه")
  }
}

if(purseOutsideColor === "وردي"){
  if(!keywords.includes("وردي")){
    keywords.push("وردي")
  }
  if(!keywords.includes("وردية")){
    keywords.push("وردية")
  }
  if(!keywords.includes("ورديه")){
    keywords.push("ورديه")
  }
}

if(purseOutsideColor === "بني"){
  if(!keywords.includes("بني")){
    keywords.push("بني")
  }
  if(!keywords.includes("بنية")){
    keywords.push("بنية")
  }
  if(!keywords.includes("بنيه")){
    keywords.push("بنيه")
  }
}

if(purseOutsideColor === "رمادي"){
  if(!keywords.includes("رمادي")){
    keywords.push("رمادي")
  }
  if(!keywords.includes("رمادية")){
    keywords.push("رمادية")
  }
  if(!keywords.includes("رماديه")){
    keywords.push("رماديه")
  }
}

if(purseOutsideColor === "زيتي"){
  if(!keywords.includes("زيتي")){
    keywords.push("زيتي")
  }
  if(!keywords.includes("زيتية")){
    keywords.push("زيتية")
  }
  if(!keywords.includes("زيتيه")){
    keywords.push("زيتيه")
  }
}

if(purseOutsideColor === "سماوي"){
  if(!keywords.includes("سماوي")){
    keywords.push("سماوي")
  }
  if(!keywords.includes("سماوية")){
    keywords.push("سماوية")
  }
  if(!keywords.includes("سماويه")){
    keywords.push("سماويه")
  }
}

if(purseOutsideColor === "كحلي"){
  if(!keywords.includes("كحلي")){
    keywords.push("كحلي")
  }
  if(!keywords.includes("كحلية")){
    keywords.push("كحلية")
  }
  if(!keywords.includes("كحليه")){
    keywords.push("كحليه")
  }
}
}

if(purseInsideColor !== "" && !keywords.includes(purseInsideColor)){
keywords.push(purseInsideColor)

if(purseInsideColor === "أسود"){
  if(!keywords.includes("اسود")){
    keywords.push("اسود")
  }
  if(!keywords.includes("سوداء")){
    keywords.push("سوداء")
  }
  if(!keywords.includes("سودا")){
    keywords.push("سودا")
  }
}

if(purseInsideColor === "أبيض"){
  if(!keywords.includes("ابيض")){
    keywords.push("ابيض")
  }
  if(!keywords.includes("بيضاء")){
    keywords.push("بيضاء")
  }
  if(!keywords.includes("بيضا")){
    keywords.push("بيضا")
  }
}


if(purseInsideColor === "أحمر"){
  if(!keywords.includes("أحمر")){
    keywords.push("أحمر")
  }
  if(!keywords.includes("احمر")){
    keywords.push("احمر")
  }
  if(!keywords.includes("حمراء")){
    keywords.push("حمراء")
  }
  if(!keywords.includes("حمرا")){
    keywords.push("حمرا")
  }
}

if(purseInsideColor === "أزرق"){
  if(!keywords.includes("أزرق")){
  keywords.push("أزرق")
  }
  if(!keywords.includes("ازرق")){
  keywords.push("ازرق")
  }
  if(!keywords.includes("زرقاء")){
  keywords.push("زرقاء")
  }
}

if(purseInsideColor === "أخضر"){
  if(!keywords.includes("أخضر")){
  keywords.push("أخضر")
  }
  if(!keywords.includes("اخضر")){
  keywords.push("اخضر")
  }
  if(!keywords.includes("خضراء")){
  keywords.push("خضراء")
  }
  if(!keywords.includes("خضرا")){
  keywords.push("خضرا")
  }
}

if(purseInsideColor === "أصفر"){
  if(!keywords.includes("أصفر")){
  keywords.push("أصفر")
  }
  if(!keywords.includes("اصفر")){
  keywords.push("اصفر")
  }
  if(!keywords.includes("صفراء")){
  keywords.push("صفراء")
  }
  if(!keywords.includes("صفرا")){
  keywords.push("صفراء")
  }
}

if(purseInsideColor === "برتقالي"){
  if(!keywords.includes("برتقالي")){
    keywords.push("برتقالي")
  }
  if(!keywords.includes("برتقالية")){
    keywords.push("برتقالية")
  }
  if(!keywords.includes("برتقاليه")){
    keywords.push("برتقاليه")
  }
}

if(purseInsideColor === "بنفسجي"){
  if(!keywords.includes("بنفسجي")){
    keywords.push("بنفسجي")
  }
  if(!keywords.includes("بنفسجية")){
    keywords.push("بنفسجية")
  }
  if(!keywords.includes("بنفسجيه")){
    keywords.push("بنفسجيه")
  }
}

if(purseInsideColor === "وردي"){
  if(!keywords.includes("وردي")){
    keywords.push("وردي")
  }
  if(!keywords.includes("وردية")){
    keywords.push("وردية")
  }
  if(!keywords.includes("ورديه")){
    keywords.push("ورديه")
  }
}

if(purseInsideColor === "بني"){
  if(!keywords.includes("بني")){
    keywords.push("بني")
  }
  if(!keywords.includes("بنية")){
    keywords.push("بنية")
  }
  if(!keywords.includes("بنيه")){
    keywords.push("بنيه")
  }
}

if(purseInsideColor === "رمادي"){
  if(!keywords.includes("رمادي")){
    keywords.push("رمادي")
  }
  if(!keywords.includes("رمادية")){
    keywords.push("رمادية")
  }
  if(!keywords.includes("رماديه")){
    keywords.push("رماديه")
  }
}

if(purseInsideColor === "زيتي"){
  if(!keywords.includes("زيتي")){
    keywords.push("زيتي")
  }
  if(!keywords.includes("زيتية")){
    keywords.push("زيتية")
  }
  if(!keywords.includes("زيتيه")){
    keywords.push("زيتيه")
  }
}

if(purseInsideColor === "سماوي"){
  if(!keywords.includes("سماوي")){
    keywords.push("سماوي")
  }
  if(!keywords.includes("سماوية")){
    keywords.push("سماوية")
  }
  if(!keywords.includes("سماويه")){
    keywords.push("سماويه")
  }
}

if(purseInsideColor === "كحلي"){
  if(!keywords.includes("كحلي")){
    keywords.push("كحلي")
  }
  if(!keywords.includes("كحلية")){
    keywords.push("كحلية")
  }
  if(!keywords.includes("كحليه")){
    keywords.push("كحليه")
  }
}
}


if(purseInsideColor === "أسود"){
if(!keywords.includes("سوداء")){
keywords.push("سوداء")
}
if(!keywords.includes("سودا")){
keywords.push("سودا")
}
}
else if(purseInsideColor === "أبيض"){
if(!keywords.includes("بيضاء")){
keywords.push("بيضاء")
}
if(!keywords.includes("بيضا")){
keywords.push("بيضا")
}
}
else if(purseInsideColor === "وردي"){
if(!keywords.includes("وردية")){
keywords.push("وردية")
}
if(!keywords.includes("ورديه")){
keywords.push("ورديه")
}
}
else if(purseInsideColor === "ذهبي"){
if(!keywords.includes("ذهبية")){
keywords.push("ذهبية")
}
if(!keywords.includes("ذهبيه")){
keywords.push("ذهبيه")
}
}
else if(purseInsideColor === "زيتي"){
if(!keywords.includes("زيتية")){
keywords.push("زيتية")
}
if(!keywords.includes("زيتيه")){
keywords.push("زيتيه")
}
}
else if(purseInsideColor === "أخضر"){
if(!keywords.includes("خضراء")){
keywords.push("خضراء")
}
if(!keywords.includes("خضرا")){
keywords.push("خضرا")
}
}
else if(purseInsideColor === "بني"){
if(!keywords.includes("بنية")){
keywords.push("بنية")
}
if(!keywords.includes("بنيه")){
keywords.push("بنيه")
}
}
else if(purseInsideColor === "كحلي"){
if(!keywords.includes("كحلية")){
keywords.push("كحلية")
}
if(!keywords.includes("كحليه")){
keywords.push("كحليه")
}
}
else if(purseInsideColor === "سماوي"){
if(!keywords.includes("سماوية")){
keywords.push("سماوية")
}
if(!keywords.includes("سماويه")){
keywords.push("سماويه")
}
}
else if(purseInsideColor === "بنفسجي"){
if(!keywords.includes("بنفسجية")){
keywords.push("بنفسجية")
}
if(!keywords.includes("بنفسجيه")){
keywords.push("بنفسجيه")
}
}
else{
// if(!keywords.includes(purseOutsideColorSpecification)){
// keywords.push(purseOutsideColorSpecification)
// }
}

if(!keywords.includes("شنطة")){
keywords.push("شنطة")
}
if(!keywords.includes("شنطه")){
keywords.push("شنطه")
}
if(!keywords.includes("حقيبه")){
keywords.push("حقيبه")
}
}

try {
  setUpdateLoading(true);

  const functions = getFunctions();
  const updateProductFunction = httpsCallable(functions, 'updateProduct');



  // Construct product data object
  const productData = {
    bidIncrements, 
    carPlateFirstArabicLetter,
    carPlateFirstArabicNumber,
    carPlateFirstEnglishLetter,
    carPlateFirstEnglishNumber,
    carPlateFourthArabicNumber,
    carPlateFourthEnglishNumber,
    carPlateKind, 
    carPlateSecondArabicLetter, 
    carPlateSecondArabicNumber, 
    carPlateSecondEnglishLetter, 
    carPlateSecondEnglishNumber, 
    carPlateThirdArabicLetter, 
    carPlateThirdArabicNumber, 
    carPlateThirdEnglishLetter, 
    carPlateThirdEnglishNumber, 
    carPlateTransferFeeOn, 
    carPlateTransferTime, 
    category: productCategory, 
    condition: productCondition, 
    entryFee, 
    expiryDate: productExpiryDate instanceof Timestamp ? new Date(productExpiryDate.toDate()).getTime() : productExpiryDate,
    postImages: postImages.filter(image => image.src),
    keywords,
    phoneNumberCourier, 
    phoneNumberToSell, 
    postId: id, 
    postImages,
    postType, 
    productCompany, 
    productDescription, 
    productName, 
    productPrice, 
    productQty, 
    productWeight, 
    purseInsideColor, 
    purseInsideColorSpecification: purseInsideColorSpecification || "", 
    purseOutsideColor, 
    purseOutsideColorSpecification: purseOutsideColorSpecification || "",
    purseMaterial: purseMaterial || "",
    rosaryColor, 
    rosaryCount, 
    rosaryKind, 
    rosarySize, 
    shippingMethods, 
    startingPrice, 
    walletColor, 
    walletDepartment,
    watchBandColor, 
    watchBandColorSpecification, 
    watchBandMaterial, 
    watchDepartment, 
    watchDialShape, 
    watchDialSize: watchDialSize || 0, 
    watchHandlesColor, 
    watchHandlesColorSpecification, 
    watchHasOriginalPackaging, 
    watchInsideColor, 
    watchInsideColorSpecification, 
    watchIsWaterResistant, 
    watchNumbersColor, 
    watchNumbersColorSpecification, 
    watchNumbersLanguage, 
    watchOutsideColor, 
    watchOutsideColorSpecification, 
    watchYearMade: watchYearMade || 0
  };

  const response = await updateProductFunction(productData);

  // console.log(response.data.message);
  if (response.data.success) {
    setSuccessMessage("!تم تحديث بيانات المنتج");
    setShowSuccessAnim(true)
    scrollToTop()
  } else {
    console.error('Failed to delete product:', response.data.message);
  }
} catch (error) {
  console.error("Error updating product:", error);
} finally {
  setUpdateLoading(false);
}
}





const handleSelectWatchDepartment = (company) => {
  setWatchDepartment(company);
  setWatchDepartmentScrollTrigger(true)
};

const handleAnimationComplete = () => {
  setShowSuccessAnim(false); // Reset button state after animation completes
};


return (
  <>
    {numBidders > 0 ? (
      <>
        <p>لا يمكنك تعديل منتج تمت المزايدة عليه</p>
      </>
    ) : (
      <>
      {
        productIsSold && !isBusiness && (
          <div id="landing-screen-div" style={{ justifyContent: "center", alignItems: "center", direction: "rtl", height: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)", display:"flex" }}>
            
      <div style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
              <GiBangingGavel color="#00C8B8" style={{width: window.innerWidth > window.innerHeight ? "250px" : "150px", height: window.innerWidth > window.innerHeight ? "250px" : "150px"}} />
              <h4 style={{marginTop:"16px", marginBottom:"4px"}}>تم بيع المنتج!</h4>
            </div>
      </div>
        )
      }
      {showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}
      
              <>
      
          <div ref={productCategoryDivRef} style={{paddingTop:"16px",width: window.innerWidth > window.innerHeight ? "80%" : "100%", height:"fit-content", marginLeft: "auto", marginRight:"auto", direction:"rtl", paddingBottom:"32px", padding: window.innerWidth > window.innerHeight ?  "auto" : "16px", minHeight:"100vh"}}>
      
                  {/* path */}
                  <div style={{
              backgroundColor: "#F7F7F7",
              border: "1px solid #E8E9E9",
              width: "100%", 
              height:"100%",
              marginTop:"18px",
              padding: "11px",
              borderRadius:"8px",
              justifyContent:"center",
              marginRight:"auto",
              marginLeft:"auto"
            }}>
              <div style={{display:"flex", direction:"rtl", 
              }}>
                          <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />
      
                <a href='/'>
      
                <h6 style={{marginBottom:"0px", 
            color: "#01635c",
            cursor:"pointer",
            fontSize:"14px"
          }}
          
          onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
          onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >الرئيسية</h6>
          </a>
                <h6 style={{margin:"0px 6px",
            fontSize:"14px"
      
                }}>/</h6>
                <h6 style={{marginBottom:"0px", 
            color: "#01635c",
            cursor:"pointer",
            fontSize:"14px"
          }}
          onClick={()=>{navigate(-2)}}
          onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
          onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
                >حسابي</h6>
      
                <h6 style={{margin:"0px 6px",
            fontSize:"14px"
      
                }}>/</h6>
                <h6 style={{marginBottom:"0px", 
            color: "#01635c",
            cursor:"pointer",
            fontSize:"14px"
          }}
          onClick={()=>{navigate(-1)}}
          onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
          onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
                >منتجاتي</h6>
      
      <h6 style={{margin:"0px 6px",
            fontSize:"14px"
      
                }}>/</h6>
                <h6 
                          style={{marginBottom:"0px", 
                          maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
                          overflow: "hidden",  /* Hide any overflow content */
                          whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
                          textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
                        fontSize:"14px"}}
                >{productName}</h6>
              </div>
            </div>
      
      
      <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px", marginTop:"16px"}}>تفاصيل المنتج</h1>
      
      <h5 style={{fontWeight: "500", color: "#00A49B" }}><span style={{color:"red"}}>*</span>اختر التصنيف:</h5>
      
      
      <SelectableCategory selectedCategory={productCategory} onSelect={handleSelectCategory}>
        <div category="ساعات" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
          <img id='landing-screen-img-size' src={watch} alt="ساعات" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>ساعات</p>
        </div>
        <div category="سبح">
          <img id='landing-screen-img-size' src={sbhah} style={{ objectFit: "scale-down"}} alt="سبح" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>سبح</p>
        </div>
        <div category="اقلام">
          <img id='landing-screen-img-size' src={pen} style={{borderRadius: "1000px", objectFit: "scale-down", padding: "8px", backgroundColor:"#FAFAFA"}} alt="اقلام" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>أقلام</p>
        </div>
        <div category="ابواك">
          <img id='landing-screen-img-size' src={wallet} alt="ابواك" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>أبواك</p>
        </div>
        <div category="حقائب نسائية">
          <img id='landing-screen-img-size' src={purses} alt="حقائب نسائية" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>حقائب نسائية</p>
        </div>
        <div category="لوح سيارات">
          <img id='landing-screen-img-size' src={plateIcon} style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} alt="لوح سيارات" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>لوح سيارات</p>
        </div>
        <div category="ارقام جوالات">
          <img id='landing-screen-img-size' src={phoneNumberIcon} style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#FAFAFA"}} alt="ارقام جوالات" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>أرقام جوالات</p>
        </div>
      </SelectableCategory>
      </div>
      
      
      {
      
      productCategory === "ساعات" && (
      
        <>
      
      
      <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", paddingBottom: "32px", padding: window.innerWidth > window.innerHeight ? "auto" : "0px 16px" }}>
      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight: "100vh"}}>
                <div ref={watchDepartmentRef} id='watch-department-div' style={{paddingTop:"4px"}}>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>قسم الساعات:</h5>
                  <SelectableWatchCompany selectedCategory={watchDepartment} onSelect={handleSelectWatchDepartment}>
                  
                  <div  category="رجالي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                    <IoMdMale color='#00C8B8' style={{width:"80px", height:"80px"}}/>
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>رجالي</p>
                  </div>
      
                  <div  category="نسائي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                    <IoFemale color='#F00057' style={{width:"80px", height:"80px"}} />
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>نسائي</p>
                  </div>
      
      
                  <div  category="للجنسين" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                    <FaTransgender color='gray' style={{width:"80px", height:"80px"}} />
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>للجنسين</p>
                  </div>
                  </SelectableWatchCompany>
                </div>
              </div>
      
          <div id='watch-details-div' style={{paddingTop:"4px"}}>
            <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
      <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectWatchProductCompany}>
      
      <div category="Rolex">
          <img id='landing-screen-img-size' src={rolexLogo} style={{objectFit:"cover"}} alt="Rolex" />
        </div>
        <div category="Omega">
          <img id='landing-screen-img-size' src={omegaLogo} style={{objectFit:"scale-down"}} alt="Omega" />
        </div>
        <div category="Cartier">
          <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
        </div>
        <div category="Versace">
          <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
        </div>
        <div category="Montblanc">
          <img id='landing-screen-img-size' src={montBlancLogo} style={{objectFit:"scale-down"}} alt="Montblanc" />
        </div>
        <div category="Aigner">
          <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
        </div>
        <div category="Rado">
          <img id='landing-screen-img-size' src={radoLogo} style={{objectFit:"scale-down"}} alt="Rado" />
        </div>
        <div category="Givenchy">
          <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
        </div>
        <div category="Chopard">
          <img id='landing-screen-img-size' src={chopardLogo} style={{objectFit:"scale-down"}} alt="Chopard" />
        </div>
        <div category="Tissot">
          <img id='landing-screen-img-size' src={tissotLogo} style={{objectFit:"scale-down"}} alt="Tissot" />
        </div>
        <div category="Patek Philippe">
          <img id='landing-screen-img-size' src={patekPhilippeLogo} style={{objectFit:"scale-down"}} alt="Patek Philippe" />
        </div>
        <div category="Armani Exchange">
          <img id='landing-screen-img-size' src={armaniExchangeLogo} style={{objectFit:"scale-down"}} alt="Armani Exchange" />
        </div>
        <div category="G-Shock">
          <img id='landing-screen-img-size' src={gShockLogo} style={{objectFit:"scale-down"}} alt="G-Shock" />
        </div>
        <div category="Apple">
          <img id='landing-screen-img-size' src={appleLogo} style={{objectFit:"scale-down"}} alt="Apple" />
        </div>
        <div category="Huawei">
          <img id='landing-screen-img-size' src={huaweiLogo} style={{objectFit:"scale-down"}} alt="Huawei" />
        </div>
        <div category="Samsung">
          <img id='landing-screen-img-size' src={samsungLogo} style={{objectFit:"scale-down"}} alt="Samsung" />
        </div>
        <div category="Xiaomi">
          <img id='landing-screen-img-size' src={xiaomiLogo} style={{objectFit:"scale-down"}} alt="Xiaomi" />
        </div>
        <div category="Other" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أخرى</p>
        </div>
      </SelectableWatchCompany>
          </div>
      
      
      
      
          {
            productCompany !== "Apple" && productCompany !== "Huawei" && productCompany !== "Xiaomi" && productCompany !== "Samsung" && (
              <>
             
            
              <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight:"100vh" }}>
                <div ref={watchInsideColorRef} id='watch-insideColor-div' style={{paddingTop:"4px"}}>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون قرص الساعة الداخلي:</h5>
                  <SelectableWatchCompany selectedCategory={watchInsideColor} onSelect={handleSelectWatchInsideColor}>
                  
                    <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>
      
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>ستانلس ستيل</p>
                    </div>
      
                    <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
      </div>
      
      
      <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
      </div>
      
      
      <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
      </div>
      
      <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
      </div>
      
      <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
      </div>
      
      <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
      </div>
      
      <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
      </div>
      
      <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
      </div>
      
      <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
      </div>
      
      
      <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
      </div>
      
      <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
      </div>
      
      <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
      </div>
      
      
      <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
      </div>
      
      <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
      </div>
      
      <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
      </div>
      
                    <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
        </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
            
            
              <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",  minHeight:"100vh" }}>
                <div ref={watchOutsideColorRef} id='watch-outsideColor-div' style={{paddingTop:"4px"}}>
                  <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون قرص الساعة الخارجي:</h5>
                  <SelectableWatchCompany selectedCategory={watchOutsideColor} onSelect={handleSelectWatchOutsideColor}>
                  
                  <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>
      
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>ستانلس ستيل</p>
                    </div>
      
                    <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
      </div>
      
      
      <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
      </div>
      
      
      <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
      </div>
      
      <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
      </div>
      
      <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
      </div>
      
      <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
      </div>
      
      <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
      </div>
      
      <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
      </div>
      
      <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
      </div>
      
      
      <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
      </div>
      
      <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
      </div>
      
      <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
      </div>
      
      
      <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
      </div>
      
      <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
      </div>
      
      <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
      </div>
      
                    <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
        </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
      
      
      
      
      
      {/* dial shape */}
      
      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchDialShapeRef} id='watch-dialShape-div' style={{paddingTop:"8px"}}>
                  <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>شكل قرص الساعة:</h5>
                    <div>
                      {
                        watchDialShape && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchDialShape("")}}>إعادة تعيين</button>
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchDialShapeScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchDialShape} onSelect={handleSelectWatchDialShape}>
                  
                  <div  category="دائري" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <FaRegCircle color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>دائري</p>
      </div>
      
      
      
      <div  category="مربع" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <FaRegSquare color='#00C8B8' style={{width:"80px", height:"80px"}}/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>مربع</p>
      </div>
      
      
                  <div  category="مستطيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <PiRectangleBold color='#00C8B8' style={{width:"80px", height:"80px"}}/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>مستطيل</p>
      </div>
      
      
      
      <div  category="بيضاوي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <TbOval color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>بيضاوي</p>
      </div>
      
      
      <div  category="خماسي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <RiPentagonLine color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>خماسي</p>
      </div>
      
      <div  category="سداسي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <LuHexagon color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>سداسي</p>
      </div>
      
      
      
      
      
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
            
      
      
      
      
      
      
      
      {/* dial size  */}
      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchDialSizeRef} id='watch-dialSize-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>حجم قرص الساعة:</h5>
                    <div>
                      {
                        watchDialSize && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchDialSize("")}}>إعادة تعيين</button>
      
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchDialSizeScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchDialSize.toString()} onSelect={handleSelectWatchDialSize}>
                  
        <div  category="32" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>32</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>32 ملم</p>
        </div>
      
        <div  category="34" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>34</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>34 ملم</p>
        </div>
      
        <div  category="36" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>36</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>36 ملم</p>
        </div>
      
        <div  category="38" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>38</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>38 ملم</p>
        </div>
      
      
        <div  category="40" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>40</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>40 ملم</p>
        </div>
      
      
        <div  category="42" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>42</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>42 ملم</p>
        </div>
      
      
        <div  category="44" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>44</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>44 ملم</p>
        </div>
      
      
      
        <div  category="46" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>46</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>46 ملم</p>
        </div>
      
      
      
        <div  category="48" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>48</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>48 ملم</p>
        </div>
      
      
      
        <div  category="50" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <h1 style={{fontSize:"52px", fontWeight:"bold", color:"#00C8B8"}}>50</h1>
            </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>50 ملم</p>
        </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
      
      
      
      
      
        {/* watch handles color */}
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchHandlesColorRef} id='watch-handlesColor-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون عقارب الساعة:</h5>
                    <div>
                      {
                        watchHandlesColor && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchHandlesColor("")}}>إعادة تعيين</button>
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchHandlesColorScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchHandlesColor} onSelect={handleSelectWatchHandlesColor}>
                  
                  <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>
      
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>ستانلس ستيل</p>
                    </div>
      
                    <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
      </div>
      
      
      <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
      </div>
      
      
      <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
      </div>
      
      <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
      </div>
      
      <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
      </div>
      
      <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
      </div>
      
      <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
      </div>
      
      <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
      </div>
      
      <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
      </div>
      
      
      <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
      </div>
      
      <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
      </div>
      
      <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
      </div>
      
      
      <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
      </div>
      
      <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
      </div>
      
      <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
      </div>
      
                    <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
        </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
        
        
      
      
        
        
        {/* watch numbers color */}
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchNumbersColorRef} id='watch-numbersColor-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون أرقام الساعة:</h5>
                    <div>
                      {
                        watchNumbersColor && (
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchNumbersColor("")}}>إعادة تعيين</button>
      
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchNumbersColorScrollTrigger(true)}}>التالي</button>
      
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchNumbersColor} onSelect={handleSelectWatchNumbersColor}>
                  
                  <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <div style={{background: 'linear-gradient(to bottom right, #ccc, #fff)', width: '80%', height: '80%', borderRadius: 1000}}></div>
      
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>ستانلس ستيل</p>
                    </div>
      
                    <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
      </div>
      
      
      <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
      </div>
      
      
      <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
      </div>
      
      <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
      </div>
      
      <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
      </div>
      
      <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
      </div>
      
      <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
      </div>
      
      <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
      </div>
      
      <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
      </div>
      
      
      <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
      </div>
      
      <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
      </div>
      
      <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
      </div>
      
      
      <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
      </div>
      
      <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
      </div>
      
      <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
      </div>
      
                    <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
        </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
      
      
      
      
      
        {/* watch numbers language */}
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",minHeight:"100vh" }}>
                <div ref={watchNumbersLanguageRef} id='watch-handlesColor-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لغة أرقام الساعة:</h5>
                    <div>
                      {
                        watchNumbersLanguage && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchNumbersLanguage("")}}>إعادة تعيين</button>
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchNumbersLanguageScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchNumbersLanguage} onSelect={handleSelectWatchNumbersLanguage}>
                  
                  <div  category="الانجليزية" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                       <h1 style={{fontSize:"48px", fontWeight:"bold", color:"#00C8B8"}}>123</h1>
                    
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>الانجليزية</p>
                  </div>
                  
                  
                  <div  category="العربية" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                       <h1 style={{fontSize:"48px", fontWeight:"bold", color:"#00C8B8"}}>١٢٣</h1>
                    
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>العربية</p>
                  </div>
      
                  <div  category="الرومانية" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                       <h1 style={{fontSize:"48px", fontWeight:"bold", color:"#00C8B8", fontFamily: "serif", marginBottom:"0px"}}>XVII</h1>
                    
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>الرومانية</p>
                  </div>
      
                  <div  category="بدون أرقام" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                    
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"4px"}}>بدون أرقام</p>
                  </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
      
      
      
      
      
      
      {/* watch band material */}
      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchBandMaterialRef} id='watch-bandMaterial-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", width:"100%", marginBottom:"16px", justifyContent:"space-between"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px", whiteSpace:"break-spaces", wordWrap:"break-word", width:window.innerWidth > window.innerHeight ? "100%" : "50%"  }}>المادة المصنوع منها السوار:</h5>
                    <div style={{display:"flex", width:"fit-content",justifyContent:"flex-end"}}>
                      {
                        watchBandMaterial && (
                          <button id='my-button' style={{padding:"0px 16px", backgroundColor:"#F00057", whiteSpace:"nowrap", width:"fit-content" }} onClick={()=>{setWatchBandMaterial("")}}>إعادة تعيين</button>
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchBandMaterialScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchBandMaterial} onSelect={handleSelectWatchBandMaterial}>
                  
                  <div  category="ستانلس ستيل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <IoIosWatch color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
                  </div>
                  <p style={{marginBottom:"0px", marginTop:"4px"}}>ستانلس ستيل</p>
                  </div>
      
      
                  <div  category="جلد" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <IoIosWatch color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
                  </div>
                  <p style={{marginBottom:"0px", marginTop:"4px"}}>جلد</p>
                  </div>
      
      
                  <div  category="ربل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                  <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                  <IoIosWatch color='#00C8B8' style={{width:"80px", height:"80px"}} id='landing-screen-img-size'/>
                  </div>
                  <p style={{marginBottom:"0px", marginTop:"4px"}}>ربل</p>
                  </div>
      
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
      
      
        {/* watch band color */}
        {
          watchBandMaterial !== "ستانلس ستيل" && (
      
      
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",minHeight:"100vh" }}>
                <div ref={watchBandColorRef} id='watch-bandColor-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون سوار الساعة:</h5>
                    <div style={{display:"flex"}}>
                      {
                        watchBandColor && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchBandColor("")}}>إعادة تعيين</button>
      
                        )
                      }
      
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchBandColorScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchBandColor} onSelect={handleSelectWatchBandColor}>
                  
                    <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
      </div>
      
      
      <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
      </div>
      
      
      <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
      </div>
      
      <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
      </div>
      
      <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
      </div>
      
      <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
      </div>
      
      <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
      </div>
      
      <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
      </div>
      
      <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
      </div>
      
      
      <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
      </div>
      
      <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
      </div>
      
      <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
      </div>
      
      
      <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
      </div>
      
      <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
      </div>
      
      <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
          </div>
          <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
      </div>
      
                    <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
        </div>
      
      
                  </SelectableWatchCompany>
                </div>
              </div>
          )
        }
      
        
        {/* watch has box */}
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl",  minHeight:"100vh" }}>
                <div ref={watchHasOriginalPackagingRef} id='watch-hasOriginalPackaging-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>يوجد صندوق الساعة:</h5>
                    <div style={{display:"flex"}}>
                      {
                        watchHasOriginalPackaging !== "" && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchHasOriginalPackaging("")}}>إعادة تعيين</button>
      
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchHasOriginalPackagingScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchHasOriginalPackaging.toString()} onSelect={handleSelectWatchHasOriginalPackaging}>
                  
                  <div  category="true" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <IoMdCheckmark color='#00C8B8' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>نعم</p>
      </div>
      
      
                  <div  category="false" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <IoCloseSharp color='#F00057' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>لا</p>
      </div>
      
                  </SelectableWatchCompany>
                </div>
              </div>
      
      
        {/* watch is water resistant */}
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchIsWaterResistantRef} id='watch-isWaterResistant-div' style={{paddingTop:"8px"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>الساعة مقاومة للماء:</h5>
                    <div style={{display:"flex"}}>
                      {
                        watchIsWaterResistant !== "" && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchIsWaterResistant("")}}>إعادة تعيين</button>
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchIsWaterResistantScrollTrigger(true)}}>التالي</button>
                    </div>
                  </div>
                  <SelectableWatchCompany selectedCategory={watchIsWaterResistant.toString()} onSelect={handleSelectWatchIsWaterResistant}>
                  
                  <div  category="true" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <IoMdCheckmark color='#00C8B8' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>نعم</p>
      </div>
      
      
                  <div  category="false" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <IoCloseSharp color='#F00057' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
        </div>
        <p style={{marginBottom:"0px", marginTop:"4px"}}>لا</p>
      </div>
      
                  </SelectableWatchCompany>
                </div>
              </div>
        
        
        
      
      
      
      
      
      
      
      
        {/* watch year made */}
        <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                <div ref={watchYearMadeRef} id='watch-handlesColor-div' style={{paddingTop:"8px", display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>سنة الصنع:</h5>
                    <div style={{display:"flex"}}>
                      {
                        watchYearMade && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setWatchYearMade("")}}>إعادة تعيين</button>
                        )
                      }
                    <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setWatchYearMadeScrollTrigger(true)}}>التالي</button>
                    </div>
                </div>
                <div style={{marginTop:"16px"}}>
                <YearMade onSelect={handleSelectWatchYearMade} selectedYear={watchYearMade} />
                </div>
                </div>
              </div>
      
      
      
      
      
            </>
          )}
        </div>
      
      
          </>
      
        
      )
      }
      
      {
        productCategory === "سبح" && (
          <>
      
          <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", paddingBottom: "32px", padding: window.innerWidth > window.innerHeight ? "auto" : "0px 16px" }}>
      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight: "100vh"}}>
                  <div ref={rosaryKindRef} id='watch-department-div' style={{paddingTop:"4px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>نوع الخرز:</h5>
                    <SelectableWatchCompany selectedCategory={rosaryKind} onSelect={handleSelectRosaryKind}>
                    
                    <div  category="بكلايت" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>بكلايت</p>
                    </div>
                    
                    <div  category="كهرمان" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>كهرمان</p>
                    </div>
      
                    <div  category="فاتوران" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>فاتوران</p>
                    </div>
      
      
                    <div  category="عاج" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>عاج</p>
                    </div>
      
                    <div  category="خشب" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>خشب</p>
                    </div>
      
                    <div  category="حجر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                    <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <img style={{width: window.innerWidth > window.innerHeight ? "90px" : "70px", height:window.innerWidth > window.innerHeight ? "90px" : "70px", objectFit:"scale-down"}} src={rosaryIcon} alt="بكلايت" />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>حجر</p>
                    </div>
                    </SelectableWatchCompany>
                  </div>
                </div>
      </div>
      
      {
        rosaryKind && (
          <div style={{ width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
          <div ref={rosaryColorDivRef} id='watch-handlesColor-div' style={{paddingTop:"4px"}}>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
          <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون الخرز:</h5>
            </div>
            <SelectableWatchCompany selectedCategory={rosaryColor} onSelect={handleSelectRosaryColor}>
      
                      <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
        </div>
      
      
        <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
        </div>
      
      
        <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
        </div>
      
        <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
        </div>
      
        <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
        </div>
      
        <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
        </div>
      
        <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
        </div>
      
        <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
        </div>
      
        <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
        </div>
      
      
        <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
        </div>
      
        <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
        </div>
      
        <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
        </div>
      
        <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
        </div>
      
        <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
        </div>
      
        <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
        </div>
      
                      <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
            <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
            </div>
            <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
          </div>
      
      
            </SelectableWatchCompany>
          </div>
          {
            rosaryColor && (
      <div style={{minHeight:"100vh"}}>
                  <div ref={rosaryCountDivRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                      <div>
                        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>عدد الخرز:</h5>
                      </div>
                      {
                        rosaryCount !== "" && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                          onClick={()=>{setRosaryCountScrollTrigger(true)}}
                          >التالي</button>
                        )
                      }
                  </div>
                <div style={{display:"flex", alignItems:"center"}}>
                  <input ref={rosaryCountRef} 
                  defaultValue={rosaryCount}
      onChange={(e) => {
        const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
        // Check if the input value is empty
        if (inputValue === "") {
          setRosaryCount(""); // Clear the state
          return;
        }
      
        // Check if the input value contains non-numeric characters
        if (!/^\d*$/.test(inputValue)) {
          alert("يرجى ادخال أرقام فقط.");
          rosaryCountRef.current.value = ""; // Clear the input field
          return;
        }
      
        // Update the state with the valid number
        setRosaryCount(inputValue);
      }} type='tel' lang='en' placeholder='عدد الخرز' style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" : "100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right", marginTop:"8px"}}/>
                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>خرزة</p>
                </div>
      </div>
            )
          }
      
      
          {
            rosaryCount && (
      <div style={{minHeight:"100vh"}}>
                  <div ref={rosarySizeDivRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                      <div>
                        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}>مقاس الخرز:</h5>
                      </div>
                      {
                        rosaryCount && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                          onClick={()=>{setRosarySizeScrollTrigger(true)}}
                          >التالي</button>
                        )
                      }
                  </div>
                <div style={{display:"flex", alignItems:"center",  marginTop:"8px"}}>
                  <input defaultValue={rosarySize} ref={rosarySizeRef} onChange={(e) => {
        const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
        // Check if the input value is empty
        if (inputValue === "") {
          setRosarySize(""); // Clear the state
          return;
        }
      
        // Check if the input value contains non-numeric characters
        if (!/^\d*$/.test(inputValue)) {
          alert("يرجى ادخال أرقام فقط.");
          rosarySizeRef.current.value = ""; // Clear the input field
          return;
        }
      
        // Update the state with the valid number
        setRosarySize(inputValue);
      }} type='tel' lang='en' placeholder='مقاس الخرز' style={{backgroundColor:"#F0F0F0", border: "none", width: window.innerWidth > window.innerHeight ? "50%" :"100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
                  <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ملم</p>
                </div>
      </div>
            )
          }
        </div>
        )
      }
      
      
      </>
        )
      }
      
      {
        productCategory === "اقلام" && (
          <div id='watch-department-div' style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
              <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectPenProductCompany}>
        <div category="Rolex">
          <img id='landing-screen-img-size' src={rolexLogo} style={{objectFit:"cover"}} alt="Rolex" />
        </div>
        <div category="Omega">
          <img id='landing-screen-img-size' src={omegaLogo} style={{objectFit:"scale-down"}} alt="Omega" />
        </div>
        <div category="Cartier">
          <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
        </div>
        <div category="Versace">
          <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
        </div>
        <div category="Montblanc">
          <img id='landing-screen-img-size' src={montBlancLogo} style={{objectFit:"scale-down"}} alt="Montblanc" />
        </div>
        <div category="Aigner">
          <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
        </div>
        <div category="Rado">
          <img id='landing-screen-img-size' src={radoLogo} style={{objectFit:"scale-down"}} alt="Rado" />
        </div>
        <div category="Givenchy">
          <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
        </div>
        <div category="Chopard">
          <img id='landing-screen-img-size' src={chopardLogo} style={{objectFit:"scale-down"}} alt="Chopard" />
        </div>
        <div category="Tissot">
          <img id='landing-screen-img-size' src={tissotLogo} style={{objectFit:"scale-down"}} alt="Tissot" />
        </div>
        <div category="Patek Philippe">
          <img id='landing-screen-img-size' src={patekPhilippeLogo} style={{objectFit:"scale-down"}} alt="Patek Philippe" />
        </div>
      </SelectableWatchCompany>
      
            </div>
          </div>
        )
      }
      
      
      {
        productCategory === "ابواك" && (
          <>
          <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", paddingBottom: "32px", padding: window.innerWidth > window.innerHeight ? "auto" : "0px 16px" }}>
      <div style={{ width: "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: "0px", minHeight: "100vh"}}>
                  <div ref={watchDepartmentRef} id='watch-department-div' style={{paddingTop:"4px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>قسم البوك:</h5>
                    <SelectableWatchCompany selectedCategory={walletDepartment} onSelect={handleSelectWalletDepartment}>
                    
                    <div  category="رجالي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <IoMdMale color='#00C8B8' style={{width:"80px", height:"80px"}}/>
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>رجالي</p>
                    </div>
      
                    <div  category="نسائي" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <IoFemale color='#F00057' style={{width:"80px", height:"80px"}} />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>نسائي</p>
                    </div>
      
      
                    <div  category="للجنسين" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
                      <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                      <FaTransgender color='gray' style={{width:"80px", height:"80px"}} />
                      </div>
                      <p style={{marginBottom:"0px", marginTop:"4px"}}>للجنسين</p>
                    </div>
                    </SelectableWatchCompany>
                  </div>
                </div>
                </div>
      
      
      
      
      
      
      
                <div ref={walletCompanyRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
      <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectPenProductCompany}>
      <div category="Aigner">
          <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
        </div>
        <div category="Aldo">
          <img id='landing-screen-img-size' src={aldoLogo} style={{objectFit:"scale-down"}} alt="Aldo" />
        </div>
        <div category="Cartier">
          <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
        </div>
        <div category="Chanel">
          <img id='landing-screen-img-size' src={chanelLogo} style={{objectFit:"scale-down"}} alt="Chanel" />
        </div>
        <div category="Chopard">
          <img id='landing-screen-img-size' src={chopardLogo} style={{objectFit:"scale-down"}} alt="Chopard" />
        </div>
        <div category="Dior">
          <img id='landing-screen-img-size' src={diorLogo} style={{objectFit:"scale-down"}} alt="Dior" />
        </div>
        <div category="Dolce & Gabbana">
          <img id='landing-screen-img-size' src={DGLogo} style={{objectFit:"scale-down"}} alt="Dolce & Gabbana" />
        </div>
        <div category="Fred Perry">
          <img id='landing-screen-img-size' src={fredPerryLogo} style={{objectFit:"scale-down"}} alt="Fred Perry" />
        </div>
        <div category="Givenchy">
          <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
        </div>
        <div category="Gucci">
          <img id='landing-screen-img-size' src={gucciLogo} style={{objectFit:"scale-down"}} alt="Gucci" />
        </div>
        <div category="Louis Vuitton">
          <img id='landing-screen-img-size' src={lvLogo} style={{objectFit:"scale-down"}} alt="Louis Vuitton" />
        </div>
        <div category="Montblanc">
          <img id='landing-screen-img-size' src={montBlancLogo} style={{objectFit:"scale-down"}} alt="Montblanc" />
        </div>
        <div category="Omega">
          <img id='landing-screen-img-size' src={omegaLogo} style={{objectFit:"scale-down"}} alt="Omega" />
        </div>
        <div category="Rolex">
          <img id='landing-screen-img-size' src={rolexLogo} style={{objectFit:"cover"}} alt="Rolex" />
        </div>
        <div category="Saint Laurent">
          <img id='landing-screen-img-size' src={yslLogo} style={{objectFit:"scale-down"}} alt="Saint Laurent" />
        </div>
        <div category="Tom Ford">
          <img id='landing-screen-img-size' src={tomFordLogo} style={{objectFit:"scale-down"}} alt="Tom Ford" />
        </div>
        <div category="Tory Burch">
          <img id='landing-screen-img-size' src={toryBurchLogo} style={{objectFit:"scale-down"}} alt="Tory Burch" />
        </div>
        <div category="Versace">
          <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
        </div>
        <div category="Other" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أخرى</p>
        </div>
      
      </SelectableWatchCompany>
            </div>
          </div>
      
      
      
      
      
      
      
          <div style={{ width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px", minHeight:"100vh" }}>
                  <div ref={walletColorRef} id='watch-insideColor-div' style={{paddingTop:"4px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون البوك الخارجي:</h5>
                    <SelectableWatchCompany selectedCategory={walletColor} onSelect={handleSelectWalletColor}>
      
            
                    <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
        </div>
      
      
        <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
        </div>
      
      
        <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
        </div>
      
        <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
        </div>
      
        <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
        </div>
      
        <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
        </div>
      
        <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
        </div>
      
        <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
        </div>
      
        <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
        </div>
      
      
        <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
        </div>
      
        <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
        </div>
      
        <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
        </div>
      
        <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
        </div>
      
        <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
        </div>
      
        <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
        </div>
      
                      <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
            <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
            </div>
            <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
          </div>
      
      
            </SelectableWatchCompany>
                  </div>
                </div>
      
      
      
      
          </>
        )
      }
      
      
      {
        productCategory === "لوح سيارات" && (
          <>
          <div ref={carPlateKindRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>نوع اللوحة:</h5>
              <SelectableWatchCompany selectedCategory={carPlateKind} onSelect={handleSelectCarPlateKind}>
        
        
        <div category="لوحة سيارة خاصة" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <div  id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000, padding:"10px"}}>
            <img id='car-plate-img-size' src={carPlateWhite} alt="" />
          </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة سيارة خاصة</p>
        </div>
      
      
      
        <div category="لوحة دراجة نارية" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000}}>
        <img id='bike-plate-img-size' src={carPlateWhite} alt="" />
        </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة دراجة نارية</p>
        </div>
        <div category="لوحة سيارة نقل" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000, padding:"10px"}}>
        <img id='car-plate-img-size' src={carPlateYellow} alt="" />
        </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة سيارة نقل عام</p>
        </div>
        <div category="لوحة سيارة تجارية" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex", borderRadius:1000, padding:"10px"}}>
        <img id='car-plate-img-size' src={carPlateBlue} alt="" />
        </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>لوحة سيارة تجارية</p>
        </div>
      
      </SelectableWatchCompany>
            </div>
          </div>
      
      
      
      
          <div ref={carPlateTransferFeeOnRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>رسوم نقل الملكية:</h5>
              <SelectableCondition selectedCategory={carPlateTransferFeeOn} onSelect={handleSelectCarPlateTransferFeeOn}>
              <div category="البائع" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>على البائع<span style={{fontWeight:"400"}}>: عند بيع اللوحة, ستتكفل بدفع رسوم نقل الملكية للمشتري.</span></p>
                  </div>
                  <div category="المشتري" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>على المشتري<span style={{fontWeight:"400"}}>: عند شرائه للوحة, سيتكفل المشتري بدفع رسوم نقل الملكية اليه.</span></p>
                  </div>
                    </SelectableCondition>    
            </div>
          </div>
      
      
      
          <div ref={carPlateTransferTimeRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>أتعهد بنقل الملكية خلال:</h5>
      <SelectableWatchCompany selectedCategory={carPlateTransferTime} onSelect={handleSelectCarPlateTransferTime}>
      <div category="خلال يومين عمل" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <BsStopwatchFill color='#00C8B8' style={{width:"60px", height:"60px"}}/>
        </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>خلال يومين عمل</p>
        </div>
        <div category="خلال 5 أيام عمل" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaCalendarAlt color='#00C8B8' style={{width:"60px", height:"60px"}}/>
        </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>خلال 5 أيام عمل</p>
        </div>
      </SelectableWatchCompany>
            </div>
          </div>
      
      
      
      
      
      
          <div ref={carPlateNumbersRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "8px px" : "8px 16px" }}>
      
          <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                      <div>
                        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>حروف و أرقام اللوحة:</h5>
                      </div>
                      {
                        carPlateFirstArabicLetter && carPlateFirstArabicNumber && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                          onClick={()=>{setCarPlateNumbersScrollTrigger(true)}}
                          >التالي</button>
                        )
                      }
                  </div>
            <div style={{width: window.innerWidth > window.innerHeight ? "60%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", }}>
          <CarPlate kind={carPlateKind}
          firstArabicLetter={carPlateFirstArabicLetter}
          secondArabicLetter={carPlateSecondArabicLetter}
          thirdArabicLetter={carPlateThirdArabicLetter}
          firstArabicNumber={carPlateFirstArabicNumber}
          secondArabicNumber={carPlateSecondArabicNumber}
          thirdArabicNumber={carPlateThirdArabicNumber}
          fourthArabicNumber={carPlateFourthArabicNumber}
          firstEnglishLetter={carPlateFirstEnglishLetter}
          secondEnglishLetter={carPlateSecondEnglishLetter}
          thirdEnglishLetter={carPlateThirdEnglishLetter}
          firstEnglishNumber={carPlateFirstEnglishNumber}
          secondEnglishNumber={carPlateSecondEnglishNumber}
          thirdEnglishNumber={carPlateThirdEnglishNumber}
          fourthEnglishNumber={carPlateFourthEnglishNumber}
          setFirstArabicLetter={handleSetCarPlateFirstArabicLetter}
          setFirstEnglishLetter={handleSetCarPlateFirstEnglishLetter}
          setSecondArabicLetter={handleSetCarPlateSecondArabicLetter}
          setSecondEnglishLetter={handleSetCarPlateSecondEnglishLetter}
          setThirdArabicLetter={handleSetCarPlateThirdArabicLetter}
          setThirdEnglishLetter={handleSetCarPlateThirdEnglishLetter}
          setFirstArabicNumber={handleSetCarPlateFirstArabicNumber}
          setFirstEnglishNumber={handleSetCarPlateFirstEnglishNumber}
          setSecondArabicNumber={handleSetCarPlateSecondArabicNumber}
          setSecondEnglishNumber={handleSetCarPlateSecondEnglishNumber}
          setThirdArabicNumber={handleSetCarPlateThirdArabicNumber}
          setThirdEnglishNumber={handleSetCarPlateThirdEnglishNumber}  
          setFourthArabicNumber={handleSetCarPlateFourthArabicNumber}
          setFourthEnglishNumber={handleSetCarPlateFourthEnglishNumber}
          setPostImageUrl={handleSetCarPlatePostImage}
           />
          </div>
      
      </div>
      
          </>
        )
      }
      
      {
        productCategory === "حقائب نسائية" && (
          <>
      
          
      <div ref={purseCompanyRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>الشركة المصنعة:</h5>
      <SelectableWatchCompany selectedCategory={productCompany} onSelect={handleSelectPenProductCompany}>
      
      <div category="Aigner">
          <img id='landing-screen-img-size' src={aignerLogo} style={{objectFit:"scale-down"}} alt="Aigner" />
        </div>
        <div category="Aldo">
          <img id='landing-screen-img-size' src={aldoLogo} style={{objectFit:"scale-down"}} alt="Aldo" />
        </div>
        <div category="Balenciaga">
          <img id='landing-screen-img-size' src={balenciagaLogo} style={{objectFit:"scale-down"}} alt="Balenciaga" />
        </div>
        <div category="Cartier">
          <img id='landing-screen-img-size' src={cartierLogo} style={{objectFit:"scale-down"}} alt="Cartier" />
        </div>
        <div category="Chanel">
          <img id='landing-screen-img-size' src={chanelLogo} style={{objectFit:"scale-down"}} alt="Chanel" />
        </div>
        <div category="Charles & Keith">
          <img id='landing-screen-img-size' src={charlesKeithLogo} style={{objectFit:"scale-down"}} alt="Charles & Keith" />
        </div>
        <div category="Coach">
          <img id='landing-screen-img-size' src={coachLogo} style={{objectFit:"scale-down"}} alt="Coach" />
        </div>
        <div category="Dior">
          <img id='landing-screen-img-size' src={diorLogo} style={{objectFit:"scale-down"}} alt="Dior" />
        </div>
        <div category="Dolce & Gabbana">
          <img id='landing-screen-img-size' src={DGLogo} style={{objectFit:"scale-down"}} alt="Dolce & Gabbana" />
        </div>
        <div category="Givenchy">
          <img id='landing-screen-img-size' src={givenchyLogo} style={{objectFit:"scale-down"}} alt="Givenchy" />
        </div>
        <div category="Gucci">
          <img id='landing-screen-img-size' src={gucciLogo} style={{objectFit:"scale-down"}} alt="Gucci" />
        </div>
        <div category="Guess">
          <img id='landing-screen-img-size' src={guessLogo} style={{objectFit:"scale-down"}} alt="Guess" />
        </div>
        <div category="Louis Vuitton">
          <img id='landing-screen-img-size' src={lvLogo} style={{objectFit:"scale-down"}} alt="Louis Vuitton" />
        </div>
        <div category="Michael Kors">
          <img id='landing-screen-img-size' src={michaelKorsLogo} style={{objectFit:"scale-down"}} alt="Michael Kors" />
        </div>
        <div category="Off-White">
          <img id='landing-screen-img-size' src={offWhiteLogo} style={{objectFit:"scale-down"}} alt="Off-White" />
        </div>
        <div category="Prada">
          <img id='landing-screen-img-size' src={pradaLogo} style={{objectFit:"scale-down"}} alt="Prada" />
        </div>
        <div category="Saint Laurent">
          <img id='landing-screen-img-size' src={yslLogo} style={{objectFit:"scale-down"}} alt="Saint Laurent" />
        </div>
        <div category="Ted Baker">
          <img id='landing-screen-img-size' src={tedBakerLogo} style={{objectFit:"scale-down"}} alt="Ted Baker" />
        </div>
        <div category="Tory Burch">
          <img id='landing-screen-img-size' src={toryBurchLogo} style={{objectFit:"scale-down"}} alt="Tory Burch" />
        </div>
        <div category="Versace">
          <img id='landing-screen-img-size' src={versaceLogo} style={{objectFit:"scale-down"}} alt="Versace" />
        </div>
        <div category="Other" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop: "4px" , color:"#01635c"}}>أخرى</p>
        </div>
      
      </SelectableWatchCompany>
            </div>
          </div>
      
      
      
      
      
      
          <div ref={purseMaterialRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>المادة المصنوعة منها الحقيبة:</h5>
      <SelectableWatchCompany selectedCategory={purseMaterial} onSelect={handleSelectPurseMaterial}>
      <div  category="جلد" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <RiShoppingBagLine color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>جلد</p>
        </div>
      <div  category="مخمل" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <RiShoppingBagLine color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>مخمل</p>
        </div>
      </SelectableWatchCompany>
            </div>
          </div>
      
      
          <div ref={purseOutsideColorRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>لون الحقيبة الخارجي:</h5>
              <SelectableWatchCompany selectedCategory={purseOutsideColor} onSelect={handleSelectPurseOutsideColor}>
              
              
              <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
        </div>
      
      
        <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
        </div>
      
      
        <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
        </div>
      
        <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
        </div>
      
        <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
        </div>
      
        <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
        </div>
      
        <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
        </div>
      
        <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
        </div>
      
        <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
        </div>
      
      
        <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
        </div>
      
        <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
        </div>
      
        <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
        </div>
      
      
      
        <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
        </div>
      
        <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
        </div>
      
        <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
        </div>
      
                      <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
            <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
            </div>
            <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
          </div>
      
      
      
                    </SelectableWatchCompany>
            </div>
          </div>
      
      
      
      
          <div ref={purseInsideColorRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
       <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px"}}>
                      <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "8px" }}>لون الحقيبة الداخلي:</h5>
                      <div style={{display:"flex"}}>
                        {
                          purseInsideColor !== "" && (
                            <button id='my-button' style={{width:"fit-content", padding:"0px 16px", backgroundColor:"#F00057"}} onClick={()=>{setPurseInsideColor("")}}>إعادة تعيين</button>
                          )
                        }
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginRight:"8px"}} onClick={()=>{setPurseInsideColorScrollTrigger(true)}}>التالي</button>
                      </div>
                    </div>
              <SelectableWatchCompany selectedCategory={purseInsideColor} onSelect={handleSelectPurseInsideColor}>
                    
      
                    
              <div category="أسود" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, black, #444, black)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أسود</p>
        </div>
      
      
        <div category="أبيض" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #f7f7f7, #FFFFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أبيض</p>
        </div>
      
      
        <div category="أحمر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FF0000, #CC0000, #FF0000)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أحمر</p>
        </div>
      
        <div category="أزرق" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #0000FF, #0000CC, #0000FF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أزرق</p>
        </div>
      
        <div category="أخضر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FF00, #00CC00, #00FF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أخضر</p>
        </div>
      
        <div category="أصفر" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFFF00, #CCCC00, #FFFF00)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>أصفر</p>
        </div>
      
        <div category="برتقالي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFA500, #FF8C00, #FFA500)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>برتقالي</p>
        </div>
      
        <div category="بنفسجي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #800080, #9400D3, #800080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بنفسجي</p>
        </div>
      
        <div category="وردي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #FFC0CB, #FF69B4, #FFC0CB)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>وردي</p>
        </div>
      
      
        <div category="بني" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #8B4513, #A0522D, #8B4513)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بني</p>
        </div>
      
        <div category="رمادي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #808080, #A9A9A9, #808080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>رمادي</p>
        </div>
      
        <div category="زيتي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #556B2F, #3B5323, #556B2F)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>زيتي</p>
        </div>
      
      
        <div category="سماوي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #00FFFF, #00CED1, #00FFFF)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>سماوي</p>
        </div>
      
        <div category="كحلي" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #000080, #000066, #000080)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>كحلي</p>
        </div>
      
        <div category="بيج" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div id='landing-screen-img-size' style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div style={{ background: 'linear-gradient(to bottom right, #F5F5DC, #FFFFE0, #F5F5DC)', width: '80%', height: '80%', borderRadius: 1000 }}></div>
            </div>
            <p style={{ marginBottom: "0px", marginTop: "4px" }}>بيج</p>
        </div>
      
                      <div  category="آخر" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
            <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
            <FaQuestion color='#00C8B8' style={{width:"55px", height:"55px"}} id='landing-screen-img-size'/>
            </div>
            <p style={{marginBottom:"0px", marginTop:"4px"}}>آخر</p>
          </div>
      
      
      
                    </SelectableWatchCompany>
            </div>
          </div>
      
      
      
      
          </>
        )
      }
      
      {
        productCategory === "ارقام جوالات" && (
          <>
          
      <div ref={phoneNumberCourierDivRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
       <div style={{paddingTop:"4px"}}>
              <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>مشغل الخدمة:</h5>
      <SelectableWatchCompany selectedCategory={phoneNumberCourier} onSelect={handleSelectPhoneCourier}>
        <div category="STC">
          <img id='landing-screen-img-size' src={stcLogo} style={{objectFit:"scale-down"}} alt="الاتصالات السعودية STC" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>الاتصالات السعودية STC</p>
        </div>
        <div category="موبايلي">
          <img id='landing-screen-img-size' src={mobilyLogo} style={{objectFit:"scale-down"}} alt="موبايلي" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>موبايلي</p>
        </div>
        <div category="زين">
          <img id='landing-screen-img-size' src={zainLogo} style={{objectFit:"scale-down"}} alt="زين" />
          <p style={{marginBottom:"0px", marginTop:"4px"}}>زين</p>
        </div>
      </SelectableWatchCompany>
            </div>
          </div>
          
      
      <div ref={phoneNumberToSellDivRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
      <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"16px", paddingTop:"8px"}}>
                      <h5 style={{ fontWeight: "500", color: "#00A49B" }}>رقم الجوال:</h5>
                      <div>
                        {
                          phoneNumberToSell.length === 10 && (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} onClick={()=>{setPhoneNumberToSellScrollTrigger(true)}}>التالي</button>
                          )
                        }
                      </div>
                    </div>
      
            <PhoneNumber 
          setPostImageUrl={handleSetPhoneNumberPostImage}
       phoneNumber={phoneNumberToSell} setPhoneNumber={handleSetPhoneNumber} />
      
          </div>
      
      
          </>
        )
      }
      
      
      {
        productCategory !== "" && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" &&  (
          
      <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px" }}>
                  <div ref={productConditionRef} style={{paddingTop:"4px"}}>
                    <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>حالة المنتج:</h5>
                    <SelectableCondition selectedCategory={productCondition} onSelect={handleSelectProductCondition}>
                      <div category="جديد" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>جديد<span style={{fontWeight:"400", lineHeight:1.7}}>: المنتج لم يتم استخدامه من قبل و
       يأتي مع كافة الملحقات والمكونات اللازمة و التغليف الاصلي من الشركة المصنعة
      </span></p>
                      </div>
                      <div category="مستعمل" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>مستعمل<span style={{fontWeight:"400"}}>: المنتج تم استخدامه من قبل و قد يكون مزودا بملحقاته الاساسية مثل التغليف الاصلي ولا يحتوي على خدوش او اي عيوب تمنع المنتج من استخدامه 
      بشكل سليم
      </span></p>
                      </div>
                      <div category="ممتازة" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>ممتازة<span style={{fontWeight:"400"}}>: المنتج يعمل بشكل سليم و قد يحتوي على بعض الخدوش التي لا تعيق استخدام المنتج</span></p>
                      </div>
                      <div category="جيدة جدا" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>جيدة جدا<span style={{fontWeight:"400"}}>: يحتوي المنتج على بعض الخدوش و لكنه نظيف و قابل للاستخدام بشكل سليم </span></p>
                      </div>
                      <div category="جيدة" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>جيدة<span style={{fontWeight:"400"}}>: يحتوي المنتج على خدوش و بعض الكسور التي لا تعيق استخدامه </span></p>
                      </div>
                      <div category="مقبولة" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>مقبولة<span style={{fontWeight:"400"}}>: يحتوي المنتج على خدوش و كسور التي قد تعليق استخدام المنتج ولكنه يعمل </span></p>
                      </div>
                    </SelectableCondition>           
                  </div>
                </div>
      
        )
      }
      
          <div style={{padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
      
      
      
      <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                  <div ref={postTypeRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                      <div>
                        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>طريقة عرض المنتج:</h5>
                        <h6 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}>يمكنك اختيار أكثر من طريقة لعرض منتجك</h6>
                      </div>
                      {
                        postType.length > 0 && (
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px", marginTop:"8px"}} 
                          onClick={()=>{setPostTypeScrollTrigger(true)}}
                          >التالي</button>
                        )
                      }
                  </div>
                  {/* <div ref={isAuctionRef} style={{paddingTop:"4px"}}>
                    <SelectableWatchCompany selectedCategory={isAuction} onSelect={handleSelectIsAuction}>
                    
                    <div  category="true" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <IoMdCheckmark color='#00C8B8' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>نعم</p>
        </div>
      
      
                    <div  category="false" style={{alignItems:"center", display:"flex", flexDirection:"column"}}>
          <div id='landing-screen-img-size' style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
          <IoCloseSharp color='#F00057' style={{width:"70px", height:"70px"}} id='landing-screen-img-size'/>
          </div>
          <p style={{marginBottom:"0px", marginTop:"4px"}}>لا</p>
        </div>
      
                    </SelectableWatchCompany>
                  </div> */}
      
      
                  <PostType onSelect={handleSelectPostType} selectedCategories={postType}>
                  <div category="شراء فوري" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>شراء فوري<span style={{fontWeight:"400"}}>: سيكون هناك سعر لشراء منتجك بشكل فوري حتى لو عرضته للمزاد. اذا اخترت ان تعرض منتجك للمزاد, سيكون هذا السعر هو سقف المزاد و سينتهي عند وصوله لهذا السعر. </span></p>
                  </div>
                  <div category="مزاد" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>مزاد<span style={{fontWeight:"400"}}>
                          : سيتم عرض المنتج للمزاد و يمكن لمن يدفع عربون دخول المزاد أن يقوم بالمزايدة عليه.
                          <br />
                        <li>
                          لا يمكنك تعديل منتج تمت المزايدة عليه
                        </li>
                          </span></p>
                  </div>
                  {
                    postType.includes("شراء فوري") && (
                  <div category="عروض" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                      <p style={{marginBottom:"0px", fontWeight:"500"}}>قابل للتفاوض<span style={{fontWeight:"400"}}>: ستتلقى عروض سعر من قبل مشترين محتملين و ستتمكن من قبول أو رفض العروض.</span></p>
                </div>
                    )
                  }
                  </PostType>
                </div>
      
                  {
                    postType.includes("مزاد") && (
                      <>
                        <div ref={productExpiryDateRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                  <div ref={productExpiryDateRef} style={{display:"flex", justifyContent:"space-between", width:"100%", paddingTop:"8px"}}>
                      <div>
                        <h5 style={{ fontWeight: "500", color: "#00A49B", marginTop: "16px" }}><span style={{color:"red"}}>*</span>تاريخ انتهاء المزاد:</h5>
                      </div>
                      {
                        productExpiryDate && ( 
                          <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                          onClick={()=>{setProductExpiryDateScrollTrigger(true)}}
                          >التالي</button>
                        )
                      }
                  </div>
                  <Datetime
                    className='custom-datetime-picker'
                    {...customInputProps}
                    locale="ar" // Set locale to Arabic for react-datetime
                    inputProps={{ ...customInputProps.inputProps, placeholder: 'تاريخ' }} // Add placeholder in Arabic
                    dateFormat="DD/MM/YYYY" // Set date format
                    timeFormat="hh:mm A" // Set time format in 12-hour format with AM/PM
                    onChange={(e)=>{setProductExpiryDate(e._d)}}
                    // initialViewDate={""}
                    initialValue={productExpiryDate instanceof Timestamp ? new Date(productExpiryDate.toDate()).getTime() : productExpiryDate }
                  />
                  </div>
                      </>
                    )
                  }
      
      
      {
      postType.length > 0 && (
      <>
      
      
                <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh" }}>
                  <div ref={productPricingRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <div style={{marginTop:"8px"}}>
                      <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>تسعير المنتج</h1>
                    </div>
                   
                  
                  {
                    postType.length === 1 && postType.includes("شراء فوري") && productPrice !== "" ? (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px",marginTop:"8px"}} 
                          onClick={()=>{setProductPricingScrollTrigger(true)}}
                          >التالي</button>
                    ) : ""
                  }
                   
                  {
                    postType.length === 1 && postType.includes("مزاد") && entryFee !== "" && startingPrice !== "" && bidIncrements !== "" ? (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                          onClick={()=>{setProductPricingScrollTrigger(true)}}
                          >التالي</button>
                    ) : ""
                  }
                  {
                    postType.length === 2 && postType.includes("شراء فوري") && postType.includes("عروض") && productPrice !== ""  ? (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                          onClick={()=>{setProductPricingScrollTrigger(true)}}
                          >التالي</button>
                    ) : ""
                  }
                   
                  
                  {
                    postType.length > 1 && postType.includes("مزاد") && postType.includes("شراء فوري")  && productPrice && entryFee && startingPrice && bidIncrements ? (
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                          onClick={()=>{setProductPricingScrollTrigger(true)}}
                          >التالي</button>
                    ) : ""
                  }
      
                  </div>
      
                  {
                    postType.length === 1 && postType.includes("شراء فوري") && (
                    <>
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> سعر المنتج:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
                      defaultValue={productPrice}
        onChange={(e) => {
          const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
          // Check if the input value is empty
          if (inputValue === "") {
            setProductPrice(""); // Clear the state
            return;
          }
      
          // Check if the input value contains non-numeric characters
          if (!/^\d*$/.test(inputValue)) {
            alert("يرجى ادخال أرقام فقط.");
            productPriceRef.current.value = ""; // Clear the input field
            return;
          }
      
          // Update the state with the valid number
          setProductPrice(inputValue);
        }}
        type='tel'
        lang='en'
        ref={productPriceRef}
        placeholder='سعر المنتج'
        style={{
          backgroundColor: "#F0F0F0",
          border: "none",
          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
          height: "50px",
          borderRadius: "8px",
          outline: "none",
          padding: "8px 16px",
          textAlign: "right"
        }}
      />
      
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                    </>
                    )
                  }
      
                  {
                    postType.length === 2 && postType.includes("شراء فوري") && postType.includes("عروض") &&(
                    <>
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> سعر المنتج:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
                      defaultValue={productPrice}
        onChange={(e) => {
          const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
          // Check if the input value is empty
          if (inputValue === "") {
            setProductPrice(""); // Clear the state
            return;
          }
      
          // Check if the input value contains non-numeric characters
          if (!/^\d*$/.test(inputValue)) {
            alert("يرجى ادخال أرقام فقط.");
            productPriceRef.current.value = ""; // Clear the input field
            setProductPrice("")
            return;
          }
      
          // Update the state with the valid number
          setProductPrice(inputValue);
        }}
        type='tel'
        lang='en'
        ref={productPriceRef}
        placeholder='سعر المنتج'
        style={{
          backgroundColor: "#F0F0F0",
          border: "none",
          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
          height: "50px",
          borderRadius: "8px",
          outline: "none",
          padding: "8px 16px",
          textAlign: "right"
        }}
      />
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                    </>
                    )
                  }
      
                  {
                    postType.includes("شراء فوري") && postType.includes("مزاد") &&(
                    <div >
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> سعر الشراء الفوري:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
                      defaultValue={productPrice || ""}
        onChange={(e) => {
          const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
          // Check if the input value is empty
          if (inputValue === "") {
            setProductPrice(""); // Clear the state
            return;
          }
      
          // Check if the input value contains non-numeric characters
          if (!/^\d*$/.test(inputValue)) {
            alert("يرجى ادخال أرقام فقط.");
            productPriceRef.current.value = ""; // Clear the input field
            setProductPrice("")
            return;
          }
      
          // Update the state with the valid number
          setProductPrice(inputValue);
        }}
        type='tel'
        lang='en'
        ref={productPriceRef}
        placeholder='سعر الشراء الفوري'
        style={{
          backgroundColor: "#F0F0F0",
          border: "none",
          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
          height: "50px",
          borderRadius: "8px",
          outline: "none",
          padding: "8px 16px",
          textAlign: "right"
        }}
      />
      
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                    </div>
                    )
                  }
      
                  {
                    postType.includes("مزاد") && (
                    <>
                    
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span> عربون دخول المزاد:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
                      defaultValue={entryFee}
      onChange={(e) => {
        const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
        // Check if the input value is empty
        if (inputValue === "") {
          setEntryFee(""); // Clear the state
          return;
        }
      
        if(parseFloat(inputValue) === 0){
          alert("يجب ان تكون القيمة اعلى من 0")
          entryFeeRef.current.value = ""; // Clear the input field
          setEntryFee(""); // Clear the state
          return;
        }
      
        if(productPrice !== "" && (parseFloat(inputValue) >= parseFloat(productPrice))){
          alert(`يجب ان تكون القيمة اقل من سعر الشراء الفوري (${formatAccountingArabic(parseFloat(productPrice))})`)
          entryFeeRef.current.value = ""; // Clear the input field
          setEntryFee(""); // Clear the state
          return;
        }
      
        // Check for non-numeric characters (excluding decimal point)
        if (!/^[0-9.]*$/.test(inputValue)) {
          alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
          entryFeeRef.current.value = ""; // Clear the input field
          setEntryFee(""); // Clear the state
          return;
        }
      
        // Check if the input is a valid number with optional decimal
        if (isNaN(parseFloat(inputValue))) {
          alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
          entryFeeRef.current.value = ""; // Clear the input field
          setEntryFee(""); // Clear the state
          return;
        }
      
        // Round the weight to two decimal places using toFixed
        const roundedPrice = parseFloat(inputValue).toFixed(2);
      
        // Update the state with the rounded weight
        setEntryFee(roundedPrice);
      }}
        type='tel'
        lang='en'
        ref={entryFeeRef}
        placeholder='عربون دخول المزاد'
        style={{
          backgroundColor: "#F0F0F0",
          border: "none",
          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
          height: "50px",
          borderRadius: "8px",
          outline: "none",
          padding: "8px 16px",
          textAlign: "right"
        }}
      />
      
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                      
                      <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: window.innerWidth > window.innerHeight ? "50%" : "100%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
                        <MdInfo size={20} color='#a0a0a0' />
                        <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>المبلغ الذي سيتم دفعه للتمكن من دخول المزاد. أقل مبلغ هو 10 ريال.</p>
                      </div> 
                    
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>مبلغ افتتاح المزاد:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
                      defaultValue={startingPrice}
                      onChange={(e) => {
                        const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
                      
                        // Check if the input value is empty
                        if (inputValue === "") {
                          setStartingPrice(""); // Clear the state
                          return;
                        }
                      
                        if(parseFloat(inputValue) === 0){
                          alert("يجب ان تكون القيمة اعلى من 0")
                          startingPriceRef.current.value = ""; // Clear the input field
                          setStartingPrice(""); // Clear the state
                          return;
                        }
                      
                        if(productPrice !== "" && (parseFloat(inputValue) >= parseFloat(productPrice))){
                          alert(`يجب ان تكون القيمة اقل من سعر الشراء الفوري (${formatAccountingArabic(parseFloat(productPrice))})`)
                          startingPriceRef.current.value = ""; // Clear the input field
                          setStartingPrice(""); // Clear the state
                          return;
                        }
                      
                        // Check for non-numeric characters (excluding decimal point)
                        if (!/^[0-9.]*$/.test(inputValue)) {
                          alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
                          startingPriceRef.current.value = ""; // Clear the input field
                          setStartingPrice(""); // Clear the state
                      
                          return;
                        }
                      
                        // Check if the input is a valid number with optional decimal
                        if (isNaN(parseFloat(inputValue))) {
                          alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
                          startingPriceRef.current.value = ""; // Clear the input field
                          setStartingPrice(""); // Clear the state
                      
                          return;
                        }
                      
                        // Round the weight to two decimal places using toFixed
                        const roundedPrice = parseFloat(inputValue).toFixed(2);
                      
                        // Update the state with the rounded weight
                        setStartingPrice(roundedPrice);
                      }}
                        type='tel'
                        lang='en'
                        ref={startingPriceRef}
                        placeholder='مبلغ افتتاح المزاد'
                        style={{
                          backgroundColor: "#F0F0F0",
                          border: "none",
                          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                          height: "50px",
                          borderRadius: "8px",
                          outline: "none",
                          padding: "8px 16px",
                          textAlign: "right"
                        }}
      />
      
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                      <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: window.innerWidth > window.innerHeight ? "50%" : "100%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
                        <MdInfo size={20} color='#a0a0a0' />
                        <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>المزاد يبدأ من هذا المبلغ. أقل مبلغ هو 10 ريال.</p>
                      </div> 
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>مضاعفات المزايدة:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
                      defaultValue={bidIncrements}
                      onChange={(e) => {
                        const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
                      
                        // Check if the input value is empty
                        if (inputValue === "") {
                          setBidIncrements(""); // Clear the state
                          return;
                        }
                      
                          if(parseFloat(inputValue) === 0){
                          alert("يجب ان تكون القيمة اعلى من 0")
                          bidIncrementRef.current.value = ""; // Clear the input field
                          setBidIncrements(""); // Clear the state
                          return;
                          }
                      
                            if(productPrice !== "" && (parseFloat(inputValue) >= parseFloat(productPrice))){
                          alert(`يجب ان تكون القيمة اقل من سعر الشراء الفوري (${formatAccountingArabic(parseFloat(productPrice))})`)
                          bidIncrementRef.current.value = ""; // Clear the input field
                          setBidIncrements(""); // Clear the state
                          return;
                        }
                      
                        // Check for non-numeric characters (excluding decimal point)
                        if (!/^[0-9.]*$/.test(inputValue)) {
                          alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
                          bidIncrementRef.current.value = ""; // Clear the input field
                          setBidIncrements(""); // Clear the state
                          return;
                        }
                      
                        // Check if the input is a valid number with optional decimal
                        if (isNaN(parseFloat(inputValue))) {
                          alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
                          bidIncrementRef.current.value = ""; // Clear the input field
                          setBidIncrements(""); // Clear the state
                      
                          return;
                        }
                      
                        // Round the weight to two decimal places using toFixed
                        const roundedPrice = parseFloat(inputValue).toFixed(2);
                      
                        // Update the state with the rounded weight
                        setBidIncrements(roundedPrice);
                      }}
                        type='tel'
                        lang='en'
                        ref={bidIncrementRef}
                        placeholder='مضاعفات المزايدة'
                        style={{
                          backgroundColor: "#F0F0F0",
                          border: "none",
                          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
                          height: "50px",
                          borderRadius: "8px",
                          outline: "none",
                          padding: "8px 16px",
                          textAlign: "right"
                        }}
      />
      
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                    <div style={{ display: "flex", backgroundColor: "#f7f7f7", width: window.innerWidth > window.innerHeight ? "50%" : "100%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px" }}>
                      <MdInfo size={20} color='#a0a0a0' />
                      <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%", marginBottom: "0px" }}>
                        هذا المبلغ سيكون مضاعفات كل مزايدة، مثال:<br />
                        <span>إذا كانت المضاعفات 50، ستكون المزايدات على منتجك مضاعفات 50 (50، 100، 150...). أقل مبلغ هو 10 ريال.</span>
                      </p>
                    </div>
      
                    </>)
                  }
      
                  {/* {
                    postType.includes("عروض") && (<>
                      <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span> الحد الأدنى للعروض:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                        <input type='tel' lang='en' ref={productPriceRef} placeholder='الحد الأدنى للعروض' style={{backgroundColor:"#F0F0F0", border: "none", width:"50%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>ر.س</p>
                      </div>
                      <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: "50%", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
                        <MdInfo size={20} color='#a0a0a0' />
                        <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>لا يمكن ارسال عرض سعر أقل من هذا المبلغ. </p>
                      </div> 
                    </>)
                  } */}
      
      
                </div>
      
      </>
      ) 
      }
          </div>
      
      
          {
      productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && isBusiness && !postType.includes("مزاد") && (
      <>
      
      
                <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding: window.innerWidth > window.innerHeight ? "8px 0px" : "8px 16px" }}>
                  <div ref={productQtyDivRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <div style={{marginTop:"8px"}}>
                      <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>الكمية</h1>
                    </div>
                   
                  
                      <button id='my-button' style={{width:"fit-content", padding:"0px 16px",marginTop:"8px"}} 
                          onClick={()=>{setProductQtyScrollTrigger(true)}}
                          >التالي</button>
      
                  </div>
      
      
                    <h5 style={{fontWeight: "500", color: "#00A49B", margin:"14px 0px" }}><span style={{color:"red"}}>*</span>الكمية:</h5>
                      <div style={{display:"flex", alignItems:"center"}}>
                      <input
      onChange={(e) => {
        const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace
      
        // Check if the input value is empty
        if (inputValue === "") {
          setProductQty(""); // Clear the state
          return;
        }
      
        if(parseFloat(inputValue) === 0){
          alert("يجب ان تكون القيمة اعلى من 0")
          productQtyRef.current.value = ""; // Clear the input field
          setProductQty(""); // Clear the state
          return;
        }
      
        // Check for non-numeric characters (excluding decimal point)
        if (!/^[0-9.]*$/.test(inputValue)) {
          alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
          productQtyRef.current.value = ""; // Clear the input field
          setProductQty(""); // Clear the state
          return;
        }
      
        // Check if the input is a valid number with optional decimal
        if (isNaN(parseFloat(inputValue))) {
          alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
          productQtyRef.current.value = ""; // Clear the input field
          setProductQty(""); // Clear the state
          return;
        }
      
        // Round the weight to two decimal places using toFixed
        const roundedPrice = parseFloat(inputValue);
      
        // Update the state with the rounded weight
        setProductQty(roundedPrice);
      }}
      
        type='tel'
        lang='en'
        ref={productPriceRef}
        defaultValue={productQty}
        placeholder='الكمية'
        style={{
          backgroundColor: "#F0F0F0",
          border: "none",
          width: window.innerWidth > window.innerHeight ? "50%" : "100%",
          height: "50px",
          borderRadius: "8px",
          outline: "none",
          padding: "8px 16px",
          textAlign: "right"
        }}
      />
      
                        <p style={{marginBottom:"0px", marginRight:"8px", fontWeight:500}}>وحدة</p>
                      </div>
                </div>
      
      </>
      ) 
      }
      {
        productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
          
      <div ref={productImagesRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding:window.innerWidth > window.innerHeight ? "0px" : "8px 16px"}}>
                  <div  style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <div>
                      <div style={{display:"flex"}}>
                      <span style={{color:"red", fontSize:"16px", fontWeight:"bold"}}>*</span>
                      <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>صور المنتج </h1>
                      </div>
                      <h6 style={{ fontWeight: "500", color: "#00A49B"}}>يمكنك اختيار من 3 الى 10 صور</h6>
                    </div>
                    {
                      postImages.length > 2 && (
                        <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                          onClick={()=>{setProductImagesScrollTrigger(true)}}
                          >التالي</button>
                      )
                    }
                  </div>
                  <ImageCropper
          imagess={postImages}
          setImages={handleImagesChange}
      />
      </div>
      
        )
      }
      
      
      <div ref={productInfoRef} style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh",padding:window.innerWidth > window.innerHeight ? "0px" : "8px 16px", paddingBottom:"32px" }}>
                  <div  style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <div>
                      <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>معلومات المنتج</h1>
                      <h6 style={{ fontWeight: "500", color: "#00A49B"}}>احرص على كتابة كلمات مفتاحية لتعزيز فرص ظهور منتجك في قوائم البحث</h6>
                    </div>
                    {
                      productName.length > 0 && productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" &&(
                        <button id='my-button' style={{width:"fit-content", padding:"0px 16px"}} 
                          onClick={()=>{setProductInfoScrollTrigger(true)}}
                          >التالي</button>
                      )
                    }
                  </div>
      
                    <div style={{display:"flex",flexDirection: window.innerWidth > window.innerHeight ? "row" : "column",justifyContent:"space-between"}}>
                    <div style={{width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>
                      
                  <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>اسم المنتج:</h5>
                        <input defaultValue={productName} onChange={(e)=>{setProductName(e.target.value)}} type='text' lang='en' ref={productNameRef} placeholder='اسم المنتج' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
                  <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}>وصف المنتج:</h5>
                        <textarea defaultValue={productDescription} onChange={(e)=>{setProductDescription(e.target.value)}} ref={productDescriptionRef} placeholder='وصف المنتج (اختياري)' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "120px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right",}}/>
                    </div>
                    {
        postImages.length > 0 && postType.length > 0 && (
          postType.includes("مزاد") && productExpiryDate !== "" ? (
            <div style={{width:window.innerWidth > window.innerHeight ? "45%" : "100%", marginTop:"16px"}}>

            <div style={{ width: window.innerWidth > window.innerHeight ? window.innerWidth > 1400 ? "fit-content" : "20vw" : window.innerWidth < 600 ? "42vw" : window.innerWidth > 1000 ? "20vw" : "25vw" ,marginRight: window.innerWidth > window.innerHeight ? "0px" : "0px", marginLeft:"auto", marginBottom:"8px", maxWidth: window.innerWidth > window.innerHeight ? "fit-content" : window.innerWidth < 600 ? "42vw" : window.innerWidth > 1000 ? "20vw" : "25vw" }}>
              
                <Product
                  imgs={postImages[0].src}
                  productName={productName}
                  currentBid={0}
                  acceptsOffers={postType.includes("عروض")}
                  carPlateKind={carPlateKind}
                  isAuction={true}
                  numBidders={0}
                  category={productCategory}
                  condition={productCondition}
                  expiryDate={productExpiryDate}
                />
              </div>
              <div style={{ display: "flex", backgroundColor: "#f7f7f7", borderRadius: "6px", padding: "8px 10px", marginTop: "4px",width:"fit-content" }}>
                <MdInfo size={20} color="#a0a0a0" />
                <p style={{ color: "#555", fontSize: "15px", flex: 1, marginBottom: "0px", marginRight: "4px" }}>
                  سيتم عرض منتجك بهذا الشكل. اذا عرضت منتجك للمزاد, سيتم عرض السعر الحالي للمنتج
                </p>
              </div>
            </div>
          ) : (
            <div style={{width:window.innerWidth > window.innerHeight ? "45%" : "100%", marginTop:"16px"}}>

            <div style={{ width: window.innerWidth > window.innerHeight ? window.innerWidth > 1400 ? "fit-content" : "20vw" : window.innerWidth < 600 ? "42vw" : window.innerWidth > 1000 ? "20vw" : "25vw" ,marginRight: window.innerWidth > window.innerHeight ? "0px" : "0px", marginLeft:"auto", marginBottom:"8px", maxWidth: window.innerWidth > window.innerHeight ? "fit-content" : window.innerWidth < 600 ? "42vw" : window.innerWidth > 1000 ? "20vw" : "25vw" }}>
              
                <Product
                  imgs={postImages[0].src}
                  productName={productName}
                  currentBid={0}
                  isAuction={false}
                  acceptsOffers={postType.includes("عروض")}
                  carPlateKind={carPlateKind}
      
                  numBidders={0}
                  category={productCategory}
                  condition={productCondition}
                  productPrice={parseFloat(productPrice) || 0}
                />
              </div>
              <div style={{ display: "flex", backgroundColor: "#f7f7f7", borderRadius: "6px", padding: "8px 10px", marginTop: "4px", width:"fit-content" }}>
                <MdInfo size={20} color="#a0a0a0" />
                <p style={{ color: "#555", fontSize: "15px", flex: 1, marginBottom: "0px", marginRight: "4px" }}>
                  سيتم عرض منتجك بهذا الشكل.
                </p>
              </div>
            </div>
          )
        )
      }
      
        </div>
      
      
      
        {
        (productCategory === "لوح سيارات" || productCategory === "ارقام جوالات") && (
          <>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop:"20vh" }}>
            <button
              id='my-button'
              disabled={updateLoading}
              style={{ width: window.innerWidth > window.innerHeight ? "40%" : "80%", marginLeft: "auto", marginRight: "auto" }}
              // onClick={UpdatePost}
              onClick={updateProduct}
              >
              {updateLoading ? (
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{ display: "block" }}
                    ariaLabel='oval-loading'
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                    />
                </div>
              ) : (
                <p style={{ marginBottom: "0px" }}>حفظ</p>
              )}
            </button>
          </div>
      
      
          <div style={{ width: "100%", marginTop: "16px", display: "flex" }}>
                  <button id="my-button" disabled={deleteLoading} style={{ marginRight: "auto", marginLeft: "auto", width: window.innerWidth > window.innerHeight ? "40%" : "80%", backgroundColor: "#F00057" }} onClick={async () => deletePost()}>
                    {deleteLoading ? (
                      <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display:"flex" }}>
                        <Oval
                          height={"20px"}
                          width={"20px"}
                          color={"white"}
                          wrapperClass=""
                          visible={true}
                          wrapperStyle={{ display: "block" }}
                          ariaLabel="oval-loading"
                          secondaryColor="#ccc"
                          strokeWidth={1}
                          strokeWidthSecondary={1}
                        />
                      </div>
                    ) : (
                      <p style={{ marginBottom: "0px" }}>حذف المنتج</p>
                    )}
                  </button>
                </div>
              </>
        )
      }
      
      </div>
      {
        productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" && (
          <>
      
      
      <div style={{width: window.innerWidth > window.innerHeight ? "80%" : "100%", height: "fit-content", marginLeft: "auto", marginRight: "auto", direction: "rtl", minHeight:"100vh", padding: window.innerWidth > window.innerHeight ? "16px 0px" : "0px 16px", marginTop:"16px" }}>
                  <div ref={shippingRef} style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <div>
                      <h1 style={{marginBottom: "16px", fontSize: window.innerWidth > window.innerHeight ? "32px" : "26px", fontWeight: "bold", color:"#00A49B", textAlign:"right", marginRight: "0px"}}>الشحن و التوصيل</h1>
                  <h6 style={{fontWeight: "500", color: "#00A49B" }}>يمكنك اختيار اكثر من خيار</h6>
      
                    </div>
                    {/* {
                      shippingMethods.length > 0 && (
                      shippingMethods.includes("شحن") ? (
                      productWeight !== "" && (
                                        <button id='my-button' style={{width:"fit-content", padding:"8px 16px"}} 
                          onClick={()=>{setShippingScrollTrigger(true)}}
                          >التالي</button>
                      )
                      ) : (
                                        <button id='my-button' style={{width:"fit-content", padding:"8px 16px"}} 
                          onClick={()=>{setShippingScrollTrigger(true)}}
                          >التالي</button>)
                      )
                    } */}
                  </div>
      
                  <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>طريقة توصيل المنتج:</h5>
      
                  <PostType onSelect={handleSelectShippingMethods} selectedCategories={shippingMethods}>
                  <div category="شحن" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <FaShippingFast  color='#00C8B8' style={{width:"80px", height:"80px", marginRight:"auto", marginLeft:"auto"}} />
                        <p style={{marginBottom:"0px", fontWeight:"500"}}>شحن سريع<span style={{fontWeight:"400"}}>: سيكون بإمكانك طباعة بوليصة شحن في حال قام المشتري باختيار طريقة الشحن هذه. </span></p>
                  </div>
                  <div category="تسليم" style={{justifyContent:"space-between", padding: "0px", display:"flex", flexDirection:"column", alignItems:"center", width:"100%" }}>
                        <FaRegHandshake  color='#00C8B8' style={{width:"80px", height:"80px", marginRight:"auto", marginLeft:"auto"}} />
                        <p style={{marginBottom:"0px", fontWeight:"500", textAlign:"right"}}>توصيل<span style={{fontWeight:"400"}}>: سأقوم بتسليم المنتج للمشتري وجهاً لوجه.</span></p>
                  </div>
                  </PostType>
      
      
                  {
                    shippingMethods.includes("شحن") && (
                      <div style={{width: window.innerWidth > window.innerHeight ? "50%" : "100%"}}>
                                      <h5 style={{fontWeight: "500", color: "#00A49B", margin:"22px 0px 14px 0px" }}><span style={{color:"red"}}>*</span>وزن المنتج:</h5>
                        <div style={{display:"flex"}}>
      

                        <input  
                  onChange={(e) => {
                    const inputValue = e.target.value.trim(); // Trim any leading or trailing whitespace

                    // Check if the input value is empty
                    if (inputValue === "") {
                      return;
                    }

                    // Check for non-numeric characters (excluding decimal point)
                    if (!/^[0-9.]*$/.test(inputValue)) {
                      alert("يرجى ادخال أرقام فقط (مع فاصلة اختيارية).");
                      productWeightRef.current.value = ""; // Clear the input field
                      return;
                    }

                    // Check if the input is a valid number with optional decimal
                    if (isNaN(parseFloat(inputValue))) {
                      alert("يرجى ادخال رقم صالح (أرقام صحيحة مع فاصلة اختيارية).");
                      productWeightRef.current.value = ""; // Clear the input field
                      return;
                    }

                    // Round the weight to two decimal places using toFixed
                    const roundedWeight = parseFloat(inputValue).toFixed(1);

                    // Update the state with the rounded weight
                    setProductWeight(roundedWeight);
                  }}





 type='tel' lang='en' defaultValue={productWeight} ref={productWeightRef} placeholder='وزن المنتج' style={{backgroundColor:"#F0F0F0", border: "none", width:"100%", height: "50px", borderRadius:"8px", outline:"none", padding:"8px 16px", textAlign:"right"}}/>
      
                    <p style={{marginBottom: "auto", marginTop: "auto", marginRight:"4px"}}>كجم</p>
                        
                        </div>
                      <div style={{display: "flex", backgroundColor: "#f7f7f7" , width: "100%", marginBottom: "0px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px",}}>
                    <MdInfo size={20} color='#a0a0a0' />
      
                    <p style={{ color: "#555", marginRight: "4px", fontSize: "15px", marginBottom: "0px", width: "100%" }}>
                    وزن المنتج مطلوب لحساب تكلفة الشحن. تبلغ تكلفة الشحن <span style={{fontWeight:"600"}}>{formatAccountingArabic(23)}</span> للـ15 كيلوجرام الأولى، وريالين لكل كيلوجرام يزيد عن ذلك.
      <br/>
      {
        !isNaN(productWeight) && productWeight > 0 &&
        <span style={{marginBottom:"0px"}}>
           تكلفة شحن المنتج الحالية هي: <span style={{fontWeight:"600"}}>{formatAccountingArabic(calculateShippingCost(parseFloat(productWeight)))}</span>
        </span>
      }
                    </p>
      
                    </div>
                      </div>
                    )
                  }
      
      
      
      <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"20vh"}}>
      <button 
      id='my-button' 
      disabled={updateLoading || deleteLoading}
      style={{width: window.innerWidth > window.innerHeight ? "40%" : "80%", marginLeft:"auto", marginRight:"auto"}} 
      // onClick={UpdatePost}
      onClick={updateProduct}
      > 
      {
        updateLoading ? <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex"}}>
        <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{display: "block"}}
                    ariaLabel='oval-loading'
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  /> 
        </div> : <p style={{marginBottom:"0px"}}>{updateLoading ? (
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{ display: "block" }}
                    ariaLabel='oval-loading'
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  />
                </div>
              ) : (
                <p style={{ marginBottom: "0px" }}>حفظ</p>
              )}</p>
      } </button>
      </div>
      <div style={{ width: "100%", marginTop: "16px", display: "flex" }}>
                  <button id="my-button" 
      disabled={updateLoading || deleteLoading}
                  
                  style={{ marginRight: "auto", marginLeft: "auto", width: window.innerWidth > window.innerHeight ? "40%" : "80%", backgroundColor: "#F00057" }} onClick={async () => deletePost()}>
                    {deleteLoading ? (
                      <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display:"flex" }}>
                        <Oval
                          height={"20px"}
                          width={"20px"}
                          color={"white"}
                          wrapperClass=""
                          visible={true}
                          wrapperStyle={{ display: "block" }}
                          ariaLabel="oval-loading"
                          secondaryColor="#ccc"
                          strokeWidth={1}
                          strokeWidthSecondary={1}
                        />
                      </div>
                    ) : (
                      <p style={{ marginBottom: "0px" }}>حذف المنتج</p>
                    )}
                  </button>
                </div>
        </div>
          </>
        )
      }
      </>
          </>
    )}
  </>
);

  }

export default MyPostDetailsScreen;