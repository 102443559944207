import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import { IoIosCloseCircle, IoIosArrowBack } from 'react-icons/io';
import Lottie from 'lottie-react';
import noChatAnim from '../assets/animations/NoChatAnim.json';
import signInAnim from '../assets/animations/sign_in_anim.json';
import { Oval } from 'react-loader-spinner';
import { collection, query, where, onSnapshot, addDoc, serverTimestamp, orderBy, getDocs, doc, setDoc, Timestamp, getDoc, limit, startAfter } from 'firebase/firestore';
import { db, storage } from '../config/firebase'; // Adjust the import based on your project structure
import { IoSend } from "react-icons/io5";
import { Divider } from '@mui/material';
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { GoPaperclip } from "react-icons/go";
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { IoCloseOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaRegImage } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa";
import { FaStore } from "react-icons/fa";
import { MAILJET_API_KEY, MAILJET_SECRET_KEY } from '../config/mailjetConfig';
import { auth } from '../config/firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function



const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [chatShouldRender, setChatShouldRender] = useState(false);
  const [newChatUser, setNewChatUser] = useState(null);
  const [loading, setChatLoading] = useState(true);
  const [myId, setMyId] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState({});
  const [activeChatId, setActiveChatId] = useState(null);
  const [usersInfo, setUsersInfo] = useState({});
  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const messageRef = useRef();
  const messageAttachmentRef = useRef();
  const messagesEndRef = useRef(null); // Reference for scrolling to the bottom

  const[appLoading, setAppLoading] = useState(true)


  const [fullScreenMedia, setFullScreenMedia] = useState(null);
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const [isVideo, setIsVideo] = useState(false);

  const showMediaFullScreen = (mediaUrl, mediaType) => {
    console.log(mediaType )
    setIsMediaLoading(true);
    setFullScreenMedia(mediaUrl);
    setIsVideo(mediaType === 'video');
  };

  const closeFullScreenMedia = () => {
    setFullScreenMedia(null);
    setIsMediaLoading(true); // Reset loading state when closing
  };

  useEffect(()=>{
    if(fullScreenMedia){
      setIsMediaLoading(false);
    }
  }, [fullScreenMedia])
  


  const [myFullName, setMyFullName] = useState('');

  useEffect(() => {
    const handleAuthStateChange = async (user) => {
      if (user) {
        try {
          const docRef = doc(db, 'Users', user.uid);
    
          // Try to read from cache first
          const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);
    
          if (cachedDoc && cachedDoc.exists()) {
            const userData = cachedDoc.data();
            setMyFullName(userData.fullName); // Update the context state
          }
    
          const unsubscribeUser = onSnapshot(docRef, (docu) => {
            if (docu.exists()) {
              const userData = docu.data();
              setMyFullName(userData.fullName); // Update the context state
            } else {
              console.error('User data not found');
            }
          });
    
          return unsubscribeUser; // Correctly return the unsubscribe function
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setMyFullName('');
      }
    };

    const unsubscribeAuth = auth.onAuthStateChanged(handleAuthStateChange);
  
    return () => {
      if (unsubscribeAuth) unsubscribeAuth();
    };
  }, []);



  const handleListItemClick = (chatId) => {
    setDrawerOpen(true);
    setActiveChatId(chatId);
  };



  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the state with current window dimensions
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);





  const getLastMessage = (chatId) => {
    const chatMessages = messages[chatId] || [];
    return chatMessages[chatMessages.length - 1] || {};
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' }); // Smooth scrolling
    }
  }, [messages, activeChatId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setChatShouldRender(chatIsOpen);
    }, 0); // Delay to match the transition duration

    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, [chatIsOpen]);

  useEffect(() => {
    // Control chat rendering based on chatShouldRender state
    if (chatShouldRender) {
      setChatIsOpen(true);
    } else {
      setChatIsOpen(false);
    }
  }, [chatShouldRender]);

  const activeChat = chats.find(chat => chat.id === activeChatId);
  const otherUserId = activeChat ? activeChat.group.find(userId => userId !== myId) : null;
  const otherUser = otherUserId ? usersInfo[otherUserId] || {} : {};


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  const scrollToBottomSmooth = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const sendMessage = async (file = null, fileType = null) => {
    if (!activeChatId || (!message.trim() && !file)) {
      console.log("No active chat ID or message/file is empty.");
      return;
    }
  
    const newMessageId = uuidv4();
    const newMessage = {
      id: newMessageId,
      senderId: myId,
      message: file ? "" : message,
      fileUrl: file ? URL.createObjectURL(file) : null,
      fileType: fileType || null,
      date: new Date(),
      isDelivered: false,
      isRead: false, // Initially set to false
    };
  
    setMessages(prevMessages => {
      const messages = prevMessages[activeChatId] || [];
      if (messages.some(msg => msg.id === newMessageId)) {
        console.warn("Message already exists locally:", newMessageId);
        return prevMessages;
      }
      return {
        ...prevMessages,
        [activeChatId]: [...messages, newMessage],
      };
    });
  
    setMessage(''); // Clear the input after sending
  
    if (messageRef.current) {
      messageRef.current.value = "";
    } else {
      console.error("messageRef.current is undefined");
    }
  
    const otherUserId = newChatUser?.id || chats.find(chat => chat.id === activeChatId)?.group.find(userId => userId !== myId);
  
    if (!otherUserId) {
      console.error("No other user ID found.");
      return;
    }
  
    try {
      const functions = getFunctions();
      const sendMessageFunction = httpsCallable(functions, 'sendMessage');
      const fileBase64 = file ? await fileToBase64(file) : null;
  
      const result = await sendMessageFunction({
        id: newMessageId,
        activeChatId,
        myId,
        message: file ? "" : message,
        fileType: fileType || null,
        file: fileBase64,
        newChatUser,
        chats,
        myFullName,
        otherUserId
      });
  
      if (result.data.success) {
        setMessages(prevMessages => {
          const messages = prevMessages[activeChatId] || [];
          return {
            ...prevMessages,
            [activeChatId]: messages.map(msg =>
              msg.id === newMessageId ? { ...msg, isDelivered: true } : msg
            ),
          };
        });
      } else {
        console.error("Error sending message:", result.data.error);
      }
    } catch (error) {
      console.error("Error calling sendMessage function:", error);
    }
  };
  

  const getUnreadMessagesCountForChat = async (chatId) => {
    try {
      const messagesCollectionRef = collection(db, `Chats/${chatId}/Messages`);
      const unreadMessagesQuery = query(
        messagesCollectionRef,
        where('isRead', '==', false),
        where('senderId', '!=', myId) // Filter out messages sent by the current user
      );
      const querySnapshot = await getDocs(unreadMessagesQuery);
      return querySnapshot.size;
    } catch (error) {
      console.error('Error fetching unread messages count:', error);
      return 0;
    }
  };

  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0)
  
  useEffect(() => {
  if (!myId) return;
  
    setChatLoading(true);
  
    const messageUnsubscribes = [];
    let remainingSnapshots = 0;
  
    const chatsQuery = query(
      collection(db, 'Chats'),
      where('group', 'array-contains', myId),
      where('isDeleted', '==', false)
    );
  
    const unsubscribeChats = onSnapshot(chatsQuery, async (chatsSnapshot) => {
      if (chatsSnapshot.empty) {
        setChats([]);
        setUsersInfo({});
        setChatLoading(false);
        return;
      }
  
      const fetchedChats = chatsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      const chatWithLastMessageAndUnreadCounts = await Promise.all(
        fetchedChats.map(async chat => {
          const messagesQuery = query(
            collection(db, 'Chats', chat.id, 'Messages'),
            orderBy('date', 'desc'),
            limit(1)
          );
  
          const messagesSnapshot = await getDocs(messagesQuery);
          const lastMessageDate = messagesSnapshot.empty
            ? null
            : messagesSnapshot.docs[0].data().date
              ? messagesSnapshot.docs[0].data().date.toDate()
              : null;
  
          // Get unread messages count
          const unreadMessagesCount = await getUnreadMessagesCountForChat(chat.id)
  
          return {
            ...chat,
            lastMessageDate,
            unreadMessagesCount,
          };
        })
      );
  
      chatWithLastMessageAndUnreadCounts.sort((a, b) => {
        if (!a.lastMessageDate && !b.lastMessageDate) return 0;
        if (!a.lastMessageDate) return 1;
        if (!b.lastMessageDate) return -1;
        return b.lastMessageDate - a.lastMessageDate;
      });
  
      setChats(chatWithLastMessageAndUnreadCounts);
  
      const userIds = new Set(chatWithLastMessageAndUnreadCounts.flatMap(chat => chat.group));
      const userQuery = query(collection(db, 'Users'), where('id', 'in', [...userIds]));
      const userSnapshot = await getDocs(userQuery);
      const userDocs = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      setUsersInfo(prevState => {
        const newUserInfo = userDocs.reduce((acc, userDoc) => {
          acc[userDoc.id] = userDoc;
          return acc;
        }, {});
        return { ...prevState, ...newUserInfo };
      });
  
      remainingSnapshots = chatWithLastMessageAndUnreadCounts.length;
  
      let totalUnreadMessagess = 0; // Variable to accumulate total unread messages
  
      chatWithLastMessageAndUnreadCounts.forEach(chat => {
        const messagesQuery = query(
          collection(db, 'Chats', chat.id, 'Messages'),
          orderBy('date', 'asc')
        );
  
        const unsubscribeMessages = onSnapshot(messagesQuery, async (messageSnapshot) => {
          setMessages(prevMessages => {
            const existingMessages = prevMessages[chat.id] || [];
            const newMessages = messageSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              date: doc.data().date.toDate(),
            }));
  
            const updatedMessages = [
              ...existingMessages.filter(msg => !newMessages.some(newMsg => newMsg.id === msg.id)),
              ...newMessages
            ].sort((a, b) => a.date - b.date);
  
            return {
              ...prevMessages,
              [chat.id]: updatedMessages,
            };
          });
  
          // Update unread messages count
          const unreadMessagesCount = await getUnreadMessagesCountForChat(chat.id);
          setChats(prevChats => {
            const updatedChats = prevChats.map(c => c.id === chat.id ? { ...c, unreadMessagesCount } : c);
            
            // Calculate the new total unread messages
            totalUnreadMessagess = updatedChats.reduce((acc, c) => acc + (c.unreadMessagesCount || 0), 0);
            setTotalUnreadMessages(totalUnreadMessagess)
            // console.log("Total Unread Messages:", totalUnreadMessagess);
            setAppLoading(false)
            return updatedChats;
          });
  
          remainingSnapshots -= 1;
          if (remainingSnapshots === 0) {
            setChatLoading(false);
          }
        }, (error) => {
          console.error('Error fetching messages:', error);
          remainingSnapshots -= 1;
          if (remainingSnapshots === 0) {
            setChatLoading(false);
          }
        });
  
        messageUnsubscribes.push(unsubscribeMessages);
      });
  
      if (chatWithLastMessageAndUnreadCounts.length === 0) {
        setChatLoading(false);
      }
    }, (error) => {
      console.error('Error fetching chats:', error);
      setChatLoading(false);
    });
  
    setAppLoading(false)

    return () => {
      unsubscribeChats();
      messageUnsubscribes.forEach(unsub => unsub());
    };
  }, [myId]);
  
  
  
  



  useEffect(() => {
  
    if (!chats.length || !myId) return;
  
    const messageUnsubscribes = [];
    const chatIdSet = new Set(chats.map(chat => chat.id)); // To keep track of existing chat IDs
  
    chats.forEach(chat => {
      const unreadMessagesQuery = query(
        collection(db, `Chats/${chat.id}/Messages`),
        where('isRead', '==', false),
        where('senderId', '!=', myId)
      );
  
      const unsubscribeMessages = onSnapshot(unreadMessagesQuery, (messageSnapshot) => {
        setChats(prevChats => {
          // Check if chat ID exists before updating
          const chatExists = prevChats.find(c => c.id === chat.id);
          if (chatExists) {
            return prevChats.map(c => {
              if (c.id === chat.id) {
                return {
                  ...c,
                  unreadMessagesCount: messageSnapshot.size
                };
              }
              return c;
            });
          }
          return prevChats;
        });
      }, (error) => {
        console.error('Error fetching unread messages:', error);
      });
  
      messageUnsubscribes.push(unsubscribeMessages);
    });
  
    return () => {
      messageUnsubscribes.forEach(unsub => unsub());
    };
  }, [myId]);

  useEffect(() => {

    if (activeChatId) {
      // Firestore reference to the Messages collection within the active chat
      const messagesRef = collection(db, `Chats/${activeChatId}/Messages`);
      const q = query(messagesRef, orderBy('date', 'asc'));

      // Listener for real-time updates
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setActiveChatMessages(messages);
      });
      
      // Clean up the listener when the component unmounts or activeChatId changes
      return () => unsubscribe();
    }
  }, [activeChatId]);



  const [activeChatMessages, setActiveChatMessages] = useState([]);



  useEffect(() => {

    const markMessagesAsRead = async () => {
      try {
        const functions = getFunctions();
        const markMessagesAsReadFunction = httpsCallable(functions, 'markMessagesAsRead');
  
        if (activeChatId && activeChatMessages.length > 0) {
          // console.log('Calling markMessagesAsRead function with chatId:', activeChatId);
          const result = await markMessagesAsReadFunction({ chatId: activeChatId, myId });
          // console.log(`Messages marked as read for chat ${activeChatId}`, result.data);
        } else {
          // console.log('No active chat or messages to mark as read.');
        }
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    };
  
    markMessagesAsRead();
  }, [activeChatId, activeChatMessages, myId]);
  


  // Utility function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]); // Return base64 part only
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type.startsWith("video/") ? "video" : "image";
      // console.log("File selected:", file, "File type:", fileType);
      await sendMessage(file, fileType);
    }
  };
  
  
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
  
    let jsDate;
    if (timestamp.toDate) {
      jsDate = timestamp.toDate(); // Firestore timestamp
    } else {
      jsDate = new Date(timestamp); // JavaScript Date
    }
  
    if (isNaN(jsDate.getTime())) return "";
  
    const hours = jsDate.getHours();
    const minutes = jsDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };


  useEffect(() => {
  
    if (!chats.length || !messages) return;
  
    const sortedChats = chats.map(chat => ({
      ...chat,
      lastMessageDate: messages[chat.id]?.[messages[chat.id].length - 1]?.date || null,
    })).sort((a, b) => {
      if (!a.lastMessageDate && !b.lastMessageDate) return 0;
      if (!a.lastMessageDate) return 1;
      if (!b.lastMessageDate) return -1;
      return b.lastMessageDate - a.lastMessageDate;
    });
  
    setChats(prevChats => {
      if (JSON.stringify(prevChats) !== JSON.stringify(sortedChats)) {
        return sortedChats;
      }
      return prevChats;
    });
  }, [messages, chats]);
  

  return (
    <ChatContext.Provider
      value={{
        setChatIsOpen,
        setNewChatUser,
        setMyId,
        setChats,
        setMessages,
        setUsersInfo,
        activeChatId,
        setActiveChatId,
        drawerOpen,
        setDrawerOpen,
        handleListItemClick,
        setChatLoading,
        getLastMessage,
        message,
        setActiveChatId,
        usersInfo,
        chats,
        totalUnreadMessages,
        appLoading
      }}
    >
      {children}


      {fullScreenMedia && (
        <div 
          style={{ 
            position: "fixed", 
            top: 0, 
            left: 0, 
            width: "100%", 
            height: "100%", 
            backgroundColor: "rgba(0, 0, 0, 0.8)", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            zIndex: 1005 
          }}
          onClick={closeFullScreenMedia}
          >
          <div style={{position:"absolute", left: 0, top: 0, padding:"8px"}}
            onClick={closeFullScreenMedia}
          >
            <IoCloseOutline size={42} color='white' 
            
            />
          </div>


          {isMediaLoading && <p style={{ color: "white" }}>Loading...</p>}
          {!isMediaLoading && (
            isVideo ? (
              <video src={fullScreenMedia} controls style={{ maxWidth: "90%", maxHeight: "90%" }} />
            ) : (
              <img src={fullScreenMedia} style={{ maxWidth: "90%", maxHeight: "90%" }} alt="Full screen media" />
            )
          )}
        </div>
      )}


      <div
style={{
  width: window.innerWidth > window.innerHeight 
    ? window.innerWidth > 1100 ? '27vw' : '50vw' 
    : window.innerWidth < 600 ? '95vw' : '70vw',
  height: '80vh',
  position: 'fixed',
  right: window.innerWidth > window.innerHeight 
    ? 32 
    : window.innerWidth < 600 ? 8 : 16,
  bottom: chatShouldRender 
    ? window.innerWidth > window.innerHeight ? '32px' : '64px' 
    : '-80vh',
  opacity: chatShouldRender ? 1 : 0,
  backgroundColor: 'white',
  borderRadius: '24px',
  zIndex: 1003,
  overflow: 'hidden',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transition: 'bottom 0.4s ease-in-out, opacity 0.3s ease-in-out, transform 0.4s ease-in-out',
  transformOrigin: 'bottom right',
  transform: chatShouldRender ? 'scale(1)' : 'scale(0.95)', // Shrinks and expands from bottom-right
}}

      >
        <div style={{
          width: "100%",
          backgroundColor: "white",
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: '1px solid #ddd',
        }}>
          <IoIosCloseCircle size={22} color='#ccc' style={{ cursor: "pointer", minWidth:"22px", minHeight:"22px" }} onClick={() => { setChatShouldRender(false); setActiveChatId(null); setNewChatUser(null); setChatIsOpen(false) }} />
          <p style={{ margin: "0px", alignSelf: "center", fontWeight: 500, color: "#01635c" }}>الرسائل الخاصة</p>
          <div />
        </div>
  
        {auth.currentUser === null ? (
          // Show Lottie animation if myId is null
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Lottie animationData={signInAnim} loop={true} style={{height:"70%", width:"70%"}} />
            <p style={{ marginBottom: 'auto', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color:"#01635c" }}>قم بتسجيل الدخول لعرض الرسائل</p>
          </div>
        ) : loading ? (
          // Show loading spinner if loading is true
          <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", position: "absolute" }}>
            <Oval
              height={"30px"}
              width={"30px"}
              color={"#00C8B8"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block" }}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={2}
              strokeWidthSecondary={1}
            />
          </div>
        ) : (
          <>
            {!newChatUser && (
              chats.length > 0 ? (
                <ul style={{ listStyleType: 'none', padding: "0px", margin: 0 }}>
{chats.map(chat => {
  const otherUserId = chat.group.find(userId => userId !== myId);
  const otherUser = usersInfo[otherUserId] || {};
  const lastMessage = getLastMessage(chat.id);

  // Ensure `rating` is a number with a default value of 0 if undefined
  const rating = typeof otherUser.rating === 'number' ? otherUser.rating : 0;

  // Use `chat.unreadMessagesCount` to get the number of unread messages
  const unreadMessages = chat.unreadMessagesCount || 0;

  return (
    <div key={chat.id} style={{ padding: '10px', boxSizing: 'border-box' }}>
      <li
        onClick={() => handleListItemClick(chat.id)}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          direction: "rtl",
          boxSizing: 'border-box',
          overflow: 'hidden',
          margin: '0px',
          listStyleType: 'none'
        }}
      >
        <img
          src={otherUser.profileImgUrl || 'default-image-url'}
          alt={otherUser.fullName || 'Unknown User'}
          className='skeleton'
          style={{ width: 55, height: 55, borderRadius: '50%', marginLeft: '10px', objectFit: "cover", border: "0.5px solid #ccc", boxSizing: 'border-box', opacity:1 }}
        />
        <div style={{ display: "flex", flexDirection: "column", marginLeft: '10px', flex: 1, overflow: 'hidden' }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", boxSizing: 'border-box', overflow: 'hidden' }}>
            <div style={{ display: "flex", alignItems: 'center', flex: 1, overflow: 'hidden'}}>
              <span style={{ textAlign: "right", fontWeight: 500, fontSize: "15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginLeft:"4px" }}>
                {otherUser.fullName || 'Unknown User'}
              </span>
              {!otherUser.isBusiness && otherUser.isVerified && <RiVerifiedBadgeFill size={18} style={{ marginLeft: "4px", marginRight: "2px", marginTop: "3px" }} color='#00C8B8' />}
              {otherUser.isBusiness && otherUser.isVerified && <FaStore size={18} style={{ marginLeft: "4px", marginRight: "2px", marginTop: "3px" }} color='#F00057' />}
              <div style={{ display: "flex", alignItems: 'center', marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                <AiFillStar size={17} style={{ marginTop: "3px" }} color='gold' />
                <p style={{ marginBottom: "0px", fontSize: "13px", marginLeft: '4px' }}>{rating.toFixed(1)}</p>
                <p style={{ marginBottom: "0px", marginLeft: '4px', fontSize: "13px" }}>({otherUser.numRaters || 0})</p>
              </div>
            </div>
            <p style={{ marginBottom: "0px", fontSize: "11px", direction: "ltr", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexShrink: 0 }}>
              {formatDate(lastMessage.date)}
            </p>
          </div>

          <div style={{ width: "100%", display: "flex", marginTop: "8px", alignItems: "center", justifyContent: "space-between", boxSizing: 'border-box' }}>
            <div style={{ display: "flex", maxWidth: "80%", boxSizing: 'border-box' }}>
              <span style={{ textAlign: "right", fontSize: "14px", direction: "rtl", display: "block", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "100%"}}>
                {lastMessage.senderId === myId && (
                  !lastMessage.isDelivered ? (
                    <IoCheckmark style={{ marginLeft: "4px", marginRight: "0px", color: "gray" }} />
                  ) : (
                    <IoCheckmarkDoneOutline style={{ marginLeft: "4px", marginRight: "0px", color: lastMessage.isRead ? "dodgerblue" : "gray" }} />
                  )
                )}
                {lastMessage.fileType === "image" ? <FaRegImage color='gray' /> : lastMessage.fileType === "video" ? <FaVideo color='gray'/> : lastMessage.message || 'لا توجد رسائل بعد'}
              </span>
            </div>
            {unreadMessages > 0 && (
              <div style={{
                backgroundColor: "#F00057", color: "white", borderRadius: "50%", width: "20px", height: "20px", display: "flex",
                alignItems: "center", justifyContent: "center", fontSize: "12px", marginRight: "10px"
              }}>
                {unreadMessages}
              </div>
            )}
          </div>
        </div>
      </li>
      <Divider style={{ width: '80%', margin:"8px 0px" }} /> {/* Adjust style as needed */}
    </div>
  );
})}









                </ul>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <Lottie animationData={noChatAnim} loop={true} />
                  <p style={{ marginBottom: 'auto', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color:"#01635c" }}>لا توجد محادثات بعد</p>
                </div>
              )
            )}
  
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                position: 'absolute',
                top: 0,
                right: drawerOpen ? 0 : '-100%',
                transition: 'right 0.2s ease-in-out',
                zIndex: 1004,
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
{(activeChatId || newChatUser) && (
  <>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      backgroundColor: 'white',
      borderBottom: '1px solid #ddd',
      direction: "rtl",
      position: "sticky",
      width: "100%",
      top: 0,
      justifyContent: "space-between"
    }}>
      <a href={`/user/${otherUser?.id || newChatUser?.id}`}>
        <div style={{
          width: "fit-content",
          display: "flex",
          marginLeft: "auto"
        }}>
          <img
            src={otherUser?.profileImgUrl || newChatUser?.profileImgUrl || 'default-image-url'}
            alt={otherUser?.fullName || newChatUser?.fullName || 'Unknown User'}
            className='skeleton'
            style={{
              width: 45,
              height: 45,
              borderRadius: '50%',
              marginLeft: '8px',
              objectFit: "cover",
              opacity: 1,
              border: "0.5px solid #ccc"
            }}
          />
          <div>
            <div style={{ display: "flex" }}>
              <span style={{ fontWeight: 500, fontSize: "15px", color: "black" }}>
                {otherUser?.fullName || newChatUser?.fullName || 'Unknown User'}
              </span>
              {(otherUser?.isVerified || newChatUser?.isVerified) && (
                <RiVerifiedBadgeFill
                  size={20}
                  style={{ marginLeft: "4px", marginRight: "2px", marginTop: "3px" }}
                  color='#00C8B8'
                />
              )}
              <div style={{ display: "flex", marginRight:"4px" }}>
                <AiFillStar size={17} style={{ marginTop: "3px" }} color='gold' />
                <p style={{ marginBottom: "0px", fontSize: "13px", color: "black" }}>
                  {(otherUser?.rating?.toFixed(1) || newChatUser?.rating?.toFixed(1) || 0)}
                </p>
                <p style={{
                  marginBottom: "0px",
                  marginRight: "4px",
                  fontSize: "13px",
                  color: "black"
                }}>
                  ({otherUser?.numRaters || newChatUser?.numRaters || 0})
                </p>
              </div>
            </div>

            {(otherUser?.city || newChatUser?.city) && (
              <div style={{ direction: "rtl", display: "flex" }}>
                <FaLocationDot size={14} color='red' style={{ marginTop: "4px" }} />
                <p style={{ marginRight: "2px", marginBottom: "0px", fontSize: "13px", color: "black" }}>
                  {otherUser?.city || newChatUser?.city}
                </p>
              </div>
            )}
          </div>
        </div>
      </a>
      <IoIosArrowBack
        size={22}
        color='#000'
        style={{ cursor: "pointer", marginRight: '8px' }}
        onClick={() => {
          setDrawerOpen(false);
          setMessage("");
          setActiveChatId(null)
          setNewChatUser(null);
        }}
      />
    </div>
  </>
)}

              <div style={{ padding: '8px 16px', flex: 1 }}>
                {activeChatId && messages[activeChatId] && messages[activeChatId].length > 0 ? (
                  <>

{messages[activeChatId].map((msg) => (
  <div key={msg.id} style={{ display: "flex", width: "100%" }}>
<div
  style={{
    marginLeft: msg.senderId === myId ? "auto" : "0px",
    padding: "8px",
    backgroundColor: "#F7F7F7",
    minWidth: "25%",
    maxWidth: "95%",
    marginBottom: "8px",
    borderRadius: "12px",
    textAlign: "right",
    overflowWrap: "break-word", // Ensures long words break and wrap
    wordBreak: "break-word", // Prevents long words from overflowing
    whiteSpace: "pre-wrap", // Ensures whitespace and line breaks are preserved
    height: "fit-content" // Allows container height to expand based on content
  }}
  onClick={() => {
    if (msg.fileType === "image") {
      showMediaFullScreen(msg.fileUrl, msg.fileType);
    }
  }}
>
  {
    !msg.fileUrl ? (
      <p style={{ fontSize: "14px", marginBottom: "4px", direction: "rtl" }}>{msg.message}</p>
    ) : (
      msg.fileType === "video" ? (
        <video
          style={{ width: "200px", minWidth: "70%", marginBottom: "4px", borderRadius: "8px", objectFit: "contain", maxHeight: "350px", opacity: 1 }}
          src={msg.fileUrl}
          controls
        />
      ) : (
        <img
          style={{ width: "200px", minWidth: "70%", marginBottom: "4px", borderRadius: "12px", objectFit: "contain", maxHeight: "350px", opacity: 1 }}
          src={msg.fileUrl}
          alt=""
        />
      )
    )
  }
  <div style={{ display: "flex" }}>
    <div style={{ width: "100%", display: "flex", textAlign: "right", justifyContent: "flex-end" }}>
      <small style={{ fontSize: '10px' }}>{formatDate(msg.date)}</small>
      {msg.senderId === myId && (
        !msg.isDelivered ? (
          <IoCheckmark style={{ marginLeft: "4px", marginRight: "0px", color: "gray" }} />
        ) : (
          <IoCheckmarkDoneOutline style={{ marginLeft: "4px", marginRight: "0px", color: msg.isRead ? "dodgerblue" : "gray" }} />
        )
      )}
    </div>
  </div>
</div>

  </div>
))}




                    <div ref={messagesEndRef} />
                  </>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor:"white" }}>
                    <Lottie animationData={noChatAnim} loop={true} style={{height:"50%", backgroundColor:"white", marginTop:"auto", maxHeight:"50vh"}} />
                    <p style={{ marginBottom: 'auto', textAlign: 'center', fontWeight: '500', whiteSpace: 'nowrap', overflow: 'hidden', color:"#01635c" }}>قم بإرسال رسالتك للبائع</p>
                  </div>
                )}
              </div>
  
              {(activeChatId || newChatUser) && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  backgroundColor: 'white',
                  direction: "rtl",
                  position: "sticky",
                  width: "100%",
                  bottom: 0,
                  justifyContent: "space-between",
                  borderTop:"0.5px solid #ccc"
                }}>
                  {
                    message.length > 0 ? (
                      <IoSend
                        onClick={() => { 
                          if(messageRef){
                            messageRef.current.focus();
                          }
                          sendMessage() }}
                        size={32} color='#F00057' style={{ marginLeft: "8px", padding: "4px", borderRadius: "50%", cursor: "pointer" }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                        onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                      />
                    ) : (
                      <>
                        <input
                          ref={messageAttachmentRef}
                          type="file"
                          accept="image/*,video/*"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <GoPaperclip
                          onClick={() => { 
                            if (messageAttachmentRef.current) {
                              messageAttachmentRef.current.click();
                            }
                          }}
                          size={32} color='#00C8B8' style={{ marginLeft: "8px", padding: "4px", borderRadius: "50%", cursor: "pointer" }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = "#F7F7F7"} // Change color on hover
                          onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"}
                        />
                      </>
                    )
                  }

                  <input
                    ref={messageRef}
                    onKeyDown={(e)=>{
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); // Prevents adding a newline when Enter is pressed
                        if (message.trim()) {
                          sendMessage();
                          setMessage(''); // Clears the input after sending the message

                        }
                      }
                    }}
                    onChange={(e) => {
                      setMessage(e.target.value.trim())
                    }}
                    placeholder='اكتب رسالتك' style={{ border: "none", backgroundColor: "#F7F7F7", width: "100%", padding: "8px", borderRadius: "12px", fontSize: "14px" }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </ChatContext.Provider>
  );
  


};