import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa6';

const CustomCheckbox = ({ checked, onChange, label }) => {
  const [isChecked, setIsChecked] = useState(checked);

  // Effect to update local state when external 'checked' prop changes
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxClick = () => {
    // Prevent unchecking if this is the "الكل" checkbox and it is the only active filter
    if (label === "الكل" && !isChecked) {
      onChange(true); // force to remain checked
    } else {
      onChange(!isChecked);
    }
  };

  const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    padding: '4px 0px',
  };

  const boxStyle = {
    width: '15px',
    height: '15px',
    border: '1px solid #00C8B8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isChecked ? '#00C8B8' : 'transparent',
    borderColor: '#00C8B8',
    borderRadius: '4px',
    padding: '1.5px',
    transition: 'background-color 0.2s ease',
  };

  const checkmarkStyle = {
    color: 'white',
    opacity: isChecked ? 1 : 0,
    transition: 'opacity 0.2s ease',
  };

  return (
    <div style={checkboxStyle} onClick={handleCheckboxClick}>
      <div style={boxStyle}>
        <FaCheck style={checkmarkStyle} />
      </div>
      <span id='filter-headers' style={{ fontSize: '15px', marginRight: '8px' }}>
        {label}
      </span>
    </div>
  );
};

export default CustomCheckbox;
