import React, { useEffect, useState, useRef } from 'react';
import placeholder from '../assets/no-image.jpg'; // Fallback placeholder image
import { MdOutlineWatchLater } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { FaCheck, FaMoneyBillWave } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import { BsStopwatchFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import { getFirestore, collection, getDocs ,onSnapshot, serverTimestamp ,query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, FieldValue, getDocsFromCache, getDocFromCache, writeBatch, orderBy, arrayUnion } from 'firebase/firestore';



function Order({
  postId, 
  imgs, 
  isSold, 
  isBidder, 
  productName, 
  highestBidder, 
  acceptsOffers,
  productDescription, 
  productPrice, 
  startingPrice,
  productSeller, 
  expiryDate, 
  condition, 
  currentBid,
  numBidders, 
  numBids, 
  link, 
  sellerCity, 
  buyerId, 
  myId, 
  isAuction, 
  category, 
  carPlateKind,
  orderId
}) {


  const formatAccountingArabic = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits: number > 100 ? 0 : 2,
      maximumFractionDigits: number > 100 ? 0 : 2,
    });
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
    return arabicFormattedNumber;
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const skeletonRef = useRef(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
    if (skeletonRef.current) {
      skeletonRef.current.style.display = 'none';
    }
  };

  // useEffect(() => {
  //   if (imageLoaded) {
  //     const skeletonElement = document.querySelector('.skeleton');
  //     if (skeletonElement) {
  //       skeletonElement.style.display = 'none';
  //     }
  //   }
  // }, [imageLoaded]);

  const productDivRef = useRef(null);

  // useEffect(() => {
  //   if (productDivRef.current) {
  //     const timer = setTimeout(() => {
  //       productDivRef.current.classList.add('fade-in');
  //     }, 0);
  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  return (
    <div style={{ display: "block", minWidth: "100%", position: "relative", width: "100%" }}>
      <Link to={link}>
        <div className='product-div'>
          <div style={{ position: "absolute", right: -5, top: -8, justifyContent: "space-evenly" }}>
            {acceptsOffers && (
              <FaMoneyBillWave color='white' size={25} style={{ padding: "4px", backgroundColor: "#00C8B8", borderRadius: 1000, marginLeft: "4px" }} />
            )}
            {isAuction && isBidder && (
              highestBidder === myId ? (
                <FaCheck color='white' size={28} style={{ padding: "6px", backgroundColor: "#00C8B8", borderRadius: 1000, marginLeft: "4px" }} />
              ) : 
                <MdOutlineClose color='white' size={28} style={{ padding: "2px", backgroundColor: "orange", borderRadius: 1000, marginLeft: "4px" }} />
            )}
          </div>
          {/* <div className="image-container"> */}
            {/* <div className="skeleton" ref={skeletonRef} style={{ minHeight: window.innerWidth > window.innerHeight ? "200px" : "170px" }} /> */}
            {/* <img
              src={imgs} // Use the fallback image if imgs is undefined or null
              className="product-img"
              style={{
                objectFit: (category === "لوح سيارات" || category === "ارقام جوالات") ? "scale-down" : "cover",
                display: imageLoaded ? "block" : "none",
                padding: category === "لوح سيارات" ? (carPlateKind === "لوحة دراجة نارية" ? "24px" : "0px") : "0px",
              }}
              onLoad={handleImageLoad}
              // loading='eager'
              onError={(e) => { e.target.src = placeholder; }} // Fallback to placeholder on error
            /> */}
            <img
            style={{
              objectFit: (category === "لوح سيارات") ? "scale-down" : "cover",
            }}
            className="product-img" src={imgs} loading='lazy' />
          {/* </div> */}


          <p style={{fontSize:"14px"}} id='product-name-div'>{productName}</p>
          <div style={{ display: "flex", direction: "rtl", justifyContent: "space-between", alignItems: "center" }}>
            {!isAuction && (
              <p style={{ direction: "rtl", color: "#F00057", marginBottom: "4px", fontSize:"14px" }}>{formatAccountingArabic(productPrice)}</p>
            )}
            {isAuction && (
              <>
                <p style={{ marginBottom: "0px", color: "#F00057", fontSize:"14px" }}>{formatAccountingArabic(currentBid)}</p>
              </>
            )}
          </div>
          {isAuction && (
            <div style={{ display: "flex", direction: "rtl", alignItems: "center", marginBottom: "2px", marginTop: "2px" }}>
      <div style={{display:"flex", justifyContent: "space-between", marginTop:"8px"}}>
        <PiGavelFill
    style={{color: "#00C6B7", marginTop:"2px"}}
    />
    <p style={{marginBottom: "0px", marginRight: "4px", color: "#F00057", fontSize:"14px",}}>{numBidders}</p>
    </div>
    <div style={{display: "flex",  marginRight:"auto", alignItems:"center", marginTop:"8px"}}>
    <Countdown
    className='product-countdown-class'
    date={expiryDate instanceof Timestamp ? new Date(expiryDate.toDate()).getTime() : expiryDate }

    >
      <p style={{ marginBottom: "0px", color: "#F00057", fontSize:"14px"}}>انتهى المزاد</p>
    </Countdown>
    <BsStopwatchFill size={15} color='#F00057' style={{marginRight: "4px", marginBottom:"2px"}}/>
        </div>
            </div>
          )}
          <div style={{ display: "flex", direction: "rtl", marginTop:"4px", marginBottom:"8px" }}>
            {condition && (
              <>
                <p style={{marginBottom: "0px", marginTop: "0px", color: "#00C6B7", fontSize:"14px"}}>الحالة: </p>
                <p style={{ marginBottom: "0px", color: "#F00057", marginRight: "4px",  fontSize:"14px" }}>{condition}</p>
              </>
            )}
          </div>
          <button id='my-button' style={{ width: "100%" }}>تفاصيل الطلب</button>
        </div>
      </Link>
    </div>
  );
}

export default Order;
