import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, query, where, doc, getDoc, onSnapshot } from 'firebase/firestore'; // Assuming Firebase
import { db, auth, storage } from '../config/firebase';
import { MdInfo, MdSentimentVerySatisfied } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
  import LoadingScreen from './LoadingScreen';
  import { GoHomeFill, GoReport } from "react-icons/go";
  import { getFunctions, httpsCallable } from "firebase/functions";
  import { Oval } from 'react-loader-spinner'
import { orderBy } from 'lodash';
import SuccessAnim from "../components/SuccessAnim"
import { FaPrint } from "react-icons/fa";
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { IoIosCloseCircle } from "react-icons/io"; 
import * as XLSX from 'xlsx';
import excelFile from '../assets/excel/ServedCities.xlsx'; // Import the Excel file
import { MdModeEdit } from "react-icons/md";
import { FaTrashCan } from "react-icons/fa6";
import { onAuthStateChanged } from "firebase/auth";


function MySoldProductsScreen() {
  const [orderData, setOrderData] = useState(null); // State for order data
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState("")
  const [myId, setMyId] = useState("")
  const [productImg, setProductImg] = useState("")
  const [productName, setProductName] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [productCategory, setProductCategory] = useState("")
  const [orderAmount, setOrderAmount] = useState("")
  const [postType, setPostType] = useState([])
  const [buyerId, setBuyerId] = useState("")
  const [entryFee, setEntryFee] = useState("")
  const [bidders, setBidders] = useState([])
  const [city, setCity] = useState("")
  const [neighbourhood, setNeighbourhood] = useState("")
  const [street, setStreet] = useState("")
  const [notes, setNotes] = useState("")

  const navigate = useNavigate();
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  
  const {id} = useParams();
  const [postId, setPostId] = useState(id);
  const [isReceived, setIsReceived] = useState("");
  const [isRejected, setIsRejected] = useState("");

  const [productData, setProductData] = useState("");

  const [orderStatus, setOrderStatus] = useState("");
  const [updateOrderStatusLoading, setUpdateOrderStatusLoading] = useState(false)



  const [cityHasError, setCityHasError] = useState(false);
  const [neighbourhoodHasError, setNeighbourhoodHasError] = useState(false);


  const handleAnimationComplete = () => {
    setShowSuccessAnim(false); // Reset button state after animation completes
  };


  const [productWeight, setProductWeight] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [newReceiverName, setNewReceiverName] = useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");

const [createShipmentLoading, setCreateShipmentLoading] = useState(false)
const [printShipmentLoading, setPrintShipmentLoading] = useState(false)
const [successMessage, setSuccessMessage] = useState('');
const [awbNo, setAWBNo] = useState("");
const [hasCreatedShipment, setHasCreatedShipment] = useState(false)
const [notMyOrder, setNotMyOrder]= useState(false)

const [receiverAddress,setReceiverAddress] = useState({})
const [senderAddress,setSenderAddress] = useState({})

const [fullName, setFullName] = useState("")

const [buyerPhoneNumber, setBuyerPhoneNumber] = useState("")

const cityRef = useRef();
const neighbourhoodRef = useRef();
const streetRef = useRef();
const notesRef = useRef();

const [senderName, setSenderName] = useState("")
const [newSenderName, setNewSenderName] = useState("")

const [showAddAddress, setShowAddAddress] = useState(false);
          
const [addressShouldRender, setAddressShouldRender] = useState(false);


useEffect(() => {
if (showAddAddress) {
setAddressShouldRender(true);
}
}, [showAddAddress]);

useEffect(() => {
if (!showAddAddress) {
setTimeout(() => setAddressShouldRender(false), 500); // Duration matches the animation time
}
}, [showAddAddress]);

const [cities, setCities] = useState([]);
const [myAddress, setMyAddress] = useState();

useEffect(() => {
  const fetchCities = async () => {
    try {
      // Fetch the Excel file from the imported URL
      const response = await fetch(excelFile);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // console.log('Excel file fetched successfully.');

      // Convert the response to an ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();
      // console.log('ArrayBuffer read from file.');

      // Parse the ArrayBuffer using XLSX
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      // console.log('Workbook parsed:', workbook);

      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      // console.log('Sheet name:', sheetName);
      
      const sheet = workbook.Sheets[sheetName];
      // console.log('Sheet:', sheet);
      
      // Read sheet data as array of arrays
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      // console.log('Sheet data:', data);

      // Extract city names assuming cities are in the first column
      const cityList = data.map(row => row[0]).filter(city => city);
      // console.log('City list:', cityList);
      
      setCities(cityList);
    } catch (error) {
      console.error('Error fetching or parsing the Excel file:', error);
    }
  };

  fetchCities();
}, []);

const [saveAddressIsLoading, setSaveAddressIsLoading] = useState(false);

const [addressMode, setAddressMode] = useState("Add");

const [myPhoneNumber, setMyPhoneNumber] = useState("")
const [senderPhoneNumber, setSenderPhoneNumber] = useState(myPhoneNumber)
const [senderNameHasError, setSenderNameHasError] = useState(false)
const [phoneNumberHasError, setPhoneNumberHasError] = useState(false)

const myPhoneNumberRef = useRef();
const nameRef = useRef();

 
const handleChange = (e) => {
  setCityHasError(false);

  if(receiverOrSenderAddressUpdate === "Sender"){
    // Update senderAddress.city with the new value
    setSenderAddress(prevState => ({
      ...prevState,
      city: e.target.value,
    }));
  }
  else{
  setReceiverAddress(prevState => ({
    ...prevState,
    city: e.target.value,
  }));}
};

const [newReceiverNeighborhood, setNewRecieverNeighborhood] = useState("")
const [newReceiverStreet, setNewRecieverStreet] = useState("")
const [newReceiverNotes, setNewRecieverNotes] = useState("")

const [newSenderNeighborhood, setNewSenderNeighborhood] = useState("")
const [newSenderStreet, setNewSenderStreet] = useState("")
const [newSenderNotes, setNewSenderNotes] = useState("")

useEffect(() => {
  window.scrollTo({
top: 0,
left: 0,
behavior: 'instant'
});
}, []);


const [signUpPhoneNumber, setSignUpPhoneNumber] = useState("");

useEffect(() => {
  const handleAuthStateChange = async (user) => {
    if (user) {
      try {
        setIsLoading(true);

        const docRef = doc(db, 'Users', user.uid);

        // Try to read from cache first
        const cachedDoc = await getDoc(docRef, { source: 'cache' }).catch(() => null);

        if (cachedDoc && cachedDoc.exists()) {
          // Read from server if cache is not available
          const docSnapshot = await getDoc(docRef);
          if (!docSnapshot.exists()) {
            // Document doesn't exist, create it
            try {
              const userDetails = {
                phoneNumber: `+966${signUpPhoneNumber}`,
                uid: auth.currentUser.uid,
              };

              const functions = getFunctions();
              const createUserDoc = httpsCallable(functions, 'createUserDoc');
              const response = await createUserDoc(userDetails);

              console.log("createUserDoc function response:", response);

              if (response.data.success) {
                console.log("User document created with ID:", response.data.userId);
              } else {
                console.error("Error creating user document:", response.data.error);
              }
            } catch (error) {
              console.error("Error calling createUserDoc function:", error);
            }
          }
        }

        // Real-time updates
        const unsubscribeUser = onSnapshot(docRef, (docu) => {
          if (docu.exists()) {
            const userData = docu.data();
            setMyId(userData.id)
            // setUserData(userData);
          } else {
            console.error('User data not found');
          }
        });

        // Ensure loader is set to false
        setIsLoading(false);

        return () => {
          if (unsubscribeUser) unsubscribeUser();
        };
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        // setUserInfoLoading(false);
        // setFetching(false); // Ensure fetching state is updated
      }
    } else {
      setIsLoading(false);
      // setUserInfoLoading(false);
      // setFetching(false);
    }
  };

  // Monitor auth state changes
  const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChange);

  return () => {
    if (unsubscribeAuth) unsubscribeAuth();
  };
}, [signUpPhoneNumber]); // Include any dependencies, e.g., `signUpPhoneNumber`



useEffect(() => { 
  if (myId && id) {
    setIsLoading(true); // Start loading before fetching data

    const fetchOrderAndProduct = async (orderDoc) => {
      try {
        const order = orderDoc.data();
        // Fetch and set order and product data...

        const productDocRef = doc(db, "Products", order.productId.toString());
        const productDoc = await getDoc(productDocRef);

        if (productDoc.exists()) {
          const productData = productDoc.data();
          setProductImg(productData.imgs[0]);
          setProductName(productData.productName);
          setProductDescription(productData.productDescription);
          setProductCategory(productData.category);
          setProductWeight(productData.productWeight);
          setProductData(productData);
          setOrderAmount(order.amount);
          setDeliveryOption(order.deliveryOption);
          setReceiverName(order.receiverName)
          setReceiverAddress(order.receiverAddress);
          setSenderName(order.senderName);
          setIsRejected(order.isRejected)
          setIsReceived(order.isReceived)
          setSenderAddress(order.senderAddress)
          setSenderPhoneNumber(order.senderPhoneNumber)
          setReceiverPhoneNumber(order.receiverPhoneNumber)
          setAWBNo(order.awbNo)
          setOrderStatus(order.order_status)
        } else {
          console.error("No product document found!");
          setProductData(null);
        }
      } catch (err) {
        console.error("Error processing data:", err);
        setError(err);
      } finally {
        setIsLoading(false); // Data fetch complete, stop loading
      }
    };

    const orderQuery = query(
      collection(db, "Orders"),
      where("sellerId", "==", myId),
      where("id", "==", parseFloat(id)),
      where("type", "==", "Purchase"),
      where("payment_status", "==", "success")
    );

    const unsubscribeFromOrder = onSnapshot(
      orderQuery,
      (orderSnapshot) => {
        if (!orderSnapshot.empty) {
          const orderDoc = orderSnapshot.docs[0];
          fetchOrderAndProduct(orderDoc);
        } else {
          console.error("No matching order found");
          setOrderData(null);
          setProductData(null);
          setIsLoading(false); // No data, stop loading
        }
      },
      (err) => {
        console.error("Error fetching order data:", err);
        setError(err);
        setIsLoading(false); // Handle error, stop loading
      }
    );

    return () => unsubscribeFromOrder();
  } else if (!id) {
    // If authentication is checked but no myId
    setIsLoading(false); // No ID, stop loading
  }
}, [myId, id]);


const [trackingStatus, setTrackingStatus] = useState([]);
const [trackingLoading, setTrackingLoading] = useState([]);

const [newCity, setNewCity] = useState("")
const [newSenderPhoneNumber, setNewSenderPhoneNumber] = useState("")
const [newReceiverPhoneNumber, setNewReceiverPhoneNumber] = useState("")
const [receiverOrSenderAddressUpdate, setReceiverOrSenderAddressUpdate] = useState("");

const getSmsaTrackingDetails = async () => {
  const functions = getFunctions();
  const getSmsaTrackingDetails = httpsCallable(functions, 'getSmsaTrackingDetails');

  try {
    // console.log(`Requesting tracking details for AWB No: ${awbNo}`);

    const result = await getSmsaTrackingDetails({ awbNo });

    if (result.data.success) {
      // console.log('Tracking details fetched successfully:', result.data.trackingDetails);

      // Extract and set the Tracking array
      const trackingDetails = result.data.trackingDetails.Tracking || [];
      setTrackingStatus(trackingDetails);
    } else {
      console.error('Failed to fetch tracking details:', result.data.message);
    }
  } catch (error) {
    console.error('Error fetching tracking details:', error);
  } finally {
    setTrackingLoading(false)
  }
};

useEffect(()=>{
  if(awbNo){

    // console.log("Getting the tracking details for awbNo: ", awbNo)
    getSmsaTrackingDetails()
  }
}, [awbNo])

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Extract day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};


// if(!myId && !isLoading){
//   return <SignInOrSignUpScreen  />
// }

  if(notMyOrder){
    return <p>not my order</p>
  }



  if (error) {
    return (
      <div>
        <p>Error fetching data: {error.message}</p>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  
  if (auth.currentUser === null) {
    // console.log("returning sign in or sign up: ", myId);
    return <SignInOrSignUpScreen setSignUpPhoneNumber={setSignUpPhoneNumber} />;
  }
  
  
  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits:2,
      maximumFractionDigits: 2
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}

const updateOrderStatus = async () => {
  const functions = getFunctions();
  const updateOrderStatus = httpsCallable(functions, 'updateOrderStatus');

  try {
    setUpdateOrderStatusLoading(true)
    const result = await updateOrderStatus({ orderId: id, status: orderStatus });
    if(result.data.success){
      setSuccessMessage("!تم تحديث حالة الطلب بنجاح")
      setShowSuccessAnim(true)
    }
    // Handle success, e.g., update UI or state
  } catch (error) {
    console.error("Error updating order status:", error);
    // Handle error, e.g., show error message to the user
  } finally {
    setUpdateOrderStatusLoading(false)
  }
};

const createShipment = async () => {

  if(!senderName){
    alert("يرجى ادخال اسم المرسل")
    return;
  }

  if(!senderPhoneNumber){
    alert("يرجى ادخال رقم المرسل");
    return;
  }

  if(!senderAddress.city){
    alert("يرجى ادخال مدينة المرسل");
    return;
  }
  // Hardcoded shipment details, including sender and recipient information
  
  const shipmentDetails = {
    orderId: id,
    sender: {
      name: senderName,
      address: senderAddress.neighborhood,
      city: senderAddress.city,
      notes: senderAddress.notes,
      street: senderAddress.street,
      phone: senderPhoneNumber,
    },
    recipient: {
      name: receiverName,
      address: receiverAddress.neighborhood,
      city: receiverAddress.city,
      notes: receiverAddress.notes,
      street: receiverAddress.street,
      phone:  receiverPhoneNumber,
    },
    productWeight,
    productDescription: productDescription || "....",
    // Add any other necessary fields here
  };


  try {
    // Call the Firebase Cloud Function
    setCreateShipmentLoading(true)
    const response = await fetch('https://us-central1-men-ela-testing.cloudfunctions.net/createShipment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(shipmentDetails)
    });

    // Parse the response
    const data = await response.json();

    // Check if the request was successful
    if (response.ok) {
      // console.log('Shipment created successfully:', data);
      setCreateShipmentLoading(false)
      setHasCreatedShipment(true);
      setSuccessMessage("!تم إصدار البوليصة بنجاح");
      setShowSuccessAnim(true);
      return data;
    } else {
      console.error('Error creating shipment:', data);
      alert(data.error)
      setCreateShipmentLoading(false)
      return { error: data };
    }
  } catch (error) {
    console.error('Error calling createShipment function:', error);
    setCreateShipmentLoading(false)
    return { error: 'An error occurred while creating the shipment.' };
  } 

};

const printShipmentPDF = async () => {
  try {
    setPrintShipmentLoading(true);

    // Open a new tab in response to user interaction (button click)
    const newTab = window.open('', '_blank');

    const response = await fetch(`https://us-central1-men-ela-testing.cloudfunctions.net/getShipmentPDF?awbNo=${awbNo}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (response.ok) {
      if (data.pdfData) {
        const pdfData = data.pdfData;

        const pdfBinary = atob(pdfData);
        const pdfArray = Uint8Array.from(pdfBinary, char => char.charCodeAt(0));

        const pdfBlob = new Blob([pdfArray], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Update the newly opened tab with the PDF URL
        newTab.location.href = pdfUrl;
      } else {
        console.error('PDF data not found in response.');
        newTab.close(); // Close the tab if PDF data is not found
        return { error: 'PDF data not found in response.' };
      }
    } else {
      console.error('Error fetching PDF:', data.error);
      newTab.close(); // Close the tab if an error occurs
      return { error: data.error };
    }
  } catch (error) {
    console.error('Error calling getShipmentPDF function:', error);
    return { error: 'An error occurred while fetching the PDF.' };
  } finally {
    setPrintShipmentLoading(false);
  }
};

const updateAddress = async () => {
  const functions = getFunctions();
  const updateUserAddress = httpsCallable(functions, 'updateUserAddress');

  try {
    const result = await updateUserAddress({
      userId: auth.currentUser.uid,
      senderPhoneNumber:  senderPhoneNumber ? senderPhoneNumber : myPhoneNumber,
      receiverPhoneNumber: "",
      senderName: senderName,
      receiverName: "",
      city: city ? city : senderAddress.city,
      neighborhood: neighbourhood ? neighbourhood : senderAddress.neighborhood,
      street: street ? street : senderAddress.street,
      notes: notes ? notes : senderAddress.notes,
    });

    if (result.data.success) {
      console.log('Address updated successfully:', result.data.message);
    }
  } catch (error) {
    console.error('Error updating address:', error);
  }
};

const updateOrderSenderAddress = async () => {
  const functions = getFunctions();
  const updateOrderSenderAddress = httpsCallable(functions, 'updateOrderSenderAddress');

  try {
    const result = await updateOrderSenderAddress({
      orderId: id,
      city: city ? city : senderAddress.city,
      neighborhood: neighbourhood ? neighbourhood : senderAddress.neighborhood,
      street: street ? street : senderAddress.street,
      notes: notes ? notes : senderAddress.notes,
      senderPhoneNumber: newSenderPhoneNumber !== "" ? newSenderPhoneNumber : myPhoneNumber,
      senderName: newSenderName !== "" ? newSenderName : senderName
    });

    if (result.data.success) {
      // console.log('Address updated successfully:', result.data.message);
    } else {
      console.error('Update failed:', result.data.message);
    }
  } catch (error) {
    console.error('Error updating address:', error);
  }
};

const updateOrderReceiverAddress = async () => {
  const functions = getFunctions();
  const updateOrderReceiverAddress = httpsCallable(functions, 'updateOrderReceiverAddress');


  try {
    const result = await updateOrderReceiverAddress({
      orderId: id,
      city: receiverAddress.city,
      neighborhood: receiverAddress.neighborhood,
      street: receiverAddress.street,
      notes: receiverAddress.notes,
      receiverName: newReceiverName !== "" ? newReceiverName : receiverName,
      receiverPhoneNumber: newReceiverPhoneNumber !== "" ? newReceiverPhoneNumber : buyerPhoneNumber
    });

    if (result.data.success) {
      console.log('Address updated successfully:', result.data.message);
    } else {
      console.error('Update failed:', result.data.message);
    }
  } catch (error) {
    console.error('Error updating address:', error);
  }
};

const clearAddress = async () => {
  const functions = getFunctions();
  const clearUserAddress = httpsCallable(functions, 'clearUserAddress');

  try {
    const result = await clearUserAddress({
      userId: auth.currentUser.uid,
    });

    if (result.data.success) {
      console.log('Address cleared successfully:', result.data.message);
      setCity("")
      setReceiverName("")
    }
  } catch (error) {
    console.error('Error clearing address:', error);
  }
};

let arabicState = "";

  return (
    <div style={{minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
     
{showSuccessAnim && <SuccessAnim onAnimationComplete={handleAnimationComplete} message={successMessage} />}

                 {/* path */}
<div style={{width:window.innerWidth > window.innerHeight ? "80%" : "100%", margin:"auto", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
<div style={{
        backgroundColor: "#F7F7F7",
	      border: "1px solid #E8E9E9",
        width: "100%", 
        height:"100%",
        marginTop:"18px",
        padding: "11px",
        borderRadius:"8px",
        justifyContent:"center",
        marginRight:"auto",
        marginLeft:"auto"
      }}>
        <div style={{display:"flex", direction:"rtl", 
        }}>
                    <GoHomeFill size={18} style={{marginLeft:"6px", color:"#00C8B8", minHeight:"18px", minWidth:"18px"}} />

          <a href='/'>

          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
    >الرئيسية</h6>
    </a>
          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-2)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >حسابي</h6>

          <h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 style={{marginBottom:"0px", 
      color: "#01635c",
      cursor:"pointer",
      fontSize:"14px"
    }}
    onClick={()=>{navigate(-1)}}
    onMouseEnter={(e)=>{e.target.style.color = "#00A49B"}}
    onMouseLeave={(e)=>{e.target.style.color = "#01635c"}}
          >الطلبات</h6>

<h6 style={{margin:"0px 6px",
      fontSize:"14px"

          }}>/</h6>
          <h6 
                    style={{marginBottom:"0px", 
                    maxWidth: "100%",  /* Ensure the element does not exceed the container's width */
                    overflow: "hidden",  /* Hide any overflow content */
                    whiteSpace: "nowrap",  /* Prevent text from wrapping to a new line */
                    textOverflow: "ellipsis",  /* Display ellipsis if the text overflows */
                  fontSize:"14px"}}
          > طلب رقم # {id}</h6>
        </div>
      </div>
</div>
     
{addressShouldRender && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          opacity: showAddAddress ? 1 : 0,
          transition: "opacity 0.5s ease-out",

            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: window.innerWidth > window.innerHeight ? "35%" : "90%",
              height: "fit-content",
              backgroundColor: "white",
              position: "relative",
              margin: "auto",
              opacity: 1,
              zIndex: 2,
              borderRadius: "16px",
              padding: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              
            }}
            className={showAddAddress ? "pop-up" : "pop-down"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "8px",
              }}
            >
              <IoIosCloseCircle
                size={27}
                style={{ cursor: "pointer" }}
                color="#ccc"
                onClick={() => {
                  if(saveAddressIsLoading) return;
                  setNewSenderName(senderName)
                  setNewReceiverName(receiverName)
                  setNewSenderPhoneNumber(myPhoneNumber)
                  setNewReceiverPhoneNumber(receiverPhoneNumber)
                  setCity("")
                  cityRef.current.value = "";
                  neighbourhoodRef.current.value = "";
                  streetRef.current.value = "";
                  notesRef.current.value = "";
                  setCityHasError(false);
                  setNeighbourhoodHasError(false);
                  setShowAddAddress(false);
                }}
              />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h5 style={{ marginTop: "16px" }}>
                  {addressMode === "Add" ? "أضف عنوانك" : `تحديث عنوان ${receiverOrSenderAddressUpdate === "Sender" ? "المرسل" : "المستلم"}`}
                </h5>
              </div>
              <div style={{ width: 32, height: 32 }}></div> {/* Empty div for balance */}
            </div>
            <div style={{ width: "100%", padding: "0px 16px" }}>
              <div style={{ display: "flex", direction:"rtl" }}>
                <p style={{ color: "red" }}>*</p>
                <input
                  autoFocus
                  id={senderNameHasError ? "input-field-error" : "input-field"}
                  defaultValue={receiverOrSenderAddressUpdate === "Sender" ? senderName : receiverName} 
                  ref={nameRef}
                  placeholder= { receiverOrSenderAddressUpdate === "Sender"  ? "اسم المرسل" : "اسم المستلم"}
                  autoComplete='name'
                  style={{ paddingBottom: "8px" }}
                  onChange={(e) => { 
                    setSenderNameHasError(false); 
                    if(receiverOrSenderAddressUpdate === "Sender" )
                    setNewSenderName(e.target.value); 
                    else
                    setNewReceiverName(e.target.value)
                  }}
                />
                
              </div>
              <div style={{ display: "flex", direction:"rtl" }}>
                <p style={{ color: "red" }}>*</p>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%"}}>

                <input
                  id={phoneNumberHasError ? "input-field-error" : "input-field"}
                  defaultValue={receiverOrSenderAddressUpdate === "Sender" ? (senderPhoneNumber !== "" ? senderPhoneNumber : myPhoneNumber) : receiverPhoneNumber}
                  ref={myPhoneNumberRef}
                  type='tel'
                  autoComplete='phone'
                  placeholder={receiverOrSenderAddressUpdate === "Sender" ? "رقم المرسل" : "رقم المستلم"}
                  style={{ paddingBottom: "8px", direction:"ltr" }}
                  onChange={(e) => {

                    setPhoneNumberHasError(false)
                    const inputValue = e.target.value;
                  
                    // Check if input contains non-numeric characters
                    if (/[^0-9]/.test(inputValue)) {
                      alert("يرجى ادخال أرقام فقط");
                      myPhoneNumberRef.current.value = "";
                      return;
                    }
                  
                    // Filter out non-numeric characters and update the state
                    const numericValue = inputValue.replace(/\D/g, '');

                    if(receiverOrSenderAddressUpdate === "Sender"){
                      setNewSenderPhoneNumber(numericValue);
                    } else{
                      setNewReceiverPhoneNumber(numericValue)
                    }
                  }}
                  
                />
                  </div>
              </div>
              <div style={{ display: "flex", direction:"rtl" }}>
                <p style={{ color: "red" }}>*</p>
                {/* <input
                  id={cityHasError ? "input-field-error" : "input-field"}
                  defaultValue=""
                  ref={cityRef}
                  autoComplete='address-level2'
                  placeholder="المدينة"
                  style={{ paddingBottom: "8px" }}
                  onChange={(e) => { setCityHasError(false); }}
                /> */}
      <select ref={cityRef}
      defaultValue={receiverOrSenderAddressUpdate === "Sender" ? senderAddress.city : receiverAddress.city}
                  id={cityHasError ? "input-field-error" : "input-field"}
      
      style={{marginTop:"8px"}} onChange={handleChange}>
        <option value="">المدينة</option>
        {cities.map((city, index) => (
          <option style={{backgroundColor:"white"}} key={index} value={city}>
            {city}
          </option>
        ))}
      </select>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "16px",
                  justifyContent: "space-between",
                }}
              >

                <div style={{ width: "45%" }}>
                  <input
                  onChange={(e)=>{
                    if(receiverOrSenderAddressUpdate === "Sender"){
                      setSenderAddress(prevState => ({
                        ...prevState,
                        street: e.target.value,
                      }));
                    }
                    else{
                      setReceiverAddress(prevState => ({
                        ...prevState,
                        street: e.target.value,
                      }));
                    }
                  }}
                    defaultValue={receiverOrSenderAddressUpdate === "Sender" ? senderAddress.street : receiverAddress.street}
                    id="input-field"
                    autoComplete='address'
                    placeholder="الشارع (اختياري)"
                    ref={streetRef}
                    style={{ paddingBottom: "8px" }}
                  />
                </div>
                <div style={{ width: "45%", direction:"rtl" }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ marginBottom: "0px", color: "red" }}>*</p>
                    <input
                      defaultValue={receiverOrSenderAddressUpdate === "Sender" ? senderAddress.neighborhood : receiverAddress.neighborhood}
                      id={neighbourhoodHasError ? "input-field-error" : "input-field"}
                      ref={neighbourhoodRef}
                      autoComplete='none'
                      placeholder="الحي"
                      style={{ paddingBottom: "8px" }}
                      onChange={(e) => {
                        if(receiverOrSenderAddressUpdate === "Sender"){
                          setSenderAddress(prevState => ({
                            ...prevState,
                            neighborhood: e.target.value,
                          }));
                        }
                        else{
                          setReceiverAddress(prevState => ({
                            ...prevState,
                            neighborhood: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>  
              </div>
              <input
                defaultValue={receiverOrSenderAddressUpdate === "Sender" ? senderAddress.notes : receiverAddress.notes}
                onChange={(e)=>{
                  if(receiverOrSenderAddressUpdate === "Sender"){
                    setSenderAddress(prevState => ({
                      ...prevState,
                      notes: e.target.value,
                    }));
                  }
                  else{
                    setReceiverAddress(prevState => ({
                      ...prevState,
                      notes: e.target.value,
                    }));
                  }
                }}
                id="input-field"
                autoComplete='none'
                placeholder="ملاحظات (اختياري)"
                ref={notesRef}
                style={{ paddingBottom: "8px", marginTop: "16px" }}
              />
            </div>
            <button
              id="my-button"
              disabled={saveAddressIsLoading}
              style={{
                height: "40px",
                marginTop: "8px",
                marginBottom: "16px",
                width: window.innerWidth > window.innerHeight ? "50%" : "70%",
              }}
              onClick={async () => {

                if(nameRef.current){
                  if(nameRef.current.value === "")
                  setSenderNameHasError(true);
                }

                if(myPhoneNumberRef.current.value === ""){
                  setPhoneNumberHasError(true)
                }

                if (cityRef.current.value === "") {
                  setCityHasError(true);
                }
                if (neighbourhoodRef.current.value === "") {
                  setNeighbourhoodHasError(true);
                }

                if (
                  nameRef.current.value !== "" &&
                  myPhoneNumberRef.current.value !== "" &&
                  cityRef.current.value !== "" &&
                  neighbourhoodRef.current.value !== ""
                ) {
                  setSaveAddressIsLoading(true);
                  // console.log(receiverOrSenderAddressUpdate)
                  if(receiverOrSenderAddressUpdate === "Sender"){
                    await updateAddress();
                    await updateOrderSenderAddress()
                  } else if (receiverOrSenderAddressUpdate === "Receiver"){
                    console.log("receiver called ")
                    await updateOrderReceiverAddress()
                  }
                  else return;
                  // try {
                  //   await updateDoc(userDocRef, {
                  //     "address.city": cityRef.current.value,
                  //     "address.neighbourhood": neighbourhoodRef.current.value,
                  //     "address.street": streetRef.current.value,
                  //     "address.notes": notesRef.current.value,
                  //     receiverName: receiverName,
                  //   });
                  //   console.log("Address updated successfully.");
                  // } catch (error) {
                  //   console.error("Error updating address:", error);
                  // }
                  setShowAddAddress(false);
                }
                // if (!saveMyAddress) {
                //   if (cityRef.current.value === "" || neighbourhoodRef.current.value === "")
                //     return;
                //   setCity(cityRef.current.value);
                //   setNeighbourhood(neighbourhoodRef.current.value);
                //   setStreet(streetRef.current.value);
                //   setNotes(notesRef.current.value);

                //   cityRef.current.value = "";
                //   neighbourhoodRef.current.value = "";
                //   streetRef.current.value = "";
                //   notesRef.current.value = "";

                //   setShowAddAddress(false);
                // }
                setSaveAddressIsLoading(false);
              }}
            >
              {saveAddressIsLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display:"flex"
                  }}
                >
                  <Oval
                    height={"20px"}
                    width={"20px"}
                    color={"white"}
                    wrapperClass=""
                    visible={true}
                    wrapperStyle={{ display: "block" }}
                    ariaLabel="oval-loading"
                    secondaryColor="#ccc"
                    strokeWidth={1}
                    strokeWidthSecondary={1}
                  />
                </div>
              ) : (
                <p style={{ marginBottom: "0px" }}>
                  {addressMode === "Add" ? "إضافة العنوان" : "تحديث العنوان"}
                </p>
              )}
            </button>
          </div>
        </div>
      )}


       <div style={{display: "flex", flexDirection:  window.innerWidth > window.innerHeight ? "row" : "column",justifyContent: window.innerWidth > window.innerHeight ? "space-between" : "start",direction: "rtl", width: window.innerWidth > window.innerHeight ? "80%" : "100%", marginLeft:"auto", marginRight:"auto", height: "fit-content", position:"relative", paddingBottom:"32px", minHeight: window.innerWidth > window.innerHeight ? "calc(100vh - 65px)" : "calc(100vh - 110px)"}}>
     
                               <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding:window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                       <div>
                               <h5 style={{marginTop:"16px"}}>ملخص الطلب</h5>
                               <div style={{ height: "fit-content", border : "1.5px solid #00C8B8", borderRadius: "12px", padding: "8px"}}>
                                       <h6 style={{marginRight: "8px"}}>{`طلب رقم#   ${id}`}</h6>
     
                                       <div style={{width: "95%", display: "flex"}}>
         <img src={productImg} alt="" style={{width: "30%", borderRadius: "12px", objectFit: productCategory !== "لوح سيارات" && productCategory !== "ارقام جوالات" ? "cover" : "scale-down", height: window.innerWidth > window.innerHeight ? "150px" : "100px"}} />
         <div style={{marginRight: "8px", width: "70%"}}>
           <p style={{
             marginBottom: "4px",
             fontWeight: "500",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 8,
             overflow: "hidden"
           }}>
             {productData.productName}
           </p>
           <p style={{
             marginBottom: "0px",
             fontSize: "14px",
             color: "gray",
             display: "-webkit-box",
             WebkitBoxOrient: "vertical",
             WebkitLineClamp: 10,
             overflow: "hidden"
           }}>
             {productDescription}
           </p>
           <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
             <p style={{marginBottom: "0px"}}>{formatAccountingArabic(orderAmount)}</p>
           </div>
         </div>
         
       </div>
     
                               </div>
     
     {
       productCategory !== "ارقام جوالات" && productCategory !== "لوح سيارات" && (
        <div style={{height:"fit-content", width:"100%", marginBottom:"24px", borderRadius:"8px", direction:"rtl", margin:"16px auto", marginTop:"32px"}}>
  <h5 style={{marginBottom:"8px"}}>طرق الشحن و التوصيل</h5>

  <div style={{ backgroundColor: "#f7f7f7", padding: "24px 16px", borderRadius: "8px", alignItems: "center", width:"100%"}}>
  
  {
    deliveryOption === "شحن" && (
      <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center"}}>
      <FaShippingFast color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
      <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>الشحن السريع</p>
    </div>
    )
  }

{
  deliveryOption === "تسليم" && (
  <div style={{ display: "flex", width:window.innerWidth > window.innerHeight ? "50%" :"100%", alignItems:"center", marginTop:"0px"}}>
    <FaRegHandshake color='#00C8B8' size={32} style={{ marginBottom: "0px", marginLeft: "8px" }} />
    <p style={{ marginBottom: "0px", fontSize: "16px", fontWeight: 500 }}>التسليم وجهاً لوجه</p>
  </div>
  )
}

  </div>
  
{
  deliveryOption === "تسليم" && (
    <div style={{padding:"8px", display: "flex", backgroundColor: "#fff0f2" ,marginBottom: "16px", marginTop: "8px", borderRadius:"8px"}}>
        <div>
          <MdInfo size={17} color='#ff7385' style={{minHeight:"17px", minWidth: "17px"}} />
        </div>

        <div>
        <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>نوصي بالتقاء المشتري في مكان عام لتسليم المنتج و توخي الحذر لأي ظرف كان, و التأكد من ان المشتري فحص المنتج في حينه, و تجنب تلقي اي وسيلة دفع خارج المنصة.</p>
        <p style={{ color: "black", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>منصة (بيع) تخلي مسؤوليتها في حال عدم اتباع اياً من المذكور أعلاه.</p>
        </div>
    </div>
  )
}
</div>
       )
     }

                              {
                                deliveryOption === "شحن" && (
                                  <>
                                  <div style={{display:"flex", marginTop: "24px"}}>
     
                                  <h5 style={{fontWeight: "bold", color: "#444" }}>عنوان المستلم</h5>
                                  </div>

                                  

                                  <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px", textAlign:"right"}}>
      <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{receiverName}</p>    
      <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px",direction:"ltr", marginLeft:"auto"}}>{receiverPhoneNumber}</p>    

        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{receiverAddress.city}</p>    
        <p style={{ marginBottom: "8px", color: "gray" }}>
        {`حي ${receiverAddress.neighborhood}${receiverAddress.street ? `, شارع ${receiverAddress.street}` : ''}`}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{receiverAddress.notes}</p>
      </div>
      {
        awbNo === "" && (
          <div style={{display: "flex", position:"absolute", left: 0 , top: 0, width: "100%", padding:"12px", justifyContent:"flex-end"}}>
          <MdModeEdit size={17} color='gray' style={{cursor:"pointer", minHeight:"17px", minWidth:"17px", marginLeft:"8px"}} onClick={()=>{setReceiverOrSenderAddressUpdate("Receiver"); setShowAddAddress(true); setAddressMode("Edit")}} />
          {/* <FaTrashCan size={17} color='gray'  style={{cursor:"pointer", minHeight:"17px", minWidth:"17px"}} onClick={async ()=>{
            if (window.confirm("حذف العنوان الحالي؟")) {
              if(city && neighbourhood){
                setCity("");
                setNeighbourhood("");
                setStreet("");
                setNotes("");
              } else {
                // const userDocRef = doc(db, "Users", auth.currentUser.uid);
              //   try {
              //     await updateDoc(userDocRef, {
              //     'address.city': "",
              //     'address.neighbourhood': "",
              //     'address.street': "",
              //     'address.notes': "" 
              //   });
              //   console.log('Address updated successfully.');
              // } catch (error) {
              //   console.error('Error updating address:', error);
              // }
              clearAddress();
              }
          } else {
              console.log("Item deletion cancelled.");
          }
          }}/> */}
        </div>
        )
      }
    </div>
    {
      senderAddress.city && (
        <>
            <div style={{display:"flex", marginTop: "24px"}}>
     
     <h5 style={{fontWeight: "bold", color: "#444" }}>عنوان المرسل</h5>
     </div>


     <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1.5px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
      <IoLocation size={32} color='#00C8B8' />
      <div style={{marginRight: "8px", textAlign:"right"}}>
        <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px"}}>{senderName}</p>    
        <p style={{fontSize: "16px", fontWeight: "500", marginBottom: "0px", direction:"ltr"}}>{senderPhoneNumber}</p>    
        <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>{senderAddress?.city}</p>    
        <p style={{ marginBottom: "8px", color: "gray" }}>
          {`حي ${senderAddress.neighborhood} ${senderAddress.street ? `, شارع ${senderAddress.street}` : ""}`}
        </p>
        <p style={{marginBottom:"0px", color:"gray"}}>{senderAddress.notes}</p>
      </div>
      {
        awbNo === "" && (
          <div style={{display: "flex", position:"absolute", left: 0 , top: 0, width: "100%", padding:"12px", justifyContent:"flex-end"}}>
          <MdModeEdit size={17} color='gray' style={{cursor:"pointer", minHeight:"17px", minWidth:"17px", marginLeft:"8px"}} onClick={()=>{setReceiverOrSenderAddressUpdate("Sender"); setShowAddAddress(true); setAddressMode("Edit")}} />
          {/* <FaTrashCan size={17} color='gray'  style={{cursor:"pointer", minHeight:"17px", minWidth:"17px"}} onClick={async ()=>{
            if (window.confirm("حذف العنوان الحالي؟")) {
              if(city && neighbourhood){
                setCity("");
                setNeighbourhood("");
                setStreet("");
                setNotes("");
              } else {
                // const userDocRef = doc(db, "Users", auth.currentUser.uid);
              //   try {
              //     await updateDoc(userDocRef, {
              //     'address.city': "",
              //     'address.neighbourhood': "",
              //     'address.street': "",
              //     'address.notes': "" 
              //   });
              //   console.log('Address updated successfully.');
              // } catch (error) {
              //   console.error('Error updating address:', error);
              // }
              clearAddress();
              }
          } else {
              console.log("Item deletion cancelled.");
          }
          }}/> */}
        </div>
        )
      }
    </div>
        </>
      )
    }
    {
  senderAddress.city ? (
    awbNo === "" ? (
      <button
        id="my-button"
        disabled={createShipmentLoading}
        onClick={createShipment}
        style={{ backgroundColor: "#F00057", width: "100%", marginTop: "8px" }}
      >
        {createShipmentLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block" }}
              ariaLabel="oval-loading"
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
          </div>
        ) : (
          <p style={{ marginBottom: "0px" }}>إصدار بوليصة الشحن</p>
        )}
      </button>
    ) : (
      <button
        id="my-button"
        disabled={printShipmentLoading}
        onClick={printShipmentPDF}
        style={{ width: "100%", marginTop: "8px" }}
      >
        {printShipmentLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{ display: "block" }}
              ariaLabel="oval-loading"
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
          </div>
        ) : (
          <p style={{ marginBottom: "0px" }}>
            طباعة البوليصة <FaPrint
              size={16}
              style={{
                marginRight: "4px",
                marginBottom: "2px",
                minHeight: "16px",
                minWidth: "16px",
              }}
            />
          </p>
        )}
      </button>
    )
  ) : (
    <button
      onClick={() => {
        setReceiverOrSenderAddressUpdate("Sender")
        setShowAddAddress(true);
        setAddressMode("Add");
        // setSaveMyAddress(true);
      }}
      id="my-button"
      style={{ height: "35px", width: "100%", marginTop: "16px" }}
    >
      أضف عنوانك لاصدار بوليصة الشحن 
    </button>
  )
}


<div
              style={{
                textAlign: 'center',
                position: "relative",
                borderRadius: "16px",
                width: "100%",
                padding: "16px 8px",
                backgroundColor: "#F7F7F7",
                textAlign: 'right',
                display:"flex",
                marginTop:"8px"
              }}
            >
                  <MdInfo size={20} color='#a0a0a0' style={{minHeight:"20px", minWidth:"20px"}} />
                  <div style={{marginRight:"4px"}}>
                    
              <p>
                في حال تم إصدار بوليصة الشحن لن تتمكن من تعديل عنوان المرسل أو المستلم الا في حالة التواصل مع خدمة عملاء شركة الشحن.
                <br />
                <br />
                <span>رقم التواصل: <span style={{fontWeight:500, direction:"ltr"}}>966112892999+</span></span>
                </p>

                  </div>
            </div>


    <div style={{display:"flex", marginTop: "24px"}}>
    <h5 style={{fontWeight: "bold", color: "#444" }}>تتبع الشحنة {awbNo && `# ${awbNo}`}</h5>
     </div>

     <div>
      
     {
  trackingLoading ? (
    <div
      style={{
        marginTop: "55px",
        display: 'flex',
        width: "fit-content",
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: "8px",
      }}
    >
      <Oval
        height={"30px"}
        width={"30px"}
        color={"#00C8B8"}
        wrapperClass=""
        visible={true}
        wrapperStyle={{ display: "block" }}
        ariaLabel='oval-loading'
        secondaryColor="#ccc"
        strokeWidth={1}
        strokeWidthSecondary={1}
      />
    </div>
  ) : (
    trackingStatus.length > 0 ? (
      trackingStatus.map((update, index) => {
        let arabicState = ''; // Initialize arabicState variable

        const activity = update.Activity.toLowerCase();

        switch (activity) {
          case 'data received':
            arabicState = 'تم استلام بيانات الشحنة';
            break;
          case 'picked up':
            arabicState = 'تم استلام الشحنة من المرسل';
            break;
          case 'departed form origin':
            arabicState = 'الشحنة غادرت الفرع';
            break;
          case 'arrived hub facility':
            arabicState = 'الشحنة وصلت لمحطة التوزيع';
            break;
          case 'departed hub facility':
            arabicState = 'الشحنة غادرت محطة التوزيع';
            break;
          case 'out for delivery':
            arabicState = 'جاري توصيل الشحنة للعميل';
            break;
          case 'consignee no response':
            arabicState = 'فشل التواصل مع العميل';
            break;
          case 'contact no. not belongs to consignee':
            arabicState = 'الرقم المسجل بالشحنة مختلف عن رقم العميل';
            break;
          case 'incorrect delivery address':
            arabicState = 'عنوان العميل خاطئ';
            break;
          case 'at smsa facility':
            arabicState = 'الشحنة وصلت الفرع';
            break;
          case 'at smsa retail center':
            arabicState = 'الشحنة وصلت الفرع';
            break;
          case 'awaiting consignee for collection':
            arabicState = 'بانتظار استلام العميل من الفرع';
            break;
          case 'proof of delivery captured':
            arabicState = 'تم التوصيل';
            break;
          case 'consignee mobile off':
            arabicState = 'جوال العميل مغلق';
            break;
          default:
            arabicState = update.Activity; // Fallback to the original activity if no match
            break;
        }

        return (
          <div key={index} style={{ direction: "rtl" }}>
            <p><strong>حالة الشحنة:</strong> {arabicState}</p>
            <p><strong>الوقت:</strong> {formatDate(update.Date)}</p>
            <hr />
          </div>
        );
      })
    ) : (
      <p>لم يتم إصدار بوليصة الشحن بعد.</p>
    )
  )
}

</div>

                                  </>
                                )
                              }
                               
                               </div>
                               
                               </div>
                               <div style={{width: window.innerWidth > window.innerHeight ? "47%" : "100%", padding: window.innerWidth > window.innerHeight ? "0px" : "0px 16px"}}>
                                       <h5  style={{fontWeight: "bold", color: "#444", marginTop: window.innerWidth > window.innerHeight ? "16px" : "42px", }}>ملخص المبلغ</h5>
     
                                       <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
       <div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
       <h6>المبلغ</h6>
       <h6>{formatAccountingArabic(orderAmount)}</h6>  
       </div>
                                       </div>


                                       {
       productCategory !== "ارقام جوالات" && productCategory !== "لوح سيارات" && (
         <>

                                 </>
                               )}
                               <h5 style={{marginTop:"16px"}}>حالة الطلب</h5>
                               <select disabled={orderStatus !== "جديد" && orderStatus !== "تم الشحن" && orderStatus !== "تم التسليم"} onChange={(e)=>{setOrderStatus(e.target.value)}} defaultValue={orderStatus} 
              style={{ color: "black", borderRadius: "4px", marginBottom:"8px", cursor:"pointer" }}
              id="input-field"
                               
                               >
                                <option style={{backgroundColor:"white", color:"black"}} value="جديد">جديد</option>
                                {
                                  orderStatus !== "جديد" && orderStatus !== "تم الشحن" && orderStatus !== "تم التسليم" && (
                                    <option style={{backgroundColor:"white", color:"black"}} value={orderStatus}>{orderStatus}</option>
                                  )
                                }
                                {
                                  deliveryOption === "شحن" ? (
                                    <option style={{backgroundColor:"white", color:"black"}} value="تم الشحن">تم الشحن</option>
                                  ) : (
                                    <option style={{backgroundColor:"white", color:"black"}} value="تم التسليم">تم التسليم</option>
                                  )
                                }
                              </select>
                              {
                                deliveryOption === "شحن" && orderStatus !== "جديد" && orderStatus !== "تم الشحن" &&  (
                                  <div
                                  style={{
                                    textAlign: 'center',
                                    position: "relative",
                                    borderRadius: "16px",
                                    width: "100%",
                                    padding: "16px 8px",
                                    backgroundColor: "#F7F7F7",
                                    textAlign: 'right',
                                    display:"flex",
                                    marginTop:"8px"
                                  }}
                                >
                                      <MdInfo size={20} color='#a0a0a0' style={{minHeight:"20px", minWidth:"20px"}} />
                                      <div style={{marginRight:"4px"}}>
                                        
                                  <p style={{marginBottom:"0px"}}>
                                    سيتم تحديث حالة الطلب من قبل شركة الشحن عندما يكون هناك تحديثات.
                                    <br />
                                    </p>  
                    
                                      </div>
                                </div>
                                )
                              }
                              {
                                (orderStatus === "جديد" || orderStatus === "تم الشحن" || orderStatus === "تم التسليم") && (
                                  <button onClick={()=>{updateOrderStatus()}} id='my-button' style={{height:"35px", width:"100%", marginTop:"16px"}}>
                                    {
                                      updateOrderStatusLoading ? (
                                        <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          display:"flex"
                                        }}
                                      >
                                        <Oval
                                          height={"20px"}
                                          width={"20px"}
                                          color={"white"}
                                          wrapperClass=""
                                          visible={true}
                                          wrapperStyle={{ display: "block" }}
                                          ariaLabel="oval-loading"
                                          secondaryColor="#ccc"
                                          strokeWidth={1}
                                          strokeWidthSecondary={1}
                                        />
                                      </div>
                                      ) : (
                                        <p style={{marginBottom:"0px"}}>حفظ</p>
                                      )
                                    }
                                  </button>
                                )
                              }

                              {
                                isRejected && (
                                  <h6 style={{marginTop:"8px"}}>تم رفض استلام المنتج, سيقوم فريقنا بالتواصل معك للتحقق.</h6>
                                )
                              }
                              {
                                isReceived && (
                                  <h6 style={{marginTop:"8px"}}>تم استلام المنتج من المشتري و اضافة الرصيد الى حسابك</h6>
                                )
                              }
                               </div>
                       </div>
    </div>
  );
}

export default MySoldProductsScreen;
