import React from 'react'
import CustomNavbar from '../components/CustomNavbar';

function UserAgreement () {
    // window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'instant'
    //   });
        return (
            <div id='new-screens-div'>
                <h4>
شروط استخدام منصة بيع:</h4>
                
<ul style={{marginRight: "24px"}}> 
    <li style={{marginTop:"24px"}}><strong>صحة المنتج والتصنيف:</strong> يلتزم البائع بتحديد فئة صحيحة للمنتج المُعروض للبيع، وضمان دقة وصحة المعلومات المقدمة حول المنتج.</li>

    <li style={{marginTop:"24px"}}><strong>حالة المنتج:</strong> يجب أن يكون المنتج صالحًا للاستخدام، وعلى البائع الكشف عن أي عيوب قد تؤثر على أدائه.</li>

    <li style={{marginTop:"24px"}}><strong>شروط الشحن:</strong> يتعهد البائع بإرسال المنتج أو شحنه عبر شركات الشحن المعتمدة بأسرع وقت ممكن.</li>

    <li style={{marginTop:"24px"}}><strong>ضمان حقوق المشتري:</strong> يجب على البائع توفير سياسة واضحة للاسترجاع والضمان، مع تأكيد حق المشتري في فحص المنتج والاعتراض على العيوب.</li>

    <li style={{marginTop:"24px"}}><strong>تأكيد استلام المنتج:</strong> يُطلب من المشتري تأكيد استلام المنتج بعد الشراء، مع التحقق من صحته وتطابقه مع الوصف.</li>

    <li style={{marginTop:"24px"}}><strong>حماية المعلومات الشخصية:</strong> تلتزم المنصة بحفظ خصوصية المعلومات الشخصية للبائعين والمشترين.</li>

    <li style={{marginTop:"24px"}}><strong>التفاعل السريع:</strong> يُشجع على التواصل السريع بين البائع والمشتري لحل أي مشكلة أو استفسار فورًا.</li>

    <li style={{marginTop:"24px"}}><strong>عدم الغش والتضليل:</strong> يمنع استخدام معلومات زائفة، ويُعاقب على أي نشاط يهدف إلى إيهام المشترين.</li>

    <li style={{marginTop:"24px"}}><strong>المسؤولية عن الأمان:</strong> تتحمل المنصة والبائعين المسؤولية الكاملة عن أمان المعلومات والمعاملات.</li>

    <li style={{marginTop:"24px"}}><strong>حق الإلغاء:</strong> يحتفظ المشتري بحق إلغاء الصفقة في حالة عدم التزام البائع بالشروط.</li>

    <li style={{marginTop:"24px"}}><strong>تعديل الشروط:</strong> تحتفظ المنصة بحق تعديل شروط الاستخدام مع توجيه إشعارات للأطراف المعنية.</li>
</ul>
            </div>
        );
}
 
export default UserAgreement;