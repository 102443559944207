import React, { useEffect, useState, useRef } from 'react'
import e from '../assets/electronics.avif';
import f from '../assets/furniture.jpg';
import { MdOutlineWatchLater } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";
import { Divider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {Link , useParams, useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import Countdown from 'react-countdown';
import { getStorage, ref, listAll, getDownloadURL,  } from "firebase/storage";
import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, query , updateDoc, serverTimestamp } from 'firebase/firestore';
import placeholder from '../assets/no-image.jpg';
import { MdDangerous } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import {storage} from '../config/firebase';
import { BsStopwatchFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import logo from '../assets/logo.jpg'
import {db} from '../config/firebase'
import { Oval } from 'react-loader-spinner'
import { getFunctions, httpsCallable } from 'firebase/functions';




  function MyProduct({
    postId, 
    imgs, 
    isSold, 
    isBidder, 
    productName, 
    highestBidder, 
    acceptsOffers,
    productDescription, 
    productPrice, 
    startingPrice,
    productSeller, 
    expiryDate, 
    condition, 
    currentBid,
    numBidders, 
    numBids, 
    link, 
    sellerCity, 
    buyerId, 
    myId, 
    isAuction, 
    category, 
    carPlateKind
  }) {

    //   const postRef = doc(db, 'Posts', `post${postId}`);

      // const [downloadURL, setDownloadURL] = useState(null);

      // useEffect(() => {

      //   const fetchImageURL = async () => {
      //       try {
      //         const url = await getDownloadURL(ref(storage, `Posts/post${postId}/1`));
      //         setDownloadURL(url);
      //       } catch (error) {
      //         console.error('Error fetching download URL:', error);
      //       }
      //     };
      
      //   //   fetchImageURL();
      // }, [])
      
    function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: number > 100 ? 0 : 2,
          maximumFractionDigits: number > 100 ? 0 : 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }

    const [imageLoaded, setImageLoaded] = useState(false);

    const skeletonRef = useRef(null);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
      if (skeletonRef.current) {
        skeletonRef.current.style.display = 'none';
      }
    };

  // useEffect(() => {
  //   if (imageLoaded) {
  //     // Hide the skeleton element here (using a ref or DOM manipulation)
  //     const skeletonElement = document.querySelector('.skeleton');
  //     if (skeletonElement) {
  //       skeletonElement.style.display = 'none';
  //     }
  //   }
  // }, [imageLoaded]);


  const [newProductName, setNewProductName] = useState(productName)
  const [newProductPrice, setNewProductPrice] = useState(productPrice)
  const [newProductCondition, setNewProductCondition] = useState(condition)
  const [updateProductLoading, setUpdateProductLoading] = useState(false)
  
  const updateProduct = async () => {
    if (newProductName === productName && parseFloat(newProductPrice) === parseFloat(productPrice) && newProductCondition === condition) return;
  
    try {
      setUpdateProductLoading(true);
      
      const functions = getFunctions();
      const updateProductFunction = httpsCallable(functions, 'quickUpdateProduct');
  
      const response = await updateProductFunction({
        postId,
        newProductName,
        newProductPrice,
        newProductCondition
      });
  
      console.log(response.data.message);
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      setUpdateProductLoading(false);
    }
  };
  

  const productDivRef = useRef(null);

  // useEffect(() => {
  //   if (productDivRef.current) {
  //     // Add the fade-in class after the component mounts
  //     const timer = setTimeout(() => {
  //       productDivRef.current.classList.add('fade-in');
  //     }, 0); // Small delay to ensure transition is applied
  //     return () => clearTimeout(timer); // Clean up timer on component unmount
  //   }
  // }, []); 

    return (
        <>
        <div style={{display: "block", minWidth:"100%", position:"relative", width:"100%"}}>

            <div  className='product-div'>
              {/* product top right icons */}
              <div style={{position:"absolute", right: -5, top: -8, justifyContent:"space-evenly"}}>
                {
                  acceptsOffers && (
                    <FaMoneyBillWave color='white' size={25} style={{padding:"4px", backgroundColor:"#00C8B8",  borderRadius:1000, marginLeft:"4px"}} />
                  )
                }

                {
                  isAuction && isBidder && (
                    highestBidder === myId ? (
                      <FaCheck color='white' size={28} style={{padding:"6px", backgroundColor:"#00C8B8",  borderRadius:1000, marginLeft:"4px"}} />
                      ) : 
                      <MdOutlineClose color='white' size={28} style={{padding:"2px", backgroundColor:"orange",  borderRadius:1000, marginLeft:"4px"}} />

                  )
                }

              </div>

<Link to={link}>
              <div className="image-container">
      {/* <div className="skeleton" ref={skeletonRef} style={{minHeight: window.innerWidth > window.innerHeight ? "200px" : "170px"}} />  */}
      <img
                  style={{
                    objectFit: (category === "لوح سيارات") ? "scale-down" : "cover",
                  }}
            className="product-img" src={imgs} loading='lazy' />
    </div>
    </Link>



                <input disabled={numBidders > 0} onChange={(e)=>setNewProductName(e.target.value)} className='input-field' defaultValue={productName} style={{width:"100%", border:"none", padding:"4px 8px", marginTop:"8px", borderRadius:"8px", fontWeight:500, fontSize:"14px"}} />
                {/* <h5 style={{width:"100%", overflow:"hidden", wordBreak:"break-word", textOverflow:"ellipsis", backgroundColor:"black"}}>testttttestttttestttt</h5> */}
                <div style={{ display: "flex", direction: "rtl", justifyContent: "space-between", alignItems: "center"}}>
              {!isAuction && (
                <div style={{display:"flex", alignItems:"center"}}>
                                <input onChange={(e)=>setNewProductPrice(parseFloat(e.target.value))} type='tel'  className='input-field' defaultValue={productPrice} style={{width:"100%", border:"none", padding:"4px 8px", marginTop:"8px", borderRadius:"8px", color:"#F00057", textAlign:"right", fontSize:"14px"}} />
                                <p style={{marginBottom:"0px", marginRight:"8px", color:"#F00057"}}>ر.س</p>
                    </div>
              )}

            {isAuction && (
              <>
              <p style={{marginBottom: "0px", color: "#F00057", marginTop:"4px", fontSize:"15px"}}>{formatAccountingArabic(currentBid)}</p>
                  </>
            )}
          </div>

  {isAuction && (
    <div style={{display: "flex", direction: "rtl", alignItems: "center", marginBottom: "2px", marginTop: "4px"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <PiGavelFill
    style={{color: "#00C6B7", marginTop:"2px"}}
    />
    <p style={{marginBottom: "0px", marginRight: "4px", color: "#F00057", fontSize:"14px",}}>{numBidders}</p>
    </div>
    <div style={{display: "flex",  marginRight:"auto", alignItems:"center"}}>
    <Countdown
    className='product-countdown-class'
    date={expiryDate instanceof Timestamp ? new Date(expiryDate.toDate()).getTime() : expiryDate }

    >
      <p style={{ marginBottom: "0px", color: "#F00057", fontSize:"14px"}}>انتهى المزاد</p>
    </Countdown>
    <BsStopwatchFill size={15} color='#F00057' style={{marginRight: "4px", marginBottom:"2px"}}/>
        </div>
    </div>
  )}
  <div style={{display: "flex", direction: "rtl"}}>

            {
              condition && (
                <div style={{marginTop:"8px", display:"flex", alignItems:"center", marginBottom:"16px", width:"100%"}}>
                <p style={{marginBottom: "0px", marginTop: "0px", color: "#00C6B7", fontSize:"14px"}}>الحالة: </p>
                <select disabled={numBidders > 0} onChange={(e)=>{setNewProductCondition(e.target.value)}} defaultValue={condition} style={{marginRight:"8px", border:"none", width:"100%", borderRadius:"8px", padding:"4px", alignItems:"center", color:"#F00057", outline:"none", fontSize:"14px"}}>
                    <option style={{backgroundColor:"white", color:"black"}} value="جديد">جديد</option>
                    <option style={{backgroundColor:"white", color:"black"}} value="مستعمل">مستعمل</option>
                    <option style={{backgroundColor:"white", color:"black"}} value="جيدة جدا">جيدة جدا</option>
                    <option style={{backgroundColor:"white", color:"black"}} value="جيدة">جيدة</option>
                    <option style={{backgroundColor:"white", color:"black"}} value="مقبولة">مقبولة</option>
                </select>
                </div>
              )
            }
  </div>


  <div style={{display:"flex", width:"100%", justifyContent:"center", marginTop: !condition ? "8px" : "0px"}}>

                <button disabled={updateProductLoading || numBidders > 0} id='my-button' style={{width:"60%", marginLeft:"8px"}}
                onClick={()=>{
                    updateProduct()
                }}
                >
                    {
    updateProductLoading ? <div style={{width:"100%", height:"100%", justifyContent:"center", alignItems:"center", display:"flex"}}>
    <Oval
                height={"16px"}
                width={"16px"}
                color={"white"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              /> 
    </div> : <p style={{marginBottom:"0px"}}>حفظ</p>
  }
                </button>

  <Link style={{width:"40%"}} to={link}>

                <button disabled={updateProductLoading || numBidders > 0} id='my-button' style={{width: "100%"}}>المزيد</button>
        </Link>
  </div>
            </div>
        
        </div>
        </>
    );
}
 
export default MyProduct;