// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, enableNetwork, disableNetwork } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  // production
  apiKey: "AIzaSyDRt2ZmqL-OuYmCpeU20-edrNAHhlZT_98",
  authDomain: "sell-production.firebaseapp.com",
  projectId: "sell-production",
  storageBucket: "sell-production.appspot.com",
  messagingSenderId: "58256674421",
  appId: "1:58256674421:web:69c4febe79710a47cff23c",
  measurementId: "G-E58ECV92CL"

  // testing
  // apiKey: "AIzaSyDCUbEibxiNqMFBmyAEsuHxA8esHWvE6N0",
  // authDomain: "sell-testing.firebaseapp.com",
  // projectId: "sell-testing",
  // storageBucket: "sell-testing.appspot.com",
  // messagingSenderId: "1061565056592",
  // appId: "1:1061565056592:web:fab641a002307ccddff355",
  // measurementId: "G-1DD2GK7FYR"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Export firestore, auth, and storage
// It will be imported into your React app whenever it is needed
export { db, auth, storage, enableNetwork, disableNetwork };
