import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CiEdit } from 'react-icons/ci';
import { FaRegTrashCan } from 'react-icons/fa6';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { doc, updateDoc, getDoc, collection, Timestamp, addDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';
import { Divider } from '@mui/material';
import { MdOutlineWatchLater } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { Oval } from 'react-loader-spinner'
import { FaStore } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io"; 
import { HiOutlineExclamationCircle } from "react-icons/hi";
import SuccessAnim from '../assets/animations/success.json'
import { getFunctions, httpsCallable } from "firebase/functions";
import { AiFillStar } from "react-icons/ai";
import RatingStar from '@mui/material/Rating';


function Rating({ 
    userName, // done
    userImg, // done
    ratingId, // done
    userRating, // done
    numRaters, // done
    link, // done
    timestamp, // done
    userId, // done
    isVerified, 
    isBusiness, 
    rating,
    notes,
    orderId
}) {
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;

  // Format the date
  const timeAgo = date ? formatDistanceToNow(date, { addSuffix: true, locale: arLocale }) : '';

  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [myUserId, setMyUserId] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false)
  const [commentOptionsLoading, setCommentOptionsLoading] = useState(false)
  const [shouldRender, setShouldRender] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => setShouldRender(false), 500); // Duration matches the animation time
  };

const[reportCommentShouldRender, setReportCommentShouldRender] = useState(false);
const[reportCommentIsOpen, setReportCommentIsOpen] = useState(false);

const [reportReason, setReportReason] = useState("");
const [reportNotes, setReportNotes] = useState("");
const [reportLoading, setReportLoading] = useState("");



  return (
    <>

    <div
    >
      <div
        style={{
          width: '100%',
          wordBreak: 'break-word',
          paddingTop: '8px',
          position: 'relative',
        }}
        onClick={() => setIsOpen(false)} // Close dropdown when clicking on the yellow div
      >
        <Link to={link} style={{ display: 'contents', color: 'black', textDecoration: 'none' }}>
          <div style={{ display: 'flex', width: 'fit-content', paddingLeft: '32px' }}>
            <img className='skeleton-profileImg-rating' src={userImg} width={50} height={50} style={{ borderRadius: '1000px', objectFit: 'cover', border:"0.5px solid #E8E9E9", opacity:1 }} />
            <div>
              <div style={{display:"flex"}}>
              <h6 style={{ marginRight: '8px', marginBottom: '0px', width: 'fit-content', whiteSpace: 'nowrap' }}>{userName}</h6>

              {!isBusiness && isVerified && (
                        <RiVerifiedBadgeFill size={22} color='#00c8b8' style={{marginRight:"4px"}}/>
                        )}
            {isBusiness && isVerified && <FaStore size={25} style={{ position: "relative", marginRight: "8px" }} color='#F00057' />}

              <div style={{ display: "flex", alignItems: 'center', marginLeft: 'auto', whiteSpace: 'nowrap', marginRight:"4px" }}>
                <AiFillStar size={17} style={{ marginTop: "0px" }} color='gold' />
                <p style={{ marginBottom: "0px", fontSize: "13px", marginLeft: '4px' }}>{userRating.toFixed(1)}</p>
                <p style={{ marginBottom: "0px", marginLeft: '4px', fontSize: "13px" }}>({numRaters || 0})</p>
              </div>
              </div>
              <div style={{display:"flex", direction:"rtl", marginRight:"8px", marginTop:"4px", alignItems:"center"}}>
              <MdOutlineWatchLater size={14} color='GrayText' />

              <p style={{ marginRight: '2px', marginBottom: '0px', width: 'fit-content', whiteSpace: 'nowrap', fontSize: '12px', color: 'GrayText', direction: 'rtl' }}>{timeAgo}</p>
              </div>
            </div>
          </div>
        </Link>

      </div>

      <div
        style={{
          direction: 'rtl',
          wordBreak: 'break-word',
          // marginLeft: '16px',
          width: '80%',
        }}
        onClick={() => setIsOpen(false)} // Close dropdown when clicking on the cyan div
      >
          <p style={{ marginRight: '68px',  width: '100%' }}>
          <RatingStar
            value={rating}
            style={{direction:"ltr"}}
            precision={0.5}
            readOnly
          />
          </p>
          <p style={{ marginRight: '68px', width: '100%' }}>
            {notes}
          </p>
      </div>

      <Divider sx={{ width: '100%', marginBottom:"16px" }} />
    </div>
    </>

  );
}

export default Rating;
