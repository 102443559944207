import React, { useEffect, useState, useRef } from 'react'
import e from '../assets/electronics.avif';
import f from '../assets/furniture.jpg';
import { MdOutlineWatchLater } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";
import { Divider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {Link , useParams, useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import Countdown from 'react-countdown';
import { getStorage, ref, listAll, getDownloadURL,  } from "firebase/storage";
import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, query , updateDoc, serverTimestamp } from 'firebase/firestore';
import placeholder from '../assets/no-image.jpg';
import { MdDangerous } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import {storage} from '../config/firebase';
import { BsStopwatchFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import logo from '../assets/logo.jpg'
import { IoCloseSharp } from "react-icons/io5";

  function Offer({
    offerId,
    imgs, 
    productName, 
    amount, 
    link, 
    category, 
    carPlateKind,
    acceptOffer,
    rejectOffer
  }) {
      
    function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: number > 100 ? 0 : 2,
          maximumFractionDigits: number > 100 ? 0 : 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }

    const [imageLoaded, setImageLoaded] = useState(false);

    const skeletonRef = useRef(null);
  
    const handleImageLoad = () => {
      setImageLoaded(true);
      if (skeletonRef.current) {
        skeletonRef.current.style.display = 'none';
      }
    };

  useEffect(() => {
    if (imageLoaded) {
      // Hide the skeleton element here (using a ref or DOM manipulation)
      const skeletonElement = document.querySelector('.skeleton');
      if (skeletonElement) {
        skeletonElement.style.display = 'none';
      }
    }
  }, [imageLoaded]);

    return (
        <>
        <div style={{display: "block", minWidth:"100%", position:"relative", width:"100%"}}>

            <div className='product-div'>
        <Link to={link}>

              <div className="image-container">
      {/* <div className="skeleton" ref={skeletonRef} />  */}
      <img
            style={{
              objectFit: (category === "لوح سيارات") ? "scale-down" : "cover",
            }}
            className="product-img" src={imgs} loading='lazy' />
    </div>



                <p style={{marginBottom:"4px", color:"black", fontSize:"12px"}}>رقم العرض # {offerId}</p>
                <p id='product-name-div'>{productName}</p>
                <div style={{ display: "flex", direction: "rtl", justifyContent: "space-between", alignItems: "center"}}>
                <p style={{marginBottom: "4px", marginTop: "0px", color: "#00C6B7", fontSize:"14px"}}>العرض المقدم :</p>
                  <p style={{ direction: "rtl", color: "#F00057", marginBottom: "4px", whiteSpace:"nowrap", fontSize:"15px" }}>{formatAccountingArabic(amount)}</p>
                </div>

        </Link>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                  <button id='my-button' style={{width: "47%", backgroundColor:"#00C8B8"}}
                    onClick={() => {
                      const confirmed = window.confirm("قبول العرض؟");
                      if (confirmed) {
                        acceptOffer()
                      }
                    }}

                  >
                    قبول 
                    <FaCheck style={{marginRight:"8px"}} />
                  </button>
                  <button id='my-button' style={{width: "47%", backgroundColor:"#F00057"}}
                  onClick={()=>{
                    const confirmed = window.confirm("رفض العرض؟");
                    if (confirmed) {
                      rejectOffer()
                    }
                  }}
                  >
                    رفض
                    
                    <IoCloseSharp size={19} style={{marginRight:"4px"}} />
                    </button>
                </div>
            </div>
        
        </div>


        
        </>
    );
}
 
export default Offer;